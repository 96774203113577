import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';

import Cross from '../../images/Cross';
import { colorStyleProps, fontStyleProps } from '../../styles';
import CommentList from './CommentList';

const styles = StyleSheet.create({
  comment: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    backgroundColor: colorStyleProps.grayE5.color,
    paddingHorizontal: 8,
  },
  mobileHeader: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 36,
    width: '100%',
    paddingHorizontal: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  mobileHeaderText: {
    display: 'flex',
    flexDirection: 'row',
    // width: '50%',
  },
  text: {
    ...colorStyleProps.keyBlack,
    ...fontStyleProps.bold16,
  },
  crossIcon: {
    position: 'absolute',
    top: 0,
    right: 16,
  },
  commentList: {
    width: '100%',
    marginTop: 24,
    flexShrink: 1,
    flexGrow: 1,
  },
});

const MobilePlanComments = ({ plan, hideComments }) => {
  return (
    <View style={styles.comment}>
      <View style={styles.mobileHeader}>
        <View style={styles.mobileHeaderText}>
          <Text style={styles.text}>{`留言 (${plan.commentsCount})`}</Text>
        </View>
        <View style={styles.crossIcon}>
          <TouchableOpacity onPress={hideComments}>
            <Cross type="dark" size={12} />
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.commentList}>
        <CommentList slug={plan.slug} closeModal={hideComments} planAuthor={plan.authorUid} />
      </View>
    </View>
  );
};

export default MobilePlanComments;
