import * as AuthSession from 'expo-auth-session';
import * as Google from 'expo-auth-session/providers/google';
import * as Analytics from 'expo-firebase-analytics';
import * as WebBrowser from 'expo-web-browser';
import { useContext, useEffect, useCallback } from 'react';

import { UserContext } from '../../../containers/UserProvider';

WebBrowser.maybeCompleteAuthSession();

const WithAuthSessionSignIn = ({ children }) => {
  const { authInfo, setAuthInfo } = useContext(UserContext);

  const [, response, promptAsync] = Google.useAuthRequest({
    expoClientId: process.env.GOOGLE_SERVICE_EXPO_CLIENT_ID,
    webClientId: process.env.GOOGLE_SERVICE_WEB_CLIENT_ID,
  });

  useEffect(() => {
    (async () => {
      if (response) {
        if (response.type === 'success') {
          const {
            authentication: { accessToken: token },
          } = response;
          await Analytics.logEvent('login', {
            provider: 'google',
            desc: 'login',
          });
          setAuthInfo({
            provider: 'google',
            token,
            revoke: () => AuthSession.revokeAsync({ token }, Google.discovery),
          });
        } else {
          // TODO failed
          console.error(response);
        }
      }
    })();
  }, [response]);

  const login = useCallback(async () => {
    // if alread has authInfo, just clone a new one to trigger
    // UserProvider to login again
    if (authInfo) {
      await Analytics.logEvent('login', {
        provider: 'google',
        desc: 'auth exists',
      });
      setAuthInfo({ ...authInfo });
    } else {
      promptAsync();
    }
  }, [promptAsync, authInfo]);

  return children({ login });
};

export default WithAuthSessionSignIn;
