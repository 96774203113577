import React from 'react';
import { useController } from 'react-hook-form';
import { View, Text, StyleSheet } from 'react-native';

import { colorStyleProps, fontStyleProps } from '../../styles';

export const styles = StyleSheet.create({
  container: {
    marginVertical: 8,
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelText: {
    paddingVertical: 5,
    fontSize: 16,
    fontWeight: 'bold',
    color: colorStyleProps.grayE0.color,
  },
  errorText: {
    ...fontStyleProps.regular14,
    color: colorStyleProps.error.color,
    paddingVertical: 5,
    paddingLeft: 10,
  },
});

const Field = ({ name, style, label, labelStyle, labelTextStyle, control, rules, defaultValue, children }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    shouldUnregister: true,
    defaultValue,
  });

  return (
    <View style={[styles.container, style]}>
      <View style={[styles.label, labelStyle]}>
        {label && <Text style={[styles.labelText, labelTextStyle]}>{label}</Text>}
        {label && !!error && <Text style={styles.errorText}>{error?.message}</Text>}
      </View>
      {children({ field, error })}
      {!label && !!error && <Text style={styles.errorText}>{error?.message}</Text>}
    </View>
  );
};

export default Field;
