import { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';

const Fields = ({ name, control, defaultValues, onInit, children }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const [defaultValuesToAppend, setDefaultValuesToAppend] = useState(defaultValues);
  useEffect(() => {
    if (defaultValuesToAppend.length) {
      append(defaultValuesToAppend[0]);
      setDefaultValuesToAppend(defaultValuesToAppend.slice(1));
    } else {
      onInit && onInit();
    }
  }, [defaultValuesToAppend]);

  return children({ fields, append, remove });
};

export default Fields;
