import React from 'react';
import { Image } from 'react-native';

import Fallbackable from './Fallbackable';

const FallbackableImage = ({ fallbackImage, ...props }) => (
  <Fallbackable>
    {({ isFailed, onLoad, onError }) =>
      isFailed || !props.source?.uri ? fallbackImage : <Image {...props} onLoad={onLoad} onError={onError} />
    }
  </Fallbackable>
);

FallbackableImage.defaultProps = {
  fallbackImage: null,
};

export default FallbackableImage;
