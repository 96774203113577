import { StyleSheet, Platform } from 'react-native';

import { colorStyleProps } from '../../../styles';

export default StyleSheet.create({
  main: {},
  mobileMain: {
    marginTop: 90,
    backgroundColor: colorStyleProps.tpOG.color,
    // make view scrollable
    minHeight: Platform.OS === 'web' ? 'calc(100% + 44px)' : undefined,
  },
});
