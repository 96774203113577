import React, { useCallback, useMemo } from 'react';
import { Platform } from 'react-native';

import apis from '../../apis';
import ButtonAwait from '../../components/PressAwait/ButtonAwait';
import Plus from '../../images/Plus';
import { useMobileView } from '../Root/useMobileView';

const btnStyle = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  negativeMain: {
    width: '100%',
  },
  button: {
    paddingHorizontal: Platform.OS === 'web' ? `${(100 * 35) / 1440}vw` : 35,
  },
  negativeButton: {
    width: '100%',
  },
};

const followUser = async (uid) => {
  try {
    await apis.profiles.followUser(uid);
  } catch (error) {
    console.log(error);
  }
  return null;
};

const unFollowUser = async (uid) => {
  try {
    await apis.profiles.unFollowUser(uid);
  } catch (error) {
    console.log(error);
  }
  return null;
};

const FollowButton = ({ isFollowing, uid, onFollowChange }) => {
  const [isMobileView] = useMobileView();

  const style = useMemo(() => {
    if (isMobileView) {
      return {
        ...btnStyle,
        button: {
          ...btnStyle.button,
          paddingHorizontal: 35,
        },
      };
    }
    return btnStyle;
  }, [isMobileView]);

  const onClick = useCallback(async () => {
    if (!isFollowing) {
      await followUser(uid);
    } else {
      await unFollowUser(uid);
    }
    if (onFollowChange) {
      onFollowChange();
    }
  }, [uid, isFollowing]);

  return (
    <ButtonAwait
      icon={isFollowing ? null : <Plus size={20} />}
      style={style}
      title={isFollowing ? '取消追蹤' : '追蹤'}
      negative={isFollowing}
      onPress={onClick}
      waitFor={[isFollowing]}
    />
  );
};

export default FollowButton;
