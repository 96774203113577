import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import Avatar from '../../components/Avatar';
import ImageUploader from '../../components/ImageUploader';
import EditAvatar from '../../images/EditAvatar';
import { fontStyleProps, colorStyleProps } from '../../styles';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  avatar: {
    position: 'relative',
  },
  edit: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 30,
    height: 30,
  },
  name: {
    ...fontStyleProps.bold24,
    ...colorStyleProps.keyBlack,
    marginTop: 20,
    textAlign: 'center',
  },
});

const AvatarName = ({ isEditable, userProfile, avatarOnly, onUpload }) => {
  return (
    <View style={styles.container}>
      <View style={styles.avatar}>
        <Avatar size={100} borderWidth={4} borderColor={colorStyleProps.tpWhite.color} uri={userProfile?.image} />
        {isEditable ? (
          <View style={styles.edit}>
            <ImageUploader
              objectType="photo"
              onUpload={onUpload}
              aspect={[1, 1]}
              resize={{ width: 120, height: 120 }}
              withTimestamp>
              <EditAvatar />
            </ImageUploader>
          </View>
        ) : null}
      </View>
      {avatarOnly ? null : <Text style={styles.name}>{userProfile?.username}</Text>}
    </View>
  );
};

export default AvatarName;
