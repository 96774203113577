import React from 'react';

import LoadingProvider from './containers/LoadingProvider';
import OverlayManager from './containers/OverlayManager';
import UserProvider from './containers/UserProvider';
import Root from './pages/Root';
import { FontLoader } from './styles';

export default function App() {
  return (
    <LoadingProvider>
      <FontLoader>
        <OverlayManager>
          <UserProvider>
            <Root />
          </UserProvider>
        </OverlayManager>
      </FontLoader>
    </LoadingProvider>
  );
}
