import React from 'react';
import { Platform } from 'react-native';
import { MenuOption } from 'react-native-popup-menu';

import { fontStyleProps } from '../../styles';
import Popup from '../Popup';

const styles = {
  optionText: {
    ...fontStyleProps.regular12,
    textAlign: 'left',
    paddingVertical: 10,
    paddingHorizontal: 13,
  },
};

if (Platform.OS === 'web') {
  styles.optionText.whiteSpace = 'nowrap';
}

const PopupMenu = ({ children, options, ...props }) => {
  return (
    <Popup trigger={children} {...props}>
      <>
        {options.map((o, x) => (
          <MenuOption key={x} {...o} customStyles={styles} />
        ))}
      </>
    </Popup>
  );
};

export default PopupMenu;
