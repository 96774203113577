import React, { useEffect } from 'react';
import { withMenuContext } from 'react-native-popup-menu';

import { useMobileView } from '../../pages/Root/useMobileView';
import Popup from '../Popup';
import { useHistoryWithSpec } from '../UniversalRouter/useHistoryWithSpec';
import MobileNotification from './MobileNotification';
import NotificationIconWithBadge from './NotificationIconWithBadge';
import NotificationList from './NotificationList';
import withFetchNotification from './withFetchNotification';

const Notification = ({ onOpeNotification, notificationBadge, ctx }) => {
  const [isMobileView] = useMobileView();
  const history = useHistoryWithSpec();

  const isMenuOpen = ctx.menuActions.isMenuOpen();

  useEffect(() => {
    if (isMenuOpen) {
      ctx.menuActions.closeMenu();
    }
  }, [history.location, ctx]);

  if (isMobileView) {
    return <MobileNotification />;
  }

  return (
    <Popup
      onPress={() => {
        onOpeNotification();
      }}
      trigger={<NotificationIconWithBadge />}>
      <NotificationList notificationBadge={notificationBadge} />
    </Popup>
  );
};

export default withMenuContext(withFetchNotification(Notification));
