import { Nunito_700Bold, Nunito_400Regular } from '@expo-google-fonts/nunito';
import { useFonts, Prompt_700Bold } from '@expo-google-fonts/prompt';
import { useContext, useEffect } from 'react';
import { StyleSheet } from 'react-native';

import { LoadingContext } from './containers/LoadingProvider';

export const FontLoader = ({ children }) => {
  const [fontsLoaded] = useFonts({
    Prompt_700Bold,
    Nunito_700Bold,
    Nunito_400Regular,
  });

  const { setIsLoading } = useContext(LoadingContext);

  useEffect(() => {
    setIsLoading(!fontsLoaded);
  }, [fontsLoaded]);

  return fontsLoaded ? children : null;
};

export const fontStyleProps = {
  bold56: {
    fontFamily: 'Prompt_700Bold',
    fontStyle: 'normal',
    fontSize: 56,
    lineHeight: 85,
    color: '#000000',
  },
  boldNunito56: {
    fontFamily: 'Nunito_700Bold',
    fontWeight: "bold",
    fontStyle: 'normal',
    fontSize: 56,
    lineHeight: 85,
    color: '#000000',
  },
  bold36: {
    fontFamily: 'Nunito_700Bold',
    fontStyle: 'normal',
    fontSize: 36,
    lineHeight: 49,
  },
  bold24: {
    fontFamily: 'Nunito_700Bold',
    fontStyle: 'normal',
    fontSize: 24,
    lineHeight: 33,
    color: '#000000',
  },
  bold20: {
    fontFamily: 'Nunito_700Bold',
    fontStyle: 'normal',
    fontSize: 20,
    lineHeight: 27,
    color: '#000000',
  },
  bold18: {
    fontFamily: 'Nunito_700Bold',
    fontStyle: 'normal',
    fontSize: 18,
    lineHeight: 25,
    color: '#000000',
  },
  bold16: {
    fontFamily: 'Nunito_700Bold',
    fontStyle: 'normal',
    fontSize: 16,
    lineHeight: 22,
    color: '#000000',
  },
  bold14: {
    fontFamily: 'Nunito_700Bold',
    fontStyle: 'normal',
    fontSize: 14,
    lineHeight: 19,
    color: '#000000',
  },
  bold12: {
    fontFamily: 'Nunito_700Bold',
    fontStyle: 'normal',
    fontSize: 12,
    lineHeight: 16,
    color: '#000000',
  },
  regular18: {
    fontFamily: 'Nunito_400Regular',
    fontStyle: 'normal',
    fontSize: 18,
    lineHeight: 25,
  },
  regular16: {
    fontFamily: 'Nunito_400Regular',
    fontStyle: 'normal',
    fontSize: 16,
    lineHeight: 22,
  },
  regular14: {
    fontFamily: 'Nunito_400Regular',
    fontStyle: 'normal',
    fontSize: 14,
    lineHeight: 19,
  },
  regular12: {
    fontFamily: 'Nunito_400Regular',
    fontStyle: 'normal',
    fontSize: 12,
    lineHeight: 16,
  },
};

export const fontStyles = StyleSheet.create(fontStyleProps);

export const colorStyleProps = {
  tpOG: {
    color: '#F4F4F4',
  },
  tpWhite: {
    color: '#FFFFFF',
  },
  tpBlack: {
    color: '#555555',
  },
  tpGreen: {
    color: '#41AE3F',
  }, 
  tpBlue: {
    color: '#3B72AF',
  },
  keyBlack: {
    color: '#262626',
  },
  pureBlack: {
    color: '#000000',
  },
  gray55: {
    color: '#555555',
  },
  gray82: {
    color: '#828282',
  },
  grayBD: {
    color: '#BDBDBD',
  },
  grayE0: {
    color: '#E0E0E0',
  },
  grayE5: {
    color: '#E5E5E5',
  },
  grayF2: {
    color: '#F2F2F2',
  },
  grayFD: {
    color: '#FDFDFD',
  },
  error: {
    color: '#FC6D47',
  },
  ppro: {
    color: '#BCAE64',
  },
  red: {
    color: '#EB5757',
  },

  category1: {
    backgroundColor: '#F4E3E9',
    color: '#E05788',
  },
  category2: {
    backgroundColor: '#D1E5F0',
    color: '#2D9CDB',
  },
  category3: {
    backgroundColor: '#F9EFD3',
    color: '#FBBC05',
  },
};

export const colorStyles = StyleSheet.create(colorStyleProps);
