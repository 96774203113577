import { StyleSheet } from 'react-native';

import { fontStyleProps, colorStyleProps } from '../../../styles';

export default StyleSheet.create({
  mainContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  coverContainer: {
    // 1440 x 258
    width: '100vw',
    height: '17.91vw',
    backgroundColor: colorStyleProps.keyBlack.color,
  },
  body: {
    flexGrow: 1,
    flexShrink: 1,

    display: 'flex',
    flexDirection: 'row',

    backgroundColor: colorStyleProps.tpOG.color,
  },
  profileContainer: {
    // -115 / 1440
    top: '-8vw',
    height: 'fit-content',

    marginLeft: '4.86vw',
    // 70 + 344 + 36 / 1440
    width: '31.25vw',
    maxWidth: 344,
    marginRight: '2.5vw',

    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    borderRadius: 20,
  },
  rightSection: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '100%',
    width: '100%',
    flexGrow: 1,
    flexShrink: 1,
  },

  coverUploader: {
    position: 'absolute',
    width: '100%',
    height: '100%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cornerCoverUploader: {
    position: 'absolute',
    right: 30,
    bottom: 10,
  },
  coverUploaderTextTitle: {
    ...fontStyleProps.bold24,
    ...colorStyleProps.tpWhite,
    marginTop: 20,
  },
  coverUploaderTextSubtitle: {
    ...fontStyleProps.bold16,
    ...colorStyleProps.tpWhite,
  },
});
