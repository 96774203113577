import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';

import { colorStyleProps } from '../../styles';
import NotificationList from './NotificationList';
import withFetchNotification from './withFetchNotification';

const styles = StyleSheet.create({
  mobileNotification: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    backgroundColor: colorStyleProps.grayE5.color,
  },
  mobileNotificationContainer: {
    marginHorizontal: 10,
  },
});

const MobileNotification = ({ onOpeNotification }) => {
  useEffect(() => {
    onOpeNotification();
  }, []);

  return (
    <View style={styles.mobileNotification}>
      <View style={styles.mobileNotificationContainer}>
        <NotificationList />
      </View>
    </View>
  );
};

export default withFetchNotification(MobileNotification);
