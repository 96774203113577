import React, { useCallback, useState, useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';

import apis from '../../../apis';
import Button from '../../../components/Button';
import Fields from '../../../components/Form/Fields';
import GoBack from '../../../components/GoBack';
import ButtonAwait from '../../../components/PressAwait/ButtonAwait';
import { useHistoryWithSpec, Prompt } from '../../../components/UniversalRouter';
import { LoadingContext } from '../../../containers/LoadingProvider';
import { OverlayContext } from '../../../containers/OverlayManager';
import { ToastContext } from '../../../containers/Toast';
import { UserContext } from '../../../containers/UserProvider';
import { fontStyleProps, colorStyleProps } from '../../../styles';
import Plan from '../../Plan';
import ArticleForm from './ArticleForm';
import PlanForm from './PlanForm';
import SubPlanForm from './SubPlanForm';
import AddSubPlan from './add_sub.svg';
import DeleteSubPlan from './del_sub.svg';

const styles = StyleSheet.create({
  containner: {},
  header: {
    width: '100%',
    paddingHorizontal: `${(100 * 70) / 1440}vw`,

    backgroundColor: colorStyleProps.tpWhite.color,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: colorStyleProps.tpOG.color,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    paddingVertical: 15,
  },
  headerContent: {
    width: '100%',
    minHeight: 40,

    flexShrink: 1,
    flexGrow: 0,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'center',
  },
  actions: {
    flexGrow: 0,
    flexShrink: 0,
    // alignSelf: 'top',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  main: {
    minWidth: 640,

    paddingHorizontal: `${(100 * 120) / 1440}vw`,
    paddingVertical: 40,
    backgroundColor: colorStyleProps.tpOG.color,
  },
  title: {
    ...fontStyleProps.bold36,
  },
  body: {
    marginTop: 40,
    marginBottom: 100,

    backgroundColor: colorStyleProps.tpWhite.color,
    borderRadius: 20,
  },
  delete: {
    position: 'absolute',
    right: -33,
    bottom: -33,
  },
  add: {
    top: -33,
    right: -33,
    display: 'flex',
    flexDirection: 'row-reverse',
  },
});

const btnStyle = {
  main: {
    marginHorizontal: 5,
  },
  negativeMain: {
    marginHorizontal: 5,
  },
  button: {
    paddingHorizontal: 30,
  },
};

const Step3 = ({ changeStep, plan, setPlan }) => {
  const { setIsLoading } = useContext(LoadingContext);
  const { pushOverlay } = useContext(OverlayContext);
  const { userProfile } = useContext(UserContext);
  const history = useHistoryWithSpec();
  const methods = useForm({
    mode: 'all',
    // Let fields' controller can clear default values
    // shouldUnregister: true,
  });
  const { showToast } = useContext(ToastContext);

  const isPlan = plan.type === 0;
  const { isValid, isDirty } = methods.formState;

  const [previewingPlan, setPreviewingPlan] = useState();

  const onGoBackEditing = useCallback(() => setPreviewingPlan(null), []);

  const onGoBack = useCallback(() => {
    changeStep(isPlan ? 2 : 0);
  }, [isPlan]);

  const onSave = useCallback(
    (values) => {
      const data = {
        ...plan,
        ...values,
        subPlans: values.subPlans.map((s, x) => ({ ...s, session: x + 1 })),
      };

      setIsLoading(true);
      if (!plan.slug) {
        return apis.plan
          .postPlan({
            plan: data,
          })
          .then(
            ({
              data: {
                plan: { slug },
              },
            }) => {
              const newValue = { ...data, slug };
              setPlan(newValue);
              methods.reset(newValue);
              setIsLoading(false);
              if (history.location.query.applying) {
                history.with({ pathname: `/planner/${slug}`, query: { applying: undefined } }).replace();
              }
              showToast('儲存成功');
            }
          )
          .catch((error) => {
            console.error(error);
            setIsLoading(false);
            showToast('儲存失敗', 'fail');
          });
      }
      return apis.plan
        .putPlan(plan.slug, {
          plan: data,
        })
        .then(() => {
          setPlan(data);
          methods.reset(data);
          setIsLoading(false);
          showToast('儲存成功');
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          showToast('儲存失敗', 'fail');
        });
    },
    [plan, methods, setIsLoading]
  );

  const onPreview = useCallback(
    (values) => setPreviewingPlan({ ...plan, ...values, authorUid: userProfile.uid }),
    [plan, methods, userProfile]
  );

  const onPublish = useCallback(
    (values) => {
      setIsLoading(true);
      return apis.plan
        .putPlan(plan.slug, {
          plan: {
            ...values,
            type: plan.type === 0 ? 1 : 101,
          },
        })
        .then(() => {
          history.replace(`/user/${userProfile.uid}`);
          setIsLoading(false);
          showToast('成功！文章已成功發佈');
        })
        .catch((error) => {
          console.error(error);
          showToast('發佈失敗', 'fail');
          setIsLoading(false);
        });
    },
    [history, plan, userProfile]
  );

  const onError = useCallback(() => pushOverlay(({ Message }) => <Message message="請檢查所有的欄位值" />), []);

  return (
    <FormProvider {...methods}>
      <Prompt when={isDirty} message={(location, action) => '確定不儲存?'} />
      <View style={styles.containner}>
        <View style={styles.header}>
          <View style={styles.headerContent}>
            {(plan.fromStep0 || previewingPlan) && (
              <View style={{ position: 'absolute', left: -12 }}>
                <GoBack onPress={previewingPlan ? onGoBackEditing : onGoBack} />
              </View>
            )}
          </View>
          {!previewingPlan && (
            <View style={styles.actions}>
              <ButtonAwait title="儲存" negative style={btnStyle} onPress={methods.handleSubmit(onSave, onError)} />
              <Button title="預覽" negative style={btnStyle} onPress={methods.handleSubmit(onPreview, onError)} />
              <ButtonAwait
                title="發佈"
                style={btnStyle}
                onPress={methods.handleSubmit(onPublish, onError)}
                disabled={!isValid || isDirty || !plan.slug}
              />
            </View>
          )}
        </View>
        {previewingPlan && <Plan plan={previewingPlan} isPreviewing />}
        <View style={[styles.main, previewingPlan ? { display: 'none' } : undefined]}>
          <Text style={styles.title}>{isPlan ? '建立教案' : '建立文章'}</Text>
          <View style={styles.body}>
            {isPlan ? (
              <PlanForm methods={methods} plan={plan} setPlan={setPlan} />
            ) : (
              <ArticleForm methods={methods} plan={plan} setPlan={setPlan} />
            )}
          </View>
          {isPlan && (
            <>
              <Text style={styles.title}>各節次（子題）學習設計</Text>
              <Fields name="subPlans" control={methods.control} defaultValues={plan.subPlans}>
                {({ fields, append, remove }) => (
                  <>
                    {fields.map(({ id, ...subPlan }, x) => (
                      <View key={id} style={styles.body}>
                        <SubPlanForm methods={methods} plan={plan} subPlan={subPlan} index={x} />
                        <View style={styles.delete}>
                          <TouchableOpacity onPress={() => remove(x)}>
                            <DeleteSubPlan />
                          </TouchableOpacity>
                        </View>
                      </View>
                    ))}
                    <View style={styles.add}>
                      <TouchableOpacity
                        onPress={() =>
                          append({
                            title: '',
                            session: '0',
                            performance: '',
                            content: '',
                            goal: '',
                            activity: '',
                            assessment: '',
                            note: '',
                          })
                        }>
                        <AddSubPlan />
                      </TouchableOpacity>
                    </View>
                  </>
                )}
              </Fields>
            </>
          )}
        </View>
      </View>
    </FormProvider>
  );
};

export default Step3;
