import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';

import apis from '../../apis';
import CardsView from '../../components/CardsView';
import withColumnCalculator from '../../components/CardsView/withColumnCalculator';
import DataList from '../../components/DataList';
import PlanCard from '../../components/PlanCard';
import Sorter from '../../components/Sorter';
import Tabs from '../../components/Tabs';
import { useHistoryWithSpec } from '../../components/UniversalRouter';
import { useFloatingActions } from '../../containers/FloatingActions';
import { useMobileView } from '../Root/useMobileView';
import defaultStyles from './styles';
import mobileStyles from './styles.native';

const tabs = [
  {
    value: 'latest',
    label: '最新',
  },
  {
    value: 'trending',
    label: '最熱門',
  },
  {
    value: 'feed',
    label: '關注',
  },
];

const tabsStyle = {
  tabs: {
    marginTop: 40,
    marginBottom: 30,
    marginLeft: -2,
  },
  title: {
    default: {
      fontSize: 24,
      lineHeight: 33,
    },
    active: {
      fontSize: 24,
      lineHeight: 33,
    },
  },
};

const mobileTabsStyle = {
  container: {
    marginLeft: 2,
  },
  tabs: {
    marginTop: 12,
    marginBottom: 12,
    marginLeft: -2,
  },
  title: {
    default: {
      fontSize: 16,
      lineHeight: 22,
    },
    active: {
      fontSize: 16,
      lineHeight: 22,
    },
  },
};

const PAGE_SIZE = 24;

const renderCard = (card, fixedWith) => {
  const style = fixedWith ? { width: 356 } : undefined;
  return <PlanCard key={card.id} plan={card} style={style} />;
};

const Home = withColumnCalculator(({ numColumns }) => {
  const [isMobileView, styles] = useMobileView(mobileStyles, defaultStyles);
  const history = useHistoryWithSpec();

  // set default category to url
  useEffect(() => {
    if (!history.location.query.cat) {
      history.with({ query: { cat: tabs[0].value } }).replace();
    }
  }, [history.location.query.cat]);

  // always get plan type and category from url
  const planType = history.location.query.pt;
  const category = history.location.query.cat;
  const onCategoryChange = useCallback((tab) => {
    history.with({ query: { cat: tab } }).replace();
  }, []);

  const [orderBy, setOrderBy] = useState('updated_at');
  const [sort, setSort] = useState('desc');
  const onOrderBy = useCallback((ob) => setOrderBy(ob), []);
  const onToggleSort = useCallback(() => setSort((old) => (old === 'desc' ? 'asc' : 'desc')), []);

  const onFetch = useCallback(
    async (offset) => {
      if (!planType || !category || !orderBy || !sort) {
        return null;
      }

      try {
        const { data } = await apis.plans.getPlansFeed({
          type: planType,
          category,
          orderBy,
          sort,
          offset,
          limit: PAGE_SIZE,
        });

        const newItems = [];
        if (data.plans.length) {
          newItems.push({
            key: `${offset}-${data.plans[0].slug}`,
            data: data.plans,
          });
        }

        return {
          total: data.total,
          next: offset + data.plans.length,
          items: newItems,
        };
      } catch (error) {
        // TODO handle error
        console.error(error);
      }
    },
    [planType, category, orderBy, sort]
  );

  const renderItem = useCallback(
    ({ item }) => {
      return <CardsView cards={item.data} renderCard={renderCard} numColumns={numColumns} />;
    },
    [numColumns]
  );

  const Header = useMemo(
    () => () =>
      category ? (
        <View style={styles.header}>
          <Tabs
            tabs={tabs}
            defaultCurrentTab={category}
            style={isMobileView ? mobileTabsStyle : tabsStyle}
            onTabChange={onCategoryChange}
          />
          <View style={styles.sorter}>
            <Sorter orderBy={orderBy} sort={sort} onOrderBy={onOrderBy} onToggleSort={onToggleSort} />
          </View>
        </View>
      ) : null,
    [category, orderBy, sort, isMobileView]
  );

  const { scrollableRef, callbacks } = useFloatingActions();

  return (
    <View style={styles.main}>
      <DataList
        scrollableStyle={{ paddingRight: '4.86vw' }}
        scrollableRef={scrollableRef}
        ListHeaderComponent={Header}
        renderItem={renderItem}
        onFetch={onFetch}
        emptyString="沒有任何符合條件的資料"
        {...callbacks}
      />
    </View>
  );
});

export default Home;
