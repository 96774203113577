import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';

import FallbackableImage from '../../components/Fallbackable/FallbackableImage';
import TouchableOpacityAwait from '../../components/PressAwait/TouchableOpacityAwait';
import { Link } from '../../components/UniversalRouter';
import CommentSvg from '../../images/Comment';
import HeartSvg from '../../images/Heart';
import { colorStyleProps } from '../../styles';
import Avatar from '../Avatar';
import CenterText from '../CenterText';
import styles from './styles';
import withTag from './withTag';
import withToggleFavorite from './withToggleFavorite';

const shortenNumber = (number) => (number > 999 ? '999+' : number);

export const Like = ({ plan }) => (
  <View style={styles.favorites}>
    <HeartSvg
      width={20}
      height={20}
      fill={plan.favorited ? colorStyleProps.tpGreen.color : colorStyleProps.grayBD.color}
    />
    <Text style={styles.favoritesCount}>{shortenNumber(plan.favoritesCount)}</Text>
  </View>
);

export const Comment = ({ plan }) => (
  <View style={styles.comments}>
    <CommentSvg
      width={20}
      height={20}
      fill={plan.commented ? colorStyleProps.tpGreen.color : colorStyleProps.grayBD.color}
    />
    <Text style={styles.commentsCount}>{shortenNumber(plan.commentsCount)}</Text>
  </View>
);

export const renderWithLinkOrNot = (rendered, linkTo, disable) => {
  if (disable) {
    return rendered;
  }
  return (
    <Link to={linkTo} style={{ width: '100%', height: '86.9%' }}>
      {rendered}
    </Link>
  );
};

const PlanCard = ({ plan, toggleFavorite, style, disableLink }) => {
  let linkTo = '/';
  if (plan.type === 0 || plan.type === 100) {
    linkTo = `/planner/${plan.slug}`;
  } else if (plan.type === 1 || plan.type === 101) {
    linkTo = `/plan/${plan.slug}`;
  }

  return (
    <View style={StyleSheet.compose(styles.card, style)}>
      <View style={styles.background} />
      <View style={styles.foreground}>
        {renderWithLinkOrNot(
          <View style={{ width: '100%', height: '86.9%' }}>
            <>
              <View style={styles.cover}>
                <FallbackableImage
                  source={{ uri: plan.cover }}
                  fallbackImage={<Image source={require('../../images/default_background.png')} />}
                  style={styles.coverImage}
                />
                {plan.matchRate && (
                  <View style={styles.matchRate}>
                    <LinearGradient
                      style={styles.matchRateGradient}
                      start={{ x: 0, y: 0 }}
                      end={{ x: 1, y: 0.41 }}
                      colors={['rgba(255, 255, 255, 0.2)', 'rgba(255, 255, 255, 0.6)']}
                      locations={[0.003, 0.9782]}
                    />
                    <CenterText textStyle={styles.matchRateText}>{`🔥 匹配度 ${Math.round(
                      100 * plan.matchRate
                    )}%`}</CenterText>
                  </View>
                )}
                <View style={styles.tagList}>
                  {plan.tagList.map((tag, idx) => (
                    <CenterText
                      key={tag.key}
                      containerStyle={[styles.tag, tag.containerStyle]}
                      textStyle={[styles.tagText, tag.textStyle]}>
                      {tag.text}
                    </CenterText>
                  ))}
                </View>
              </View>
              <View style={styles.title}>
                <Text style={styles.titleText} numberOfLines={1}>
                  {plan.title}
                </Text>
              </View>
            </>
          </View>,
          linkTo,
          disableLink
        )}
        <View style={styles.bottom}>
          <View style={styles.authorContainer}>
            <Link to={`/user/${plan.authorUid}`}>
              <View style={styles.author}>
                <Avatar size={24} uri={plan.authorImage} />
                <Text style={styles.authorName} numberOfLines={1}>
                  {plan.authorUsername}
                </Text>
              </View>
            </Link>
          </View>
          <View style={styles.favoritesContainer}>
            <TouchableOpacityAwait onPress={toggleFavorite}>
              <Like plan={plan} />
            </TouchableOpacityAwait>
            <View style={{ marginLeft: 18 }} />
            {renderWithLinkOrNot(<Comment plan={plan} />, `${linkTo}?showComments=true`, disableLink)}
          </View>
        </View>
      </View>
    </View>
  );
};

export default withToggleFavorite(withTag(PlanCard));
