import React from 'react';
import { TouchableOpacity, Text, Platform, View } from 'react-native';

import AppleLogo from '../../../images/AppleLogo';
import WithExpoAppleAuthentication from './WithExpoAppleAuthentication';
import WithWeb from './WithWeb';
import styles from './styles';

const NoApproach = ({ children }) => (
  <View>
    {children({})}
    <View
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: 99999,
        opacity: 0.5,
        backgroundColor: '#FFF',
        cursor: 'not-allowed',
      }}
    />
  </View>
);

const WithApproach = Platform.OS === 'ios' ? WithExpoAppleAuthentication : Platform.OS === 'web' ? WithWeb : NoApproach;

const AppleSignInButton = () => {
  return (
    <WithApproach>
      {({ login }) => (
        <TouchableOpacity style={styles.button} onPress={login}>
          <AppleLogo width={24} height={24} />
          <Text style={styles.text}>使用 Apple 登入</Text>
        </TouchableOpacity>
      )}
    </WithApproach>
  );
};

export default AppleSignInButton;
