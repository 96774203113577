import * as Analytics from 'expo-firebase-analytics';
import * as Linking from 'expo-linking';
import { Platform } from 'react-native';

export const openURL = (url, name = '_blank') => {
  Analytics.logEvent('open_url', {
    url,
  });
  return Platform.OS === 'web' ? window.open(url, name) : Linking.openURL(url);
};
