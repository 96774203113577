import * as Analytics from 'expo-firebase-analytics';
import { useContext, useCallback, useEffect } from 'react';

import { UserContext } from '../../../containers/UserProvider';
import { useHistoryWithSpec } from '../../UniversalRouter/useHistoryWithSpec';

const WithAppleAuthentication = ({ children }) => {
  const { authInfo, setAuthInfo } = useContext(UserContext);
  const history = useHistoryWithSpec();

  useEffect(() => {
    (async () => {
      if (history.location.hash) {
        const [p1, p2] = history.location.hash
          .substring(1)
          .split('&')
          .map((param) => param.split('=').map(decodeURIComponent));
        history.with({ hash: '' }).replace();
        const code = p1[0] === 'code' ? p1[1] : p2[1];
        const token = p1[0] === 'code' ? p2[1] : p1[1];

        await Analytics.logEvent('login', {
          provider: 'apple',
          action: 'login',
        });
        setAuthInfo({
          provider: 'apple',
          token,
          code,
          revoke: () => {},
        });
      }
    })();
  }, [history.location.hash]);

  const login = useCallback(async () => {
    // if alread has authInfo, just clone a new one to trigger
    // UserProvider to login again
    if (authInfo) {
      await Analytics.logEvent('login', {
        provider: 'apple',
        desc: 'auth exists',
      });
      setAuthInfo({ ...authInfo });
    } else {
      const params = {
        response_type: 'code id_token',
        response_mode: 'fragment',
        client_id: `${process.env.APPLE_SIGN_IN_CLIENT_ID}`,
        redirect_uri: `${process.env.HOST_URL}`,
      };
      window.location.href = `https://appleid.apple.com/auth/authorize?${Object.keys(params)
        .map((name) => `${name}=${encodeURIComponent(params[name])}`)
        .join('&')}`;
    }
  }, [authInfo]);

  return children({ login });
};

export default WithAppleAuthentication;
