import { StyleSheet } from 'react-native';

import { colorStyleProps, fontStyleProps } from '../../styles';

export default StyleSheet.create({
  main: {
    height: '100%',
    backgroundColor: colorStyleProps.tpOG.color,
  },

  headerImage: {
    position: 'absolute',
    width: '100%',
    height: 180,
  },

  headerBar: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    width: '100%',
    height: 90,

    position: 'relative',
  },
  headerBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingHorizontal: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
  },

  coverUploader: {
    position: 'absolute',
    right: 10,
    top: 90,
    height: 80,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  coverUploaderText: {
    flexGrow: 1,
    flexShrink: 1,
  },
  coverUploaderTextTitle: {
    ...fontStyleProps.bold16,
    color: colorStyleProps.tpWhite.color,
  },
  coverUploaderTextSubtitle: {
    ...fontStyleProps.bold12,
    color: colorStyleProps.tpWhite.color,
    marginBottom: 10,
  },
  coverUploaderIcon: {
    flexGrow: 0,
    flexShrink: 0,
  },

  chevronLeftButton: {
    display: 'flex',
    width: 25,
    height: 25,
  },

  userProfileContainer: {
    width: 344,
    height: 815,
  },
});
