import axios from 'axios';

export const getLandingPlans = () => axios.get('/api/plans/landing');

export const getPlans = ({ type, authorUid, favorited, orderBy, sort, limit, offset } = {}) =>
  axios.get('/api/plans', {
    params: {
      type,
      author_uid: authorUid,
      favorited,
      order_by: orderBy,
      sort,
      limit,
      offset,
    },
  });

export const getPlansFeed = ({ type, category, orderBy, sort, limit, offset } = {}) =>
  axios.get('/api/plans/feed', {
    params: {
      type,
      category,
      order_by: orderBy,
      sort,
      limit,
      offset,
    },
  });

export const searchPlans = ({ keyword, sort, limit, offset } = {}) =>
  axios.get('/api/plans/search', {
    params: {
      keyword,
      sort,
      limit,
      offset,
    },
  });

export const getPlansMatch = ({ subject, phase, cores, ideas, core_tags, keywords, limit, offset } = {}) => {
  const params = new URLSearchParams();
  params.append('subject', subject);
  params.append('edu_type', phase);
  cores.forEach((core) => params.append('core', core));
  ideas.forEach((idea) => params.append('idea', idea));
  core_tags.forEach((core_tag) => params.append('core_tag', core_tag));
  keywords.forEach((keyword) => params.append('keyword', keyword));
  return axios.get('/api/plans/match', {
    params,
  });
};

export const getPlan = (slug) => axios.get(`/api/plans/${slug}`);

export const postFavorite = (slug) => axios.post(`/api/plans/${slug}/favorite`);

export const deleteFavorite = (slug) => axios.delete(`/api/plans/${slug}/favorite`);
