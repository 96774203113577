import { StyleSheet } from 'react-native';

import { colorStyleProps } from '../../styles';

export default StyleSheet.create({
  main: {
    backgroundColor: colorStyleProps.tpOG.color,
    paddingLeft: '4.86vw',

    width: '100%',
    height: '100%',
  },
  header: {
    width: '100%',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  sorter: {
    flexGrow: 1,

    display: 'flex',
    flexDirection: 'row-reverse',
  },
});
