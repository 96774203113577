import React, { useEffect, useState, useMemo } from 'react';
import { Image, View } from 'react-native';

const AutoSizeImage = ({ style, uri, fit, onError }) => {
  const [size, setSize] = useState();

  useEffect(() => {
    Image.getSize(uri, (width, height) => setSize({ width, height }), onError);
  }, []);

  const [containerStyle, imageStyle] = useMemo(() => {
    if (size) {
      if (fit) {
        return [
          {
            maxWidth: '100%',
            width: size.width,
          },
          [
            {
              height: 0,
              paddingTop: `${(100 * size.height) / size.width}%`,
            },
            style,
          ],
        ];
      }
      return [
        {
          maxWidth: '100%',
          width: '100%',
        },
        [
          {
            height: 0,
            paddingTop: `${(100 * size.height) / size.width}%`,
          },
          style,
        ],
      ];
    }
    return [{}, style];
  }, [size, style, fit]);

  return size ? (
    <View style={containerStyle}>
      <Image resizeMode="contain" style={imageStyle} source={{ uri }} onError={onError} />
    </View>
  ) : null;
};

export default AutoSizeImage;
