import axios from 'axios';

export const getNotifications = ({ type, limit, offset } = {}) =>
  axios.get('/api/notifications', {
    params: {
      type,
      limit,
      offset,
    },
  });

export const getNotificationsSummary = () => axios.get('/api/notifications/summary');

export const putNotificatonToRead = (type) =>
  axios.put('/api/notifications/read', {}, { params: { type: Number(type) } });
