import React, { useMemo, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';

import ImageUploader from '../../../components/ImageUploader';
import { fontStyleProps, colorStyleProps } from '../../../styles';
import AddPicker from '../Step1/AddPicker';
import ImageSvg from './image.svg';
import LinkSvg from './link.svg';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: 'fit-content',
  },
  capsuleContainer: {
    marginRight: 12,
    marginTop: 6,
    marginBottom: 6,
  },
});

const options = [
  {
    value: 'link',
    label: '鏈結',
  },
  {
    value: 'image',
    label: '圖片',
  },
];

const PicturePicker = ({ append, children }) => {
  const [opened, setOpened] = useState(false);

  const remainOptions = useMemo(() => {
    return options.map(({ label: text, ...o }) => {
      const rendered = (
        <View style={{ marginLeft: 15, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <View
            style={{
              width: 44,
              height: 44,
              borderWidth: 1,
              borderColor: colorStyleProps.grayBD.color,
              borderRadius: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            {o.value === 'link' ? <LinkSvg /> : <ImageSvg />}
          </View>
          <Text style={{ paddingTop: 5, paddingHorizontal: 10, ...fontStyleProps.regular12 }}>{text}</Text>
        </View>
      );

      return {
        ...o,
        text: undefined,
        children:
          o.value === 'link' ? (
            rendered
          ) : (
            <ImageUploader
              objectType="plan_picture"
              onUpload={(link) => {
                append({ link });
                setOpened(false);
              }}>
              {rendered}
            </ImageUploader>
          ),
        onSelect: (clicked) => {
          if (o.value === 'link') {
            append({ editing: true, link: '' });
            setOpened(false);
          }
        },
      };
    });
  }, []);

  return (
    <View style={styles.container}>
      <AddPicker opened={opened} setOpened={setOpened} remainOptions={remainOptions}>
        {() => children}
      </AddPicker>
    </View>
  );
};

PicturePicker.defaultProps = {
  selected: [],
  setSelected: () => {},
};

export default PicturePicker;
