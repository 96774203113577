import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';

import Button from '../../components/Button';
import UniversalOverlay from '../../components/UniversalOverlay';
import { colorStyleProps, fontStyleProps } from '../../styles';

export const OverlayContext = React.createContext();

const styles = StyleSheet.create({
  overlay: {
    borderWidth: 0,
    width: '100%',
    height: '100%',
  },
  overlayOverlay: {
    backgroundColor: 'transparent',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  defaultModal: {
    display: 'flex',
    backgroundColor: colorStyleProps.tpWhite.color,
    borderRadius: 10,
    paddingVertical: 50,
    paddingHorizontal: 45,
  },
  defaultModalContent: {
    ...fontStyleProps.bold16,
    marginBottom: 30,
    textAlign: 'center',
  },
  defaultModalButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    ...fontStyleProps.regular14,
  },
});

const defaultModalButtonStyle = {
  button: {
    minWidth: 100,
  },
};

const OverlayManager = ({ children }) => {
  const [overlays, setOverlays] = useState([]);
  const [topOverlay, setTopOverlay] = useState();
  const [isVisible, setIsVisible] = useState(false);

  const popOverlay = useCallback(() => {
    setOverlays(([, ...remains]) => remains);
    setIsVisible(false);
  }, [overlays]);

  const pushOverlay = useCallback(
    (renderOverlay) => {
      setOverlays((old) => [renderOverlay, ...old]);
      setIsVisible(true);
      setTopOverlay([renderOverlay]);
    },
    [popOverlay]
  );

  const Message = useMemo(
    () =>
      ({ message, onOK }) =>
        (
          <View style={styles.defaultModal}>
            <Text style={styles.defaultModalContent}>{message}</Text>
            <View style={styles.defaultModalButtons}>
              <Button
                title="確定"
                style={defaultModalButtonStyle}
                onPress={() => {
                  onOK && onOK();
                  popOverlay();
                }}
              />
            </View>
          </View>
        ),
    [popOverlay]
  );

  const Confirm = useMemo(
    () =>
      ({ message, onOK, onCancel }) =>
        (
          <View style={styles.defaultModal}>
            <Text style={styles.defaultModalContent}>{message}</Text>
            <View style={styles.defaultModalButtons}>
              <Button
                title="取消"
                negative
                style={defaultModalButtonStyle}
                onPress={() => {
                  onCancel && onCancel();
                  popOverlay();
                }}
              />
              <View style={{ width: 8 }} />
              <Button
                title="確定"
                style={defaultModalButtonStyle}
                onPress={() => {
                  onOK && onOK();
                  popOverlay();
                }}
              />
            </View>
          </View>
        ),
    [popOverlay]
  );

  const onAnimateEnd = useCallback(() => {
    if (!isVisible) {
      if (overlays[0]) {
        // show next top overlay
        setTopOverlay([overlays[0]]);
        setIsVisible(true);
      } else {
        setTopOverlay(null);
      }
    }
  }, [isVisible, overlays]);

  const value = useMemo(
    () => ({
      pushOverlay,
    }),
    [pushOverlay]
  );

  return (
    <OverlayContext.Provider value={value}>
      {children}
      <UniversalOverlay
        isVisible={isVisible && !!topOverlay}
        style={styles.overlay}
        overlayStyle={styles.overlayOverlay}
        fullScreen
        onAnimateEnd={onAnimateEnd}>
        {topOverlay ? topOverlay[0]({ popOverlay, Message, Confirm }) : null}
      </UniversalOverlay>
    </OverlayContext.Provider>
  );
};

export default OverlayManager;
