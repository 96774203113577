import Modal from 'modal-react-native-web';
import React, { useState, useRef, useEffect } from 'react';
import { Platform, Animated } from 'react-native';
import { Overlay } from 'react-native-elements';

const ModalComponent = Platform.OS === 'web' ? Modal : undefined;

const UniversalOverlay = ({ children, isVisible, animation, onAnimateEnd, ...props }) => {
  const [isHidden, setIsHidden] = useState(!isVisible);

  const animatedValue = useRef(new Animated.Value(isVisible ? 1 : 0)).current;
  const animatingTimer = useRef();
  useEffect(() => {
    clearTimeout(animatingTimer.current);
    if (animation) {
      animatingTimer.current = setTimeout(() => {
        if (isVisible) {
          setIsHidden(false);
        }
        Animated.timing(animatedValue, {
          toValue: isVisible ? 1 : 0,
          duration: 300,
          useNativeDriver: true,
        }).start(({ finished }) => {
          if (finished) {
            if (!isVisible) {
              setIsHidden(true);
            }
            onAnimateEnd && onAnimateEnd();
          }
        });
      });
    } else {
      animatingTimer.current = setTimeout(() => {
        setIsHidden(!isVisible);
        onAnimateEnd && onAnimateEnd();
      }, 300);
    }
  }, [isVisible, animation]);

  return (
    // prop isVisible changed to false will rennder and unmount Overlay
    // to prevent blank view blocks screen issue in iOS
    isVisible && (
      <Overlay ModalComponent={ModalComponent} isVisible={!isHidden} {...props}>
        {animation ? (
          <Animated.View style={{ transform: [{ scale: animatedValue }] }}>{children}</Animated.View>
        ) : (
          children
        )}
      </Overlay>
    )
  );
};

UniversalOverlay.defaultProps = {
  animation: true,
};

export default UniversalOverlay;
