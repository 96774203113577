import React, { useContext, useCallback, useEffect, useRef, useState, createContext, useMemo } from 'react';
import { View, StyleSheet, Animated } from 'react-native';

import Avatar from '../../components/Avatar';
import NotificationIconWithBadge from '../../components/Notification/NotificationIconWithBadge';
import { Link, useRouteMatch } from '../../components/UniversalRouter';
import { UserContext } from '../../containers/UserProvider';
import HomeIcon from '../../images/HomeIcon';
import SearchIcon from '../../images/SearchIcon';
import { useMobileView } from '../../pages/Root/useMobileView';
import { colorStyleProps } from '../../styles';
const styles = StyleSheet.create({
  main: {
    position: 'absolute',
    bottom: 20,

    backgroundColor: colorStyleProps.tpWhite.color,
    borderRadius: 50,
    overflow: 'hidden',

    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom: 20,

    borderWidth: 1,
    borderColor: colorStyleProps.grayF2.color,
    shadowColor: 'rgba(0, 0, 0, 0.05)',
    shadowOffset: { width: 0, height: 4 },
    shadowRadius: 4,

    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    marginLeft: 15,
    marginRight: 15,
  },
});

const FloatingActionsContext = createContext();
const FloatingActions = ({ children }) => {
  const { userProfile } = useContext(UserContext);
  const [isMobileView] = useMobileView();
  const scrollableRef = useRef();
  const [isInteracting, setIsInteracting] = useState(false);

  const isHomePage = !!useRouteMatch({
    path: '/',
    exact: true,
  });
  const isSearchPage = !!useRouteMatch('/search');
  const isNotificationPage = !!useRouteMatch('/notification');
  const isPageWithFloatingActions = isHomePage || isSearchPage || isNotificationPage;

  const onPressHome = useCallback(() => {
    if (isHomePage && scrollableRef.current) {
      scrollableRef.current.scrollTo({ x: 0, y: 0, animated: true });
    }
  }, [isHomePage]);

  const animatedValue = useRef(new Animated.Value(isInteracting ? 0 : 1)).current;
  const animatingTimer = useRef();
  useEffect(() => {
    clearTimeout(animatingTimer.current);
    animatingTimer.current = setTimeout(() => {
      Animated.timing(animatedValue, {
        toValue: isInteracting ? 0 : 1,
        duration: 300,
        useNativeDriver: true,
      }).start();
    });
  }, [isInteracting]);

  const value = useMemo(
    () => ({
      scrollableRef,
      setIsInteracting,
    }),
    [scrollableRef, setIsInteracting]
  );

  return (
    <>
      <FloatingActionsContext.Provider value={value}>{children}</FloatingActionsContext.Provider>
      {!isMobileView || !isPageWithFloatingActions || isInteracting ? null : (
        <Animated.View
          style={[
            styles.main,
            {
              opacity: animatedValue,
            },
          ]}>
          <Link to="/" onPress={onPressHome}>
            <View style={styles.icon}>
              <HomeIcon active={isHomePage} />
            </View>
          </Link>
          <Link to="/search">
            <View style={styles.icon}>
              <SearchIcon active={isSearchPage} />
            </View>
          </Link>
          <Link to="/notification">
            <View style={styles.icon}>
              <NotificationIconWithBadge active={isNotificationPage} />
            </View>
          </Link>
          <Link to={`/user/${userProfile?.uid}`}>
            <View style={styles.icon}>
              <Avatar size={30} uri={userProfile?.image} />
            </View>
          </Link>
        </Animated.View>
      )}
    </>
  );
};

export default FloatingActions;

export const useFloatingActions = () => {
  const { scrollableRef, setIsInteracting } = useContext(FloatingActionsContext);

  const [isScrolling, setIsScrolling] = useState(false);
  const scrollingTimer = useRef();
  const onScroll = useCallback(() => {
    setIsScrolling(true);
    clearTimeout(scrollingTimer.current);
    scrollingTimer.current = setTimeout(() => setIsScrolling(false), 300);
  }, []);

  const [isDragging, setIsDragging] = useState(false);
  const onScrollBeginDrag = useCallback(() => setIsDragging(true), []);
  const onScrollEndDrag = useCallback(() => setIsDragging(false), []);

  const isInteracting = isScrolling || isDragging;
  useEffect(() => {
    setIsInteracting(isInteracting);
  }, [isInteracting]);

  return {
    scrollableRef,
    callbacks: { onScroll, onScrollBeginDrag, onScrollEndDrag },
  };
};
