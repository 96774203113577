import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  flexableMargin: {
    flexBasis: 20,
    flexGrow: 1,
    flexShrink: 1,
  },
  fixedMargin: {
    flexBasis: 20,
    flexGrow: 0,
    flexShrink: 0,
  },
});
