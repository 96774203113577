import React, { useCallback } from 'react';
import { View, StyleSheet } from 'react-native';

import More from '../../images/More';
import PopupMenu from '../PopupMenu/PopupMenu';
const styles = StyleSheet.create({
  menu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const MoreMenu = ({ onDeleteComment, commentId }) => {
  const deleteComment = useCallback(() => {
    onDeleteComment(commentId);
  }, [onDeleteComment, commentId]);
  return (
    <View style={styles.menu}>
      <PopupMenu
        options={[
          {
            onSelect: deleteComment,
            text: '刪除留言',
          },
        ]}>
        <More />
      </PopupMenu>
    </View>
  );
};

export default MoreMenu;
