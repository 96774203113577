import axios from 'axios';

export const getComments = (slug, { limit, offset } = {}) =>
  axios.get(`/api/plans/${slug}/comments`, {
    params: {
      limit,
      offset,
    },
  });

export const postComments = (slug, data) => axios.post(`/api/plans/${slug}/comments`, data);

export const putComments = (slug, comment_id, data) => axios.put(`/api/plans/${slug}/comments/${comment_id}`, data);

export const deleteComments = (slug, comment_id) => axios.delete(`/api/plans/${slug}/comments/${comment_id}`);
