import React from 'react';

import Tooltip from '../Tooltip';
import InfoSvg from './info.svg';

const Info = ({ text, children }) => (
  <Tooltip text={text}>
    <InfoSvg width={20} height={20} viewBox="0 0 14 14" />
  </Tooltip>
);

export default Info;
