import React from 'react';

import Svg from './cross.svg';
import SvgDark from './cross_dark.svg';
import SvgLight from './cross_light.svg';

const Cross = ({ type, ...props }) => {
  if (type === 'dark') {
    return <SvgDark {...props} viewBox="0 0 24 24" />;
  }
  if (type === 'light') {
    return <SvgLight {...props} viewBox="0 0 24 24" />;
  }
  return <Svg {...props} viewBox="0 0 24 24" />;
};

export default Cross;
