import { StyleSheet } from 'react-native';

import { fontStyleProps, colorStyleProps } from '../../styles';

const defaultProps = {
  container: {},
  main: {},
  negativeMain: {},
  button: {
    borderRadius: 10,
    borderWidth: 0,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  negativeButton: {
    borderWidth: 1,
    backgroundColor: 'transparent',
  },
  title: {
    ...fontStyleProps.bold16,
    paddingLeft: 10,
    paddingRight: 10,
    color: colorStyleProps.tpWhite.color,
  },
  negativeTitle: {},
};

export const green = StyleSheet.create({
  container: defaultProps.container,
  main: defaultProps.main,
  negativeMain: defaultProps.negativeMain,
  button: {
    ...defaultProps.button,
    backgroundColor: colorStyleProps.tpGreen.color,
  },
  negativeButton: {
    ...defaultProps.negativeButton,
    borderColor: colorStyleProps.tpGreen.color,
  },
  title: {
    ...defaultProps.title,
  },
  negativeTitle: {
    ...defaultProps.negativeTitle,
    color: colorStyleProps.tpGreen.color,
  },
});

export const black = StyleSheet.create({
  container: defaultProps.container,
  main: defaultProps.main,
  negativeMain: defaultProps.negativeMain,
  button: {
    ...defaultProps.button,
    backgroundColor: colorStyleProps.keyBlack.color,
  },
  negativeButton: {
    ...defaultProps.negativeButton,
    borderColor: colorStyleProps.keyBlack.color,
  },
  title: {
    ...defaultProps.title,
  },
  negativeTitle: {
    ...defaultProps.negativeTitle,
    color: colorStyleProps.keyBlack.color,
  },
});

export const gold = StyleSheet.create({
  container: defaultProps.container,
  main: defaultProps.main,
  negativeMain: defaultProps.negativeMain,
  button: {
    ...defaultProps.button,
    backgroundColor: colorStyleProps.ppro.color,
  },
  negativeButton: {
    ...defaultProps.negativeButton,
    borderColor: colorStyleProps.ppro.color,
  },
  title: {
    ...defaultProps.title,
  },
  negativeTitle: {
    ...defaultProps.negativeTitle,
    color: colorStyleProps.ppro.color,
  },
});

export const gray = StyleSheet.create({
  container: defaultProps.container,
  main: defaultProps.main,
  negativeMain: defaultProps.negativeMain,
  button: {
    ...defaultProps.button,
    backgroundColor: colorStyleProps.gray82.color,
  },
  negativeButton: {
    ...defaultProps.negativeButton,
    borderColor: colorStyleProps.gray82.color,
  },
  title: {
    ...defaultProps.title,
  },
  negativeTitle: {
    ...defaultProps.negativeTitle,
    color: colorStyleProps.gray82.color,
  },
});

export const red = StyleSheet.create({
  container: defaultProps.container,
  main: defaultProps.main,
  negativeMain: defaultProps.negativeMain,
  button: {
    ...defaultProps.button,
    backgroundColor: colorStyleProps.red.color,
  },
  negativeButton: {
    ...defaultProps.negativeButton,
    borderColor: colorStyleProps.red.color,
  },
  title: {
    ...defaultProps.title,
  },
  negativeTitle: {
    ...defaultProps.negativeTitle,
    color: colorStyleProps.red.color,
  },
});
