import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { Animated, Text, TouchableOpacity, Platform } from 'react-native';

import Cross from '../images/Cross';
import { colorStyleProps, fontStyleProps } from '../styles';

const styles = {
  container: {
    position: 'fixed',
    zIndex: 99999,
    right: '4.86vw',
    top: 100,
    width: 384,
    height: 80,
    borderRadius: 10,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 30,
    paddingRight: 30,
  },
  success: {
    backgroundColor: '#29B864',
  },
  fail: {
    backgroundColor: '#EB5757',
  },
  text: {
    ...fontStyleProps.regular16,
    color: colorStyleProps.tpWhite.color,
    flexGrow: 1,
    flexShrink: 1,
  },
};

if (Platform.OS !== 'web') {
  styles.container.position = 'absolute';
  styles.container.right = 50;
}

export const ToastContext = React.createContext();

const Toast = ({ children }) => {
  const [isVisible, setVisible] = useState(false);
  const [contentToShow, setContentToShow] = useState(false);

  const [isHidden, setIsHidden] = useState(!isVisible);

  const animatedValue = useRef(new Animated.Value(isVisible ? 1 : 0)).current;
  const animatingTimer = useRef();
  useEffect(() => {
    clearTimeout(animatingTimer.current);

    animatingTimer.current = setTimeout(() => {
      if (isVisible) {
        setIsHidden(false);
      }
      Animated.timing(animatedValue, {
        toValue: isVisible ? 1 : 0,
        duration: 500,
        useNativeDriver: true,
      }).start(({ finished }) => {
        if (finished) {
          if (!isVisible) {
            setIsHidden(true);
          }
        }
      });
    });
  }, [isVisible]);

  const autoHideTimer = useRef();
  useEffect(() => {
    if (!isHidden) {
      clearTimeout(autoHideTimer.current);
      autoHideTimer.current = setTimeout(() => {
        setVisible(false);
      }, contentToShow?.timeout);
    }
  }, [isHidden, contentToShow]);

  const show = useCallback((text, type = 'success', timeout = 3000) => {
    setContentToShow({
      text,
      type,
      timeout,
    });
    setVisible(true);
  }, []);

  const hide = useCallback(() => {
    setVisible(false);
  }, []);

  const value = useMemo(
    () => ({
      showToast: show,
    }),
    [show]
  );

  return (
    <ToastContext.Provider value={value}>
      {children}
      {Platform.OS === 'web' ? (
        <Animated.View
          style={[
            styles.container,
            styles[contentToShow?.type],
            {
              transform: [{ scale: animatedValue }],
              opacity: animatedValue,
            },
          ]}>
          <Text style={styles.text}>{contentToShow?.text}</Text>
          <TouchableOpacity onPress={hide}>
            <Cross type="light" />
          </TouchableOpacity>
        </Animated.View>
      ) : null}
    </ToastContext.Provider>
  );
};

export default Toast;
