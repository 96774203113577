import { StyleSheet } from 'react-native';

import { fontStyleProps, colorStyleProps } from '../../styles';

export default StyleSheet.create({
  landing: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  left: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 350,
  },
  title: {
    ...fontStyleProps.boldNunito56,
    ...colorStyleProps.tpGreen,
    fontSize: 36,
    lineHeight: 54,
    textAlign: 'center',
  },
  subtitle: {
    ...fontStyleProps.bold24,
    ...colorStyleProps.tpBlack,
  },
  image: {
    marginTop: 20,
    marginBottom: 20,
    marginRight: 10,
    marginLeft: 10,
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
  },
  first: {
    marginTop: 90,
  },
  second: {
    marginTop: 32,
  },
  last: {
    marginTop: 10,
    marginHorizontal: 10,
  },
});
