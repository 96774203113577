export const subjectWithEmoji = {
  0: '📝國語',
  1: '📐數學',
  2: '☘生活',
  3: '🌱自然',
  4: '👥社會',
  5: '🖼藝術', // 🎎
  6: '🏀健康與體育',
  7: '📝國文',
  8: '🔠英語',
  9: '🦠生物', // 🧬
  10: '🔬理化',
  11: '🌍地科',
  12: '⛰地理',
  13: '🗿歷史',
  14: '👨‍👨‍👧公民',
  15: '🔠英文',
  16: '⚛️物理',
  17: '🧪化學',
  18: '綜合',
  19: '校本課程',
  20: '輔導',
  21: '家政',
  22: '童軍',
  23: '音樂',
  24: '視覺藝術',
  25: '表演藝術',
  26: '健教',
  27: '體育',
  28: '生活科技',
  29: '資訊',
  30: '[特殊需求] 情意發展',
  31: '[特殊需求] 領導才能',
  32: '[特殊需求] 創造力',
  33: '[特殊需求] 獨立研究',
  34: '[特殊需求] 生活管理',
  35: '[特殊需求] 社會技巧',
  36: '[特殊需求] 學習策略',
  37: '[特殊需求] 職業教育',
  38: '[特殊需求] 溝通訓練',
  39: '[特殊需求] 點字',
  40: '[特殊需求] 定向行動',
  41: '[特殊需求] 功能性動作訓練',
  42: '[特殊需求] 輔助科技應用'
};

export const subject = {
  0: '國語',
  1: '數學',
  2: '生活',
  3: '自然',
  4: '社會',
  5: '藝術',
  6: '健康與體育',
  7: '國文',
  8: '英語',
  9: '生物',
  10: '理化',
  11: '地科',
  12: '地理',
  13: '歷史',
  14: '公民',
  15: '英文',
  16: '物理',
  17: '化學',
  18: '綜合',
  19: '校本課程',
  20: '輔導',
  21: '家政',
  22: '童軍',
  23: '音樂',
  24: '視覺藝術',
  25: '表演藝術',
  26: '健教',
  27: '體育',
  28: '生活科技',
  29: '資訊',
  30: '[特殊需求] 情意發展',
  31: '[特殊需求] 領導才能',
  32: '[特殊需求] 創造力',
  33: '[特殊需求] 獨立研究',
  34: '[特殊需求] 生活管理',
  35: '[特殊需求] 社會技巧',
  36: '[特殊需求] 學習策略',
  37: '[特殊需求] 職業教育',
  38: '[特殊需求] 溝通訓練',
  39: '[特殊需求] 點字',
  40: '[特殊需求] 定向行動',
  41: '[特殊需求] 功能性動作訓練',
  42: '[特殊需求] 輔助科技應用'
};

export const subjectOptions = Object.keys(subject).map((value) => ({ value: Number(value), label: subject[value] }));

export const phase = {
  E: '國小',
  J: '國中',
  U: '高中',
  S: '特教',
};

export const phaseOptions = Object.keys(phase).map((value) => ({ value, label: `${value} - ${phase[value]}` }));

export const grade = {
  1: '小一',
  2: '小二',
  3: '小三',
  4: '小四',
  5: '小五',
  6: '小六',
  7: '國一',
  8: '國二',
  9: '國三',
  10: '高一',
  11: '高二',
  12: '高三',
  13: '特教'
};

export const gradeOptions = [];
Object.keys(grade).forEach((value) => {
  gradeOptions.push({
    value: Number(value),
    label: grade[value],
  });
});

export const coreOptions = [
  {
    value: 'A1',
    label: '身心素質與自我精進',
    category: 1,
  },
  {
    value: 'A2',
    label: '系統思考與解決問題',
    category: 1,
  },
  {
    value: 'A3',
    label: '規劃執行與創新應變',
    category: 1,
  },
  {
    value: 'B1',
    label: '符號運用與溝通表達',
    category: 2,
  },
  {
    value: 'B2',
    label: '科技資訊與媒體素養',
    category: 2,
  },
  {
    value: 'B3',
    label: '藝術涵養與美感素養',
    category: 2,
  },
  {
    value: 'C1',
    label: '道德實踐與公民意識',
    category: 3,
  },
  {
    value: 'C2',
    label: '人際關係與團隊合作',
    category: 3,
  },
  {
    value: 'C3',
    label: '多元文化與國際理解',
    category: 3,
  },
];

export const coreOptionsMap = coreOptions.reduce((acc, it) => ({ ...acc, [it.value]: it }), {});

export const ideas = {
  0: {
    name: '改變與穩定',
    content: '系統隨著時間改變，產生某些因素的變化；當系統內各項因素不變的情況下，達到穩定的狀態。',
  },
  1: {
    name: '關係',
    content: '人與事或物之間的關係。',
  },
  2: {
    name: '模式',
    content: '在事物上產生一種規律的樣態。',
  },
  3: {
    name: '結構與功能',
    content: '相互關聯元件間的排列或組織，形成特有功能。',
  },
  4: {
    name: '系統與模型',
    content: '一群有關係的元件，依據某種規則運作而形成的整體。',
  },
  5: {
    name: '次序',
    content: '有原則的排列。',
  },
  6: {
    name: '循環',
    content: '隨著時間重複出現特定形式。',
  },
  7: {
    name: '交互作用',
    content: '物質間透過任何形式的互動產生的影響。',
  },
  8: {
    name: '互動與關聯',
    content: '探究人事物與環境間的互動情形與其關連性。',
  },
  9: {
    name: '差異與多元',
    content: '比較與辨別其差異現象，了解其多元性。',
  },
  10: {
    name: '變遷與因果',
    content: '不同時空條件下，各種事物與環境間的變遷過程與因果關係。',
  },
  11: {
    name: '選擇與責任',
    content: '追求發展或參與事務時，必須做出合宜選擇並負起應有責任。',
  },
};

export const ideaOptions = [];
Object.keys(ideas).forEach((value) => {
  ideaOptions.push({
    value: Number(value),
    label: ideas[value].name,
    tip: ideas[value].content,
  });
});

const keywords = {
  情意: [
    '接受',
    '詢問',
    '選擇',
    '描述',
    '領會',
    '仿效',
    '給予',
    '維持',
    '包含',
    '識別',
    '定位',
    '指定',
    '指明',
    '回應',
    '使用',
    '反應',
    '幫助',
    '順從',
    '遵守',
    '討論',
    '標明',
    '執行',
    '實行',
    '呈現',
    '覺察',
    '背誦',
    '報告',
    '編寫',
    '評價',
    '完成',
    '區分',
    '解釋',
    '採用',
    '形成',
    '著手',
    '引致',
    '參與',
    '證明',
    '提議',
    '解讀',
    '分享',
    '研究',
    '操作或運作',
    '重組或組織',
    '改變',
    '安排',
    '結合',
    '對比',
    '辯護',
    '歸納',
    '修改',
    '整理',
    '組織',
    '準備',
    '連結',
    '綜合',
    '價值觀的型塑',
    '品格的養成',
    '扮演',
    '區別',
    '展現',
    '顯示',
    '影響',
    '傾聽',
    '執行或表現',
    '具備',
    '修訂',
    '服務或供應',
    '解決',
    '證實',
  ],
  技能: [
    '感知',
    '挑選',
    '描述',
    '發現',
    '區分',
    '辨別',
    '識別',
    '使脫離',
    '連結',
    '選擇',
    '準備狀態',
    '著手',
    '表現',
    '解釋',
    '促使',
    '進行',
    '反應',
    '回應',
    '示範',
    '開始',
    '自願',
    '應用',
    '收集',
    '建立',
    '校準',
    '建造',
    '拆卸',
    '解剖',
    '固定',
    '修理',
    '磨成',
    '加熱',
    '操作',
    '測量',
    '改正',
    '混和',
    '組織',
    '素描',
    '運作',
    '適應',
    '改編',
    '修改',
    '改變',
    '重整',
    '重組',
    '修訂',
    '變更',
    '獨創',
    '安排',
    '結合',
    '組成',
    '建造或建構',
    '設計',
    '發明',
  ],
  認知: [
    '記憶',
    '認識',
    '回憶',
    '定義',
    '描述',
    '識別',
    '標明',
    '列舉',
    '配合',
    '指定',
    '概述',
    '複製',
    '陳述',
    '理解',
    '說明',
    '舉例',
    '分類',
    '推斷',
    '比較',
    '解釋',
    '轉換',
    '辯護',
    '辨別',
    '評價',
    '引申',
    '歸納',
    '釋義',
    '預測',
    '改寫',
    '應用',
    '執行',
    '實施',
    '計算',
    '證明',
    '發現',
    '操作',
    '修改',
    '預估',
    '準備',
    '連結',
    '示範',
    '解決',
    '運用',
    '分析',
    '差別',
    '組織',
    '歸因',
    '拆解',
    '圖示',
    '區別',
    '推論',
    '指出',
    '選擇',
    '區分',
    '細分',
    '評鑑',
    '檢核',
    '評述',
    '總結',
    '對照',
    '詮釋',
    '關聯',
    '證實',
    '創造',
    '計劃',
    '製作',
  ],
};

export const keywordOptions = [];
Object.keys(keywords).forEach((section, idx) => {
  keywords[section].forEach((value) => {
    keywordOptions.push({
      section,
      value,
      label: value,
      category: idx + 1,
    });
  });
});
