import React from 'react';
import { View } from 'react-native';
import RenderHtml from 'react-native-render-html';

import CenterText from '../../components/CenterText';
import AppleSignInButton from '../../components/SignInButton/Apple';
import GoogleSignInButton from '../../components/SignInButton/Google';
import { useMobileView } from '../Root/useMobileView';
import LandingSvg from './LandingSvg';
import defaultStyles from './styles';
import mobileStyles from './styles.native';

const Landing = () => {
  const [isMobileView, styles] = useMobileView(mobileStyles, defaultStyles);

  if (!isMobileView) {
    return (
      <View style={styles.landing}>
        <View style={styles.left}>
          <CenterText textStyle={styles.title}>TeachPlanner</CenterText>
          <CenterText textStyle={styles.subtitle}>輕鬆製作教案</CenterText>
          <View style={styles.first}>
            <GoogleSignInButton style={styles.first} />
          </View>
          <View style={styles.second}>
            <AppleSignInButton style={styles.second} />
          </View>
          <View style={styles.last}>
            <RenderHtml
              contentWidth="100%"
              source={{
                html: `<p>登入即表示您同意我們的<a href="https://docs.google.com/document/d/1MzPDnfnF1GyB1Of-hGXFcnmCxT3Dh6HqC3zIULSTuSY/edit?usp=sharing" target="_blank"><b>服務條款</b></a>，並確您已閱讀我們的<a href="https://docs.google.com/document/d/1C_rSkrQB8Ei1AmVN4-Cfb1KOob7HQ7dP5OaEvt0aj1Q/edit?usp=sharing" target="_blank"><b>隱私權政策</b></a>，瞭解我們如何蒐集、使用及分享您的資料。</p>`,
              }}
            />
          </View>
        </View>
        <View style={styles.image}>
          <LandingSvg />
        </View>
      </View>
    );
  }
  return (
    <View style={styles.landing}>
      <CenterText textStyle={styles.title}>TeachPlanner</CenterText>
      <View style={styles.image}>
        <LandingSvg />
      </View>
      <View style={styles.buttons}>
        <CenterText textStyle={styles.subtitle}>輕鬆製作教案</CenterText>
        <View style={styles.first}>
          <GoogleSignInButton style={styles.first} />
        </View>
        <View style={styles.second}>
          <AppleSignInButton style={styles.second} />
        </View>
        <View style={styles.last}>
          <RenderHtml
            contentWidth="100%"
            source={{
              html: `<p>登入即表示您同意我們的<a href="https://docs.google.com/document/d/1MzPDnfnF1GyB1Of-hGXFcnmCxT3Dh6HqC3zIULSTuSY/edit?usp=sharing" target="_blank"><b>服務條款</b></a>，並確您已閱讀我們的<a href="https://docs.google.com/document/d/1C_rSkrQB8Ei1AmVN4-Cfb1KOob7HQ7dP5OaEvt0aj1Q/edit?usp=sharing" target="_blank"><b>隱私權政策</b></a>，瞭解我們如何蒐集、使用及分享您的資料。</p>`,
            }}
          />
        </View>
      </View>
    </View>
  );
};

export default Landing;
