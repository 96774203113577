import * as Analytics from 'expo-firebase-analytics';
import React, { useCallback } from 'react';
import { View, Text } from 'react-native';

import apis from '../../apis';
import CardsView from '../../components/CardsView';
import withColumnCalculator from '../../components/CardsView/withColumnCalculator';
import DataList from '../../components/DataList';
import PlanCard from '../../components/PlanCard';
import LitePlanCard from '../../components/PlanCard/LitePlanCard';
import { useHistoryWithSpec } from '../../components/UniversalRouter';
import { useFloatingActions } from '../../containers/FloatingActions';
import SearchBar from '../NavigationBar/SearchBar';
import { useMobileView } from '../Root/useMobileView';
import defaultStyles from './styles';
import mobileStyles from './styles.native';

const PAGE_SIZE = 24;

const renderPlanCard = (card, fixedWith) => {
  const style = fixedWith ? { width: 356 } : undefined;
  return <PlanCard key={card.id} plan={card} style={style} />;
};

const renderLitePlanCard = (card, fixedWith) => {
  const style = fixedWith ? { width: 356 } : undefined;
  return <LitePlanCard key={card.id} plan={card} style={style} />;
};

const Search = withColumnCalculator(({ numColumns }) => {
  const [isMobileView, styles] = useMobileView(mobileStyles, defaultStyles);
  const history = useHistoryWithSpec();

  const keyword = history.location.query.keyword;

  const onFetch = useCallback(
    async (offset) => {
      if (!keyword) {
        return null;
      }

      try {
        const { data } = await apis.plans.searchPlans({
          keyword,
          sort: 'desc',
          offset,
          limit: PAGE_SIZE,
        });
        Analytics.logEvent('search_plan', {
          keyword,
        });

        const newItems = [];
        if (data.plans.length) {
          newItems.push({
            key: `${offset}-${data.plans[0].slug}`,
            data: data.plans,
          });
        }

        return {
          total: data.total,
          next: offset + data.plans.length,
          items: newItems,
        };
      } catch (error) {
        // TODO handle error
        console.error(error);
      }
    },
    [keyword]
  );

  const renderItem = useCallback(
    ({ item }) => {
      const renderCard = isMobileView ? renderLitePlanCard : renderPlanCard;
      return <CardsView cards={item.data} renderCard={renderCard} numColumns={isMobileView ? 2 : numColumns} />;
    },
    [numColumns, isMobileView]
  );

  const { scrollableRef, callbacks } = useFloatingActions();

  return (
    <View style={styles.main}>
      {isMobileView ? (
        <View style={{ paddingVertical: 25 }}>
          <SearchBar style={{ maxWidth: '100%', width: '100%' }} keepKeywordAfterSubmit />
        </View>
      ) : (
        <View>
          <Text style={styles.title}>搜尋結果</Text>
          {keyword && <Text style={styles.keyword}>{keyword}</Text>}
        </View>
      )}
      <DataList
        scrollableStyle={{ paddingRight: '4.86vw' }}
        scrollableRef={scrollableRef}
        renderItem={renderItem}
        onFetch={onFetch}
        emptyString="沒有任何符合條件的資料"
        {...callbacks}
      />
    </View>
  );
});

export default Search;
