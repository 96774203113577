import React from 'react';
import { View, Text } from 'react-native';

import styles from './styles';

const CenterText = ({ containerStyle, children, textStyle }) => {
  return (
    <View style={[styles.container, containerStyle]}>
      <Text style={[styles.text, textStyle]}>{children}</Text>
    </View>
  );
};

export default CenterText;
