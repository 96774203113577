import { StyleSheet } from 'react-native';

import { colorStyleProps } from '../../../styles';
import styles from '../styles';

const s = StyleSheet.create({
  text: {
    color: colorStyleProps.pureBlack.color,
    opacity: 0.54,
  },
});

export default {
  button: styles.button,
  text: StyleSheet.compose(styles.text, s.text),
};
