import React, { useMemo, useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';

import { keywordOptions } from '../../../options';
import { fontStyleProps, colorStyleProps } from '../../../styles';
import Capsule from '../../Plan/Capsule';
import AddPicker from './AddPicker';
import CloseSvg from './CloseSvg';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  capsuleContainer: {
    marginRight: 12,
    marginTop: 6,
    marginBottom: 6,
  },
});

const KeywordPicker = ({ selected, setSelected }) => {
  const [opened, setOpened] = useState(false);

  const [selectedOptions, remainOptions] = useMemo(() => {
    const set = new Set(selected);
    const newSet = new Set();
    const options1 = [];
    const options2 = [];
    keywordOptions.forEach(({ label: text, ...o }) => {
      if (set.has(o.value)) {
        if (!newSet.has(o.value)) {
          options1.push({
            ...o,
            text,
          });
          newSet.add(o.value);
        }
      } else {
        options2.push({
          ...o,
          text: undefined,
          children: (
            <Text
              style={{
                paddingTop: 5,
                paddingHorizontal: 10,
                ...fontStyleProps.regular12,
                color: colorStyleProps.keyBlack.color,
              }}>
              {text}
            </Text>
          ),
          onSelect: (clicked) => setSelected((old) => [...old, clicked]),
        });
      }
    });
    // no options to select
    if (!options2.length) {
      setOpened(false);
    }
    return [options1, options2];
  }, [selected]);

  return (
    <View style={styles.container}>
      <AddPicker
        opened={opened}
        setOpened={setOpened}
        selectedOptions={selectedOptions}
        remainOptions={remainOptions}
        selected={selected}
        setSelected={setSelected}>
        {({ onUnselect }) =>
          selectedOptions.map((o) => (
            <View style={styles.capsuleContainer}>
              <Capsule
                key={o.value}
                style={{
                  borderColor: colorStyleProps.keyBlack.color,
                  backgroundColor: colorStyleProps.keyBlack.color,
                }}>
                <Text style={{ ...fontStyleProps.regular14, color: colorStyleProps.tpWhite.color }}>{o.text}</Text>
                <View style={{ marginLeft: 15 }}>
                  <TouchableOpacity onPress={() => onUnselect(o.value)}>
                    <CloseSvg color={colorStyleProps.tpWhite.color} />
                  </TouchableOpacity>
                </View>
              </Capsule>
            </View>
          ))
        }
      </AddPicker>
    </View>
  );
};

KeywordPicker.defaultProps = {
  selected: [],
  setSelected: () => {},
};

export default KeywordPicker;
