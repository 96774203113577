import React from 'react';
import { View, Platform } from 'react-native';
import { Menu, MenuOptions, MenuTrigger, renderers } from 'react-native-popup-menu';

const { Popover } = renderers;

const style = {};

if (Platform.OS === 'web') {
  style.position = 'fixed';
}

const FixedPopover = (props) => {
  return (
    <View style={style}>
      <Popover {...props} anchorStyle={{ opacity: 0 }} />
    </View>
  );
};

const Popup = ({ trigger, children, menuProps, optionsProps, onPress }) => {
  return (
    <Menu renderer={FixedPopover} rendererProps={{ placement: 'bottom' }} {...menuProps}>
      <MenuTrigger onPress={onPress}>{trigger}</MenuTrigger>
      <MenuOptions
        customStyles={{
          optionsContainer: {
            borderRadius: 6,
            ...(optionsProps.customStyles && optionsProps.customStyles.optionsContainer),
          },
        }}>
        {children}
      </MenuOptions>
    </Menu>
  );
};

Popup.defaultProps = {
  optionsProps: {},
};

export default Popup;
