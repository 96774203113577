import * as Linking from '../../../components/Linking';
import FacebookLogoSvg from '../../../images/FBLogo';
import InstagramLogoSvg from '../../../images/IGLogo';
import YoutubeLogoSvg from '../../../images/YouTubeLogo';

const socialNetworks = [
  {
    type: 'ig',
    icon: InstagramLogoSvg,
    label: 'IG 連結',
  },
  {
    type: 'fb',
    icon: FacebookLogoSvg,
    label: 'Facebook 連結',
  },
  {
    type: 'yt',
    icon: YoutubeLogoSvg,
    label: 'Youtube 頻道連結',
  },
];

export default socialNetworks;

export const withOpen = (links) =>
  socialNetworks.map((sn, idx) => {
    if (links[idx]) {
      return { ...sn, open: () => Linking.openURL(links[idx]) };
    }
    return sn;
  });
