import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';

import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import Info from '../../../components/Info';
import { subjectOptions, phaseOptions, ideas as ideaMap } from '../../../options';
import { fontStyleProps, colorStyleProps } from '../../../styles';
import CoreListPicker from './CoreListPicker';
import IdeaPicker from './IdeaPicker';
import KeywordPicker from './KeywordPicker';
import PhaseCoreSelector from './PhaseCoreSelector';

const styles = StyleSheet.create({
  main: {
    minWidth: 640,

    paddingHorizontal: `${(100 * 120) / 1440}vw`,
    paddingVertical: 40,
    backgroundColor: colorStyleProps.tpOG.color,
  },
  title: {
    ...fontStyleProps.bold36,
  },
  body: {
    marginTop: 40,
    backgroundColor: colorStyleProps.tpWhite.color,
    borderRadius: 20,

    paddingVertical: 35,
    paddingHorizontal: 50,

    // make dropdown overlap on other UIs
    zIndex: 1,
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    ...fontStyleProps.regular14,
    color: colorStyleProps.gray82.color,
  },

  footer: {
    width: '100%',
    paddingTop: 46,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const btnStyle = {
  main: {
    marginHorizontal: 5,
  },
  button: {
    paddingHorizontal: 80,
  },
};

const Step1 = ({ changeStep, plan, setPlan, phase, setPhase }) => {
  const [subject, setSubject] = useState(plan.subject);
  const [coreList, setCoreList] = useState(plan.coreList);
  const [cores, setCores] = useState(plan.cores);
  const [ideas, setIdeas] = useState(plan.ideas);
  const [keywords, setKeywords] = useState(plan.keywords);

  useEffect(() => {
    setPlan((oldValue) => ({ ...oldValue, subject }));
  }, [subject]);

  useEffect(() => {
    setPlan((oldValue) => ({ ...oldValue, coreList }));
  }, [coreList]);

  useEffect(() => {
    setPlan((oldValue) => ({ ...oldValue, cores }));
  }, [cores]);

  useEffect(() => {
    const idea = ideas.map((value) => ideaMap[value].content).join('\n');
    setPlan((oldValue) => ({ ...oldValue, ideas, idea }));
  }, [ideas]);

  useEffect(() => {
    setPlan((oldValue) => ({ ...oldValue, keywords }));
  }, [keywords]);

  const onCancel = useCallback(() => changeStep(0), []);
  const onSelectTemplate = useCallback(() => changeStep(2), []);

  const canSelectTemplate = useMemo(
    () => phase && coreList.length && cores.length && ideas.length && keywords.length,
    [phase, coreList, cores, ideas, keywords]
  );

  return (
    <View style={styles.main}>
      <Text style={styles.title}>建立教案</Text>
      <View style={styles.body}>
        <View style={{ zIndex: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          <View style={{ zIndex: 2, marginRight: 60, marginBottom: 20, flexGrow: 0, flexShrink: 0 }}>
            <Text style={[styles.label, { marginBottom: 10 }]}>科目</Text>
            <DropDown
              style={{ container: { width: 230 } }}
              closeAfterSelecting
              items={subjectOptions}
              defaultValue={subject}
              onChangeValue={setSubject}
            />
          </View>
          <View style={{ zIndex: 1, flexGrow: 0, flexShrink: 0 }}>
            <Text style={[styles.label, { marginBottom: 10 }]}>年級</Text>
            <DropDown
              style={{ container: { width: 230 } }}
              closeAfterSelecting
              items={phaseOptions}
              defaultValue={phase}
              onChangeValue={setPhase}
            />
          </View>
        </View>

        <View style={{ paddingVertical: 20 }}>
          <View style={[{ marginBottom: 20 }, styles.flexRowCenter]}>
            <Text style={styles.label}>總綱核心素養</Text>
            <View style={{ marginLeft: 10 }}>
              <Info text="(建議：A, B, C 類各選至少一個，或適度導入19 項議題之一)" />
            </View>
          </View>
          <CoreListPicker selected={coreList} setSelected={setCoreList} />
        </View>

        <View style={{ paddingVertical: 20 }}>
          <View style={[{ marginBottom: 20 }, styles.flexRowCenter]}>
            <Text style={styles.label}>本教育階段核心素養</Text>
            <View style={{ marginLeft: 10 }}>
              <TouchableOpacity
                onPress={() =>
                  window.open(
                    'https://docs.google.com/document/d/1iC5GRRi1c5gmK1bSqdUZ13YOo_cJZftjp-B02U9bMzc/edit?usp=sharing',
                    '_blank'
                  )
                }>
                <Info text="點選圖示開啟說明" />
              </TouchableOpacity>
            </View>
          </View>
          <PhaseCoreSelector phase={phase} coreList={coreList} selected={cores} setSelected={setCores} />
        </View>

        <View style={{ width: '100%', height: 1, backgroundColor: colorStyleProps.grayE0.color }} />

        <View style={[{ paddingTop: 20, paddingBottom: 8 }, styles.flexRowCenter]}>
          <View style={[{ flexBasis: 150, marginTop: 6, marginBottom: 6 }, styles.flexRowCenter]}>
            <Text style={styles.label}>教案設計理念</Text>
            <View style={{ marginLeft: 10 }}>
              <TouchableOpacity
                onPress={() =>
                  window.open(
                    'https://docs.google.com/document/d/1sHU8wyRz7iyfEwSKrW68BhRTERTgwQMFUzK7wvnpXmE/edit?usp=sharing',
                    '_blank'
                  )
                }>
                <Info text="點選圖示開啟說明" />
              </TouchableOpacity>
            </View>
          </View>
          <View style={{ flexGrow: 1, flexShrink: 1 }}>
            <IdeaPicker selected={ideas} setSelected={setIdeas} />
          </View>
        </View>

        <View style={{ width: '100%', height: 1, backgroundColor: colorStyleProps.grayE0.color }} />

        <View style={[{ paddingTop: 20, paddingBottom: 8 }, styles.flexRowCenter]}>
          <View style={[{ flexBasis: 150, marginTop: 6, marginBottom: 6 }, styles.flexRowCenter]}>
            <Text style={styles.label}>課程目標</Text>
            <View style={{ marginLeft: 10 }}>
              <TouchableOpacity
                onPress={() =>
                  window.open(
                    'https://docs.google.com/document/d/1V33ho65_sc1CXO9dEGyF5fWL4p4iwOxJE940-7yGLGA/edit?usp=sharing',
                    '_blank'
                  )
                }>
                <Info text="點選圖示開啟說明" />
              </TouchableOpacity>
            </View>
          </View>
          <View style={{ flexGrow: 1, flexShrink: 1 }}>
            <KeywordPicker selected={keywords} setSelected={setKeywords} />
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <Button type="gray" title="取消" style={btnStyle} onPress={onCancel} />
        <Button title="第二步：選擇模板" style={btnStyle} onPress={onSelectTemplate} disabled={!canSelectTemplate} />
      </View>
    </View>
  );
};

export default Step1;
