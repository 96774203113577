import { StyleSheet } from 'react-native';

import { colorStyleProps } from '../../styles';

export default StyleSheet.create({
  bar: {
    width: '100%',
    height: 80,
    backgroundColor: colorStyleProps.tpWhite.color,
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '4.86vw',
    paddingRight: '4.86vw',
  },
  logo: {
    height: '100%',
    flexGrow: 0,
    flexShrink: 0,
    marginRight: '2.43vw',
  },
  navTypes: {
    width: '100%',
    flexGrow: 1,
    flexShrink: 1,

    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  actions: {
    width: '100%',
    flexGrow: 0,
    flexShrink: 1,
    marginRight: 20,

    display: 'flex',
    flexDirection: 'row-reverse',
    overflow: 'hidden',
  },
  searchBar: {
    flexGrow: 0,
    flexShrink: 1,
    alignSelf: 'center',
    paddingRight: 20,
  },
  notification: {
    flexGrow: 0,
    flexShrink: 0,
    alignSelf: 'center',
    paddingRight: 20,
  },
  createBtn: {
    flexGrow: 0,
    flexShrink: 1,
    alignSelf: 'center',
  },
  user: {
    width: 'fit-content',
    maxWidth: 180,
    height: '100%',
    flexGrow: 0,
    flexShrink: 0,
    overflow: 'hidden',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
});
