import { createPath } from 'history';
import { useMemo } from 'react';
import { Platform } from 'react-native';
import { useHistory as useWebHistory } from 'react-router-dom';
import { useHistory as useNativeHistory } from 'react-router-native';

const useHistory = Platform.OS === 'web' ? useWebHistory : useNativeHistory;

const stringify = (obj) => {
  const str = Object.keys(obj)
    .filter((k) => obj[k])
    .map((k) => [k, encodeURIComponent(obj[k])].join('='))
    .join('&');
  return `?${str}`;
};

const parse = (str) =>
  !str.length
    ? {}
    : str
        .substring(1)
        .split('&')
        .map((pair) => pair.split('=').map(decodeURIComponent))
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

export const useHistoryWithSpec = () => {
  const history = useHistory();

  const newHistory = useMemo(
    () => ({
      ...history,
      with: (spec, keepOtherQueryParams = true) => {
        const { pathname, search, hash } = history.location;

        const currentQuery = parse(search);
        const nextQuery = { ...currentQuery, ...spec.query };

        const nextPath = createPath({
          pathname: spec.pathname !== undefined ? spec.pathname : pathname,
          search: spec.query ? stringify(nextQuery) : search,
          hash: spec.hash !== undefined ? spec.hash : hash,
        });

        return {
          ...history,
          push: (state) => history.push(nextPath, state),
          replace: (path, state) => history.replace(nextPath, state),
        };
      },
      location: {
        ...history.location,
        query: parse(history.location.search),
      },
    }),
    [history.location.search]
  );

  return newHistory;
};
