import React from 'react';

import DropDown from '../../../../components/DropDown';
import { subject } from '../../../../options';

const items = Object.keys(subject).map((value) => ({ value, label: subject[value] }));

const SubjectPicker = ({ field }) => (
  <DropDown items={items} defaultValue={field.value} onChangeValue={field.onChange} />
);

export default SubjectPicker;
