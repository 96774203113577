import { StyleSheet } from 'react-native';

import { colorStyleProps } from '../../styles';

export default StyleSheet.create({
  main: {
    backgroundColor: colorStyleProps.tpOG.color,
    paddingLeft: 10,
    paddingRight: 10,
    width: '100%',
    height: '100%',
  },
});
