import React, { useCallback, useState, useEffect } from 'react';
import { useController, Controller } from 'react-hook-form';
import { StyleSheet, View, Text, ImageBackground, TouchableOpacity } from 'react-native';

import CustomInput from '../../../components/CustomInput';
import DropDown from '../../../components/DropDown';
import Fields from '../../../components/Form/Fields';
import ImageUploader from '../../../components/ImageUploader';
import Info from '../../../components/Info';
import Textarea from '../../../components/Textarea';
import { gradeOptions } from '../../../options';
import { fontStyleProps, colorStyleProps } from '../../../styles';
import AttachmentLink from '../../Plan/AttachmentLink';
import Capsule from '../../Plan/Capsule';
import { renderOption } from '../../Plan/PhaseCoreOption';
import PictureLink from '../../Plan/PictureLink';
import IdeaPicker from '../Step1/IdeaPicker';
import AttachmentPicker from './AttachmentPicker';
import PicturePicker from './PicturePicker';
import PlannerFormField from './PlannerFormField';
import rules from './rules';
import UploadSvg from './upload.svg';

export const styles = StyleSheet.create({
  main: {
    paddingVertical: 60,
    paddingHorizontal: 80,

    // make dropdown overlap on other UIs
    zIndex: 1,
  },
  coverImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
  field: {
    marginTop: 45,
  },
  label: {
    ...fontStyleProps.bold20,
    color: colorStyleProps.pureBlack.color,
    marginBottom: 5,
  },
});

export const useCoverController = (control, defaultValue) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'cover',
    control,
    rules: rules?.cover,
    shouldUnregister: true,
    defaultValue,
  });

  const onCoverUpload = useCallback((cover) => field.onChange(cover), [field]);
  return { coverField: field, coverError: error, onCoverUpload };
};

const useIdeasController = (control, defaultValue) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'ideas',
    control,
    rules: rules?.ideas,
    shouldUnregister: true,
    defaultValue,
  });

  const onIdeasSelect = useCallback(
    (ideasValueOrUpdater) => {
      if (typeof ideasValueOrUpdater === 'function') {
        field.onChange(ideasValueOrUpdater(field.value));
      } else {
        field.onChange(ideasValueOrUpdater);
      }
    },
    [field]
  );
  return { ideasField: field, ideasError: error, onIdeasSelect };
};

const PlanForm = ({ methods, plan, setPlan }) => {
  const { coverField, coverError, onCoverUpload } = useCoverController(methods.control, plan.cover);
  const { ideasField, ideasError, onIdeasSelect } = useIdeasController(methods.control, plan.ideas);

  const [goalContainsKeywords, setGoalContainsKeywords] = useState({});
  const onGoalChange = useCallback(
    (goal) => {
      const newContains = {};
      let text = goal;
      plan.keywords.forEach((keyword) => {
        if (text.indexOf(keyword) > -1) {
          text = text.replaceAll(keyword, ' ');
          newContains[keyword] = true;
        }
      });
      setGoalContainsKeywords(newContains);
    },
    [plan.keywords]
  );

  useEffect(() => {
    onGoalChange(plan.goal);
  }, [plan.goal]);

  const onCoresInit = useCallback(() => {
    methods.trigger();
  }, []);

  return (
    <View style={styles.main}>
      <View
        style={{
          width: `${(100 * 594) / 1440}vw`,
          height: `${(100 * 326) / 1440}vw`,
        }}>
        <ImageUploader
          objectType="plan_cover"
          onUpload={onCoverUpload}
          aspect={[1.822085889570552, 1]}
          resize={{ width: 594, height: 326 }}
          withTimestamp>
          <View
            style={{
              width: `${(100 * 594) / 1440}vw`,
              height: `${(100 * 326) / 1440}vw`,
              borderWidth: 1,
              borderStyle: 'dashed',
              borderColor: coverError ? colorStyleProps.error.color : colorStyleProps.grayBD.color,
              borderRadius: 10,
              backgroundColor: colorStyleProps.grayFD.color,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}>
            <View style={styles.coverImage}>
              <ImageBackground source={{ uri: coverField.value }} style={{ flex: 1, resizeMode: 'cover' }} />
            </View>
            <UploadSvg />
            <Text style={{ marginTop: 10, ...fontStyleProps.bold20, color: colorStyleProps.grayBD.color }}>
              新增教案圖片
            </Text>
            <Text style={{ ...fontStyleProps.regular20, color: colorStyleProps.grayBD.color }}>建議尺寸 594 x 326</Text>
            {coverError && <Text style={{ color: colorStyleProps.error.color }}>{coverError.message}</Text>}
          </View>
        </ImageUploader>
      </View>
      <PlannerFormField
        label="教案標題"
        name="title"
        defaultValue={plan.title}
        control={methods.control}
        rules={rules.title}
        style={styles.field}>
        {({ field, error }) => <CustomInput field={field} error={error} />}
      </PlannerFormField>
      <PlannerFormField
        label="課程名稱"
        name="name"
        defaultValue={plan.name}
        control={methods.control}
        rules={rules.planName}
        style={styles.field}>
        {({ field, error }) => <CustomInput field={field} error={error} />}
      </PlannerFormField>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <PlannerFormField
          label="日期"
          name="date"
          defaultValue={plan.date}
          control={methods.control}
          rules={rules.date}
          style={[styles.field, { flex: 1, marginRight: 15 }]}>
          {({ field, error }) => <CustomInput field={field} error={error} placeholder="YYYY/MM/DD" />}
        </PlannerFormField>
        <PlannerFormField
          label="學期"
          name="semester"
          defaultValue={plan.semester}
          control={methods.control}
          rules={rules.semester}
          style={[styles.field, { flex: 1, marginLeft: 15 }]}>
          {({ field, error }) => <CustomInput field={field} error={error} />}
        </PlannerFormField>
      </View>
      {plan.appliedAuthorUid ? (
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <PlannerFormField
            label="設計者"
            name="designer"
            defaultValue={plan.designer}
            control={methods.control}
            rules={rules.designer}
            style={[styles.field, { flex: 1, marginRight: 15 }]}>
            {({ field, error }) => <Textarea field={field} error={error} minRows={1} maxRows={3} />}
          </PlannerFormField>
          <PlannerFormField
            label="原作者"
            name="appliedAuthorName"
            defaultValue={plan.appliedAuthorName}
            control={methods.control}
            style={[styles.field, { flex: 1, marginLeft: 15 }]}>
            {({ field, error }) => <CustomInput field={field} error={error} disabled />}
          </PlannerFormField>
        </View>
      ) : (
        <PlannerFormField
          label="設計者"
          name="designer"
          defaultValue={plan.designer}
          control={methods.control}
          rules={rules.designer}
          style={styles.field}>
          {({ field, error }) => <Textarea field={field} error={error} minRows={1} maxRows={3} />}
        </PlannerFormField>
      )}
      <View style={{ zIndex: 1, display: 'flex', flexDirection: 'row' }}>
        <PlannerFormField
          label="年級"
          name="grade"
          defaultValue={plan.grade}
          control={methods.control}
          rules={rules.grade}
          style={[styles.field, { flex: 1, marginRight: 15 }]}>
          {({ field, error }) => (
            <DropDown
              closeAfterSelecting
              items={gradeOptions}
              defaultValue={field.value}
              onChangeValue={field.onChange}
            />
          )}
        </PlannerFormField>
        <PlannerFormField
          label="節數"
          name="totalLectures"
          defaultValue={plan.totalLectures}
          control={methods.control}
          rules={rules.totalLectures}
          style={[styles.field, { flex: 1, marginLeft: 15 }]}>
          {({ field, error }) => <CustomInput field={field} error={error} />}
        </PlannerFormField>
      </View>
      <PlannerFormField
        label={
          <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ flexShrink: 0, marginRight: 15 }}>設計理念</Text>
            <View style={{ marginRight: 10 }}>
              <TouchableOpacity
                onPress={() =>
                  window.open(
                    'https://docs.google.com/document/d/1sHU8wyRz7iyfEwSKrW68BhRTERTgwQMFUzK7wvnpXmE/edit?usp=sharing',
                    '_blank'
                  )
                }>
                <Info text="點選圖示開啟說明" />
              </TouchableOpacity>
            </View>
            {ideasError && (
              <Text
                style={{
                  color: colorStyleProps.error.color,
                  whiteSpace: 'nowrap',
                  marginRight: 10,
                  ...fontStyleProps.regular14,
                }}>
                {ideasError.message}
              </Text>
            )}
            <View style={{ width: '100%' }}>
              <IdeaPicker selected={ideasField.value} setSelected={onIdeasSelect} />
            </View>
          </View>
        }
        name="idea"
        defaultValue={plan.idea}
        control={methods.control}
        rules={rules.idea}
        style={styles.field}>
        {({ field, error }) => <Textarea field={field} error={error} minRows={10} maxRows={25} />}
      </PlannerFormField>
      <View style={styles.field}>
        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Text style={styles.label}>本教育階段核心素養</Text>
          <View style={{ marginLeft: 10, marginBottom: 5 }}>
            <TouchableOpacity
              onPress={() =>
                window.open(
                  'https://docs.google.com/document/d/1iC5GRRi1c5gmK1bSqdUZ13YOo_cJZftjp-B02U9bMzc/edit?usp=sharing',
                  '_blank'
                )
              }>
              <Info text="點選圖示開啟說明" />
            </TouchableOpacity>
          </View>
        </View>
        <Fields
          name="cores"
          control={methods.control}
          rules={rules.cores}
          defaultValues={plan.cores}
          onInit={onCoresInit}>
          {({ fields, append, remove }) =>
            fields.map(({ id, ...core }, x) => (
              <View key={id} style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                <Controller
                  name={`cores.${x}.coreName`}
                  control={methods.control}
                  rules={rules.core.coreName}
                  shouldUnregister
                  defaultValue={core.coreName}
                  render={({ field }) => (
                    <View style={{ marginRight: 12 }}>{renderOption({ option: field.value })}</View>
                  )}
                />
                <Controller
                  name={`cores.${x}.body`}
                  control={methods.control}
                  rules={rules.core.body}
                  shouldUnregister
                  defaultValue={core.body}
                  render={({ field, fieldState: { error }, formState }) => (
                    <Textarea style={{ flexGrow: 1 }} field={field} error={error} minRows={1} maxRows={5} />
                  )}
                />
              </View>
            ))
          }
        </Fields>
      </View>
      <PlannerFormField
        label={
          <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ flexShrink: 0, marginRight: 15 }}>課程目標</Text>
            <View style={{ marginRight: 10 }}>
              <TouchableOpacity
                onPress={() =>
                  window.open(
                    'https://docs.google.com/document/d/1V33ho65_sc1CXO9dEGyF5fWL4p4iwOxJE940-7yGLGA/edit?usp=sharing',
                    '_blank'
                  )
                }>
                <Info text="點選圖示開啟說明" />
              </TouchableOpacity>
            </View>
            <View style={{ width: '100%', flexDirection: 'row', flexWrap: 'wrap', flexShrink: 1 }}>
              {plan.keywords.map((k) => (
                <Capsule
                  key={k}
                  style={{
                    borderColor: colorStyleProps.keyBlack.color,
                    backgroundColor: colorStyleProps.keyBlack.color,
                    marginRight: 12,
                    marginTop: 6,
                    marginBottom: 6,
                    opacity: goalContainsKeywords[k] ? 1 : 0.2,
                  }}>
                  <Text
                    style={{
                      ...fontStyleProps.regular14,
                      color: colorStyleProps.tpWhite.color,
                    }}>
                    {k}
                  </Text>
                </Capsule>
              ))}
            </View>
          </View>
        }
        name="goal"
        defaultValue={plan.goal}
        control={methods.control}
        rules={rules.goal}
        style={styles.field}>
        {({ field, error }) => (
          <Textarea field={field} error={error} minRows={10} maxRows={25} onChangeText={onGoalChange} />
        )}
      </PlannerFormField>
      <PlannerFormField
        label="總結性表現任務"
        name="summary"
        defaultValue={plan.summary}
        control={methods.control}
        rules={rules.summary}
        style={styles.field}>
        {({ field, error }) => <Textarea field={field} error={error} minRows={10} maxRows={25} />}
      </PlannerFormField>
      <View style={styles.field}>
        <Text style={styles.label}>架構脈絡</Text>
        <Fields name="pictures" control={methods.control} defaultValues={plan.pictures}>
          {({ fields, append, remove }) => (
            <>
              {fields.map(({ id, ...picture }, x) => (
                <View key={id} style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                  <Controller
                    name={`pictures.${x}.link`}
                    control={methods.control}
                    shouldUnregister
                    defaultValue={picture.link}
                    render={({ field }) => (
                      <>
                        <PictureLink
                          style={{ width: '100%' }}
                          field={field}
                          editing={picture.editing}
                          remove={remove}
                          index={x}
                        />
                        <View style={{ height: 20 }} />
                      </>
                    )}
                  />
                </View>
              ))}
              <PicturePicker append={append} />
            </>
          )}
        </Fields>
      </View>
      <View style={styles.field}>
        <Text style={styles.label}>附件</Text>
        <Fields name="attachments" control={methods.control} defaultValues={plan.attachments}>
          {({ fields, append, remove }) => (
            <>
              {fields.map(({ id, ...attachment }, x) => (
                <View key={id} style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                  <Controller
                    name={`attachments.${x}.link`}
                    control={methods.control}
                    shouldUnregister
                    defaultValue={attachment.link}
                    render={({ field }) => (
                      <AttachmentLink editing={attachment.editing} field={field} remove={remove} index={x} />
                    )}
                  />
                </View>
              ))}
              <AttachmentPicker append={append} />
            </>
          )}
        </Fields>
      </View>
    </View>
  );
};

export default PlanForm;
