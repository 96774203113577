import React, { memo, useCallback, useContext, useMemo } from 'react';
import { View, StyleSheet, Text } from 'react-native';

import apis from '../../apis';
import Button from '../../components/Button';
import CardsView from '../../components/CardsView';
import DataList from '../../components/DataList';
import LitePlanCard from '../../components/PlanCard/LitePlanCard';
import { OverlayContext } from '../../containers/OverlayManager';
import { UserContext } from '../../containers/UserProvider/UserProvider';
import { colorStyleProps } from '../../styles';
import AvatarName from './AvatarName';
import FollowButton from './FollowButton';

const PAGE_SIZE = 24;

const styles = StyleSheet.create({
  header: {
    width: '100%',
    marginTop: 90,
    backgroundColor: colorStyleProps.tpOG.color,
  },
  avatarNameContainer: {
    marginTop: -52,
  },
  emptyList: {
    backgroundColor: colorStyleProps.tpOG.color,
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  emptyView: {
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    paddingBottom: 10,
  },
  followBtn: {
    backgroundColor: colorStyleProps.tpOG.color,
    width: '100%',
    paddingHorizontal: 30,

    paddingBottom: 20,
    textAlign: 'center',
  },
});

const createBtnStyle = {
  container: {
    marginTop: 20,
  },
  button: {
    width: 250,
  },
};

const EmptyList = () => {
  const { pushOverlay } = useContext(OverlayContext);

  const showWarning = useCallback(() => {
    pushOverlay(({ Message }) => <Message message="請至電腦版建立教案!" />);
  }, []);

  return (
    <View style={styles.emptyList}>
      <View style={styles.emptyView}>
        <Text>尚未發佈任何作品</Text>
        <Button title="馬上建立屬於自己的教案!" style={createBtnStyle} onPress={showWarning} />
      </View>
    </View>
  );
};

const renderCard = (card, fixedWidth) => {
  const style = fixedWidth ? { width: 356 } : undefined;
  return <LitePlanCard key={card.id} plan={card} style={style} />;
};

const renderItem = ({ item }) => {
  return (
    <View style={[{ backgroundColor: colorStyleProps.tpOG.color }, { paddingHorizontal: 9 }]}>
      <CardsView cards={item.data} renderCard={renderCard} numColumns={2} />
    </View>
  );
};

const UserContent = memo(({ userProfile, onScroll, onFollowChange, onAvatarUpload }) => {
  const { userProfile: loginUserProfile } = useContext(UserContext);
  const isEditable = loginUserProfile.uid === userProfile.uid;
  const isFollowing = userProfile.following;

  const followUserButton = useMemo(
    () => <FollowButton isFollowing={isFollowing} uid={userProfile.uid} onFollowChange={onFollowChange} />,
    [userProfile, isFollowing, onFollowChange]
  );

  const Header = useMemo(
    () => () =>
      (
        <>
          <View style={styles.header}>
            <View style={styles.avatarNameContainer}>
              <AvatarName isEditable={isEditable} userProfile={userProfile} onUpload={onAvatarUpload} />
            </View>
          </View>
          {userProfile.blocked ? (
            <Text style={styles.followBtn}>您已將對方加入黑名單，無法查看他發佈的內容</Text>
          ) : isEditable ? null : (
            <View style={styles.followBtn}>{followUserButton}</View>
          )}
        </>
      ),
    [isEditable, userProfile, followUserButton, onAvatarUpload]
  );

  const onFetch = useCallback(
    async (offset) => {
      if (userProfile.blocked) {
        return null;
      }
      try {
        const { data } = await apis.plans.getPlans({
          authorUid: userProfile.uid,
          offset,
          limit: PAGE_SIZE,
        });
        const newItems = [];
        if (data.plans.length) {
          newItems.push({
            key: `${offset}-${data.plans[0].slug}`,
            data: data.plans,
          });
        }

        return {
          total: data.total,
          next: offset + data.plans.length,
          items: newItems,
        };
      } catch (error) {
        // TODO handle error
        console.error(error);
      }
    },
    [userProfile.uid, userProfile.blocked]
  );

  return (
    <DataList
      ListHeaderComponent={Header}
      ListEmptyComponet={isEditable ? EmptyList : undefined}
      renderItem={renderItem}
      onFetch={onFetch}
      onScroll={onScroll}
    />
  );
});

export default UserContent;
