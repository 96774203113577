import { StyleSheet } from 'react-native';

import { fontStyleProps, colorStyleProps } from '../../styles';

export default StyleSheet.create({
  card: {
    width: '100%',
    backgroundColor: colorStyleProps.tpWhite.color,
    overflow: 'hidden',
    borderRadius: 10,
    shadowColor: colorStyleProps.grayF2.color,
    shadowRadius: 4,
    shadowOffset: {
      height: 1,
      width: 0,
    },
    margin: 2,
    position: 'relative',
  },
  background: {
    paddingTop: '81.4%',
  },
  foreground: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  cover: {
    width: '100%',
    height: '78.6%',
    backgroundColor: colorStyleProps.pureBlack.color,
    marginBottom: '6%',
  },
  coverImage: {
    width: '100%',
    height: '100%',
  },
  matchRate: {
    position: 'absolute',
    left: 18,
    top: 14,
    borderWidth: 1,
    borderRadius: 60,
    borderColor: colorStyleProps.tpWhite.color,
    paddingHorizontal: 10,
    paddingVertical: 6,
    overflow: 'hidden',
  },
  matchRateGradient: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  matchRateText: {
    ...fontStyleProps.bold12,
  },
  tagList: {
    position: 'absolute',
    bottom: '-6%',
    left: '0.6%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  tag: {
    height: 24,
    marginLeft: '2.8%',
    marginTop: 5,
    paddingLeft: '2.8%',
    paddingRight: '2.8%',
    borderRadius: 6,
  },
  tagText: {
    ...fontStyleProps.bold14,
  },

  title: {
    width: '100%',
    height: '15.4%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleText: {
    ...fontStyleProps.bold16,
    ...colorStyleProps.keyBlack,
    marginLeft: 12,
  },
  bottom: {
    height: '13.1%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderTopWidth: 1,
    borderTopColor: colorStyleProps.grayF2.color,
  },

  authorContainer: {
    marginLeft: 12,
  },
  author: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    flexShrink: 1,
  },
  authorName: {
    ...fontStyleProps.bold14,
    ...colorStyleProps.keyBlack,
    marginLeft: 7,
    flexGrow: 0,
    flexShrink: 1,
  },

  favoritesContainer: {
    backgroundColor: colorStyleProps.tpWhite.color,
    paddingLeft: 6,
    paddingRight: 12,
    position: 'absolute',
    right: 0,

    display: 'flex',
    flexDirection: 'row',
  },
  favorites: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 0,
    flexShrink: 0,
  },
  favoritesCount: {
    marginLeft: 3,
    ...fontStyleProps.bold14,
  },
  comments: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 0,
    flexShrink: 0,
  },
  commentsCount: {
    marginLeft: 3,
    ...fontStyleProps.bold14,
  },

  liteBackground: {
    paddingTop: '91.4%',
  },
  liteCover: {
    width: '100%',
    height: '71.8%',
    backgroundColor: colorStyleProps.keyBlack.color,
    marginBottom: '6%',
  },
  gradientContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  gradient: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 80,
    height: 80,
  },
  liteTagList: {
    position: 'absolute',
    bottom: '-6%',
    left: '0.6%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  liteTag: {
    height: 24,
    marginLeft: 0,
    paddingLeft: '2.8%',
    paddingRight: '2.8%',
  },
  liteTagText: {
    ...fontStyleProps.regular12,
  },
  liteFavorites: {
    display: 'flex',
    flexDirection: 'row',

    marginRight: 5,
    paddingTop: 10,
    paddingRight: 5,
  },
  liteTitle: {
    width: '100%',
    height: '29.2%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colorStyleProps.tpWhite.color,
  },
  liteFavoritesCount: {
    marginLeft: 3,
    ...fontStyleProps.bold14,
    color: colorStyleProps.tpWhite.color,
  },
});
