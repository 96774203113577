import React, { useState, useCallback, useEffect } from 'react';
import { View } from 'react-native';

const PressAwait = ({ children, onPress, waitFor }) => {
  const [isAwaiting, setAwaiting] = useState(false);

  const onPressAwait = useCallback(
    async (e) => {
      try {
        setAwaiting(true);
        await onPress(e);
      } finally {
        if (!waitFor) {
          setAwaiting(false);
        }
      }
    },
    [onPress, waitFor]
  );

  useEffect(() => {
    setAwaiting(false);
  }, waitFor || []);

  return (
    <View style={{ position: 'relative', cursor: isAwaiting ? 'wait' : 'pointer', opacity: isAwaiting ? 0.5 : 1 }}>
      {children({ isAwaiting, onPressAwait })}
    </View>
  );
};

export default PressAwait;
