import * as Analytics from 'expo-firebase-analytics';
import * as GoogleSignIn from 'expo-google-sign-in';
import React, { useContext, useEffect, useCallback } from 'react';

import { OverlayContext } from '../../../containers/OverlayManager';
import { UserContext } from '../../../containers/UserProvider';

const WithGoogleSignIn = ({ children }) => {
  const { authInfo, setAuthInfo } = useContext(UserContext);
  const { pushOverlay } = useContext(OverlayContext);

  useEffect(() => {
    (async () => {
      try {
        await GoogleSignIn.initAsync({
          // You may ommit the clientId when the firebase `googleServicesFile` is configured
          // webClientId: '693851573427-icb270ru82hejtrn7eeurc67s0r02ud1.apps.googleusercontent.com',
        });
      } catch ({ message }) {
        alert('initAsync: Error:' + message);
      }
    })();
  }, []);

  const login = useCallback(async () => {
    // if alread has authInfo, just clone a new one to trigger
    // UserProvider to login again
    if (authInfo) {
      await Analytics.logEvent('login', {
        provider: 'google',
        desc: 'auth exists',
      });
      setAuthInfo({ ...authInfo });
    } else {
      try {
        await GoogleSignIn.askForPlayServicesAsync();
        const response = await GoogleSignIn.signInAsync();
        const { type, user } = response;
        if (type === 'success') {
          await Analytics.logEvent('login', {
            provider: 'google',
            action: 'login',
          });
          setAuthInfo({
            provider: 'google',
            token: user.auth.accessToken,
            revoke: () => GoogleSignIn.signOutAsync(),
          });
        } else {
          pushOverlay(({ Message }) => <Message message="無法登入，請確認輸入的帳號密碼是否正確" />);
          console.error(response);
        }
      } catch ({ message }) {
        pushOverlay(({ Message }) => <Message message="無法登入，請確認輸入的帳號密碼是否正確" />);
        console.error(message);
      }
    }
  }, [authInfo]);

  return children({ login });
};

export default WithGoogleSignIn;
