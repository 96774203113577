import React, { useCallback, useMemo, useState } from 'react';
import { View, StyleSheet, Text } from 'react-native';

import apis from '../../apis/apis';
import { useMobileView } from '../../pages/Root/useMobileView';
import { colorStyleProps, fontStyleProps } from '../../styles';
import DataList from '../DataList/DataList';
import Tabs from '../Tabs/Tabs';
import NotificationItem from './NotificationItem';

const NOTIFICATION_SIZE = 10;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    width: 250,
    borderRadius: 16,
    flexGrow: 1,
  },
  listContainer: {
    height: 200,
    width: '100%',
    paddingBottom: 17,
  },
  webTabStyle: {
    paddingHorizontal: 23,
    paddingVertical: 17,

    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  mobileTabStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: 26,
    marginBottom: 14,
  },
  mobileDataList: {
    backgroundColor: colorStyleProps.tpWhite.color,
    borderRadius: 10,
    height: 744,
    width: 356,
    paddingTop: 24,

    display: 'flex',
    flex: 1,
  },
  mobileListContainer: {
    height: 700,
    width: '100%',
  },
  list: {
    width: '100%',
    height: 312,
  },
  emptyView: {
    width: '100%',
    height: 312,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyListTitle: {
    ...colorStyleProps.gray82,
    ...fontStyleProps.bold14,
  },
  emptyListString: {
    ...colorStyleProps.gray82,
    ...fontStyleProps.regular12,
    paddingHorizontal: 45,
  },
});

const notificationCategory = [
  {
    // 1 for general
    value: '1',
    label: '提醒',
  },
  {
    // 0 for system
    value: '0',
    label: '通知',
  },
];
const tabsStyle = {
  title: {
    default: {
      color: colorStyleProps.pureBlack.color,
      fontSize: 14,
    },
    active: {
      color: colorStyleProps.tpWhite.color,
      fontSize: 14,
    },
  },
  button: {
    default: {
      paddingVertical: 9,
      paddingHorizontal: 35,
    },
    active: {
      backgroundColor: colorStyleProps.pureBlack.color,
      paddingVertical: 9,
      paddingHorizontal: 35,
    },
  },
};
const getNotificationsList = (type, offset) => {
  return apis.notifications.getNotifications({
    type,
    offset,
    limit: NOTIFICATION_SIZE,
  });
};
const updateNotification = (type) => {
  try {
    return apis.notifications.putNotificatonToRead(type);
  } catch (error) {
    console.log(error);
  }
};
const NotificationList = ({ notificationBadge }) => {
  const [currentTab, setTabCategory] = useState(notificationCategory[0].value);
  const [isMobileView] = useMobileView();
  const onCatChange = useCallback(
    async (tab) => {
      setTabCategory(tab);
      await updateNotification(tab);
      notificationBadge();
    },
    [setTabCategory, updateNotification]
  );

  const NotificationSection = ({ notifications }) => {
    return (
      <View style={isMobileView ? styles.mobileListContainer : styles.listContainer}>
        {notifications.map((nt, idx) => (
          <View key={idx}>
            <View>
              <NotificationItem notification={nt} createdTime={nt.createdAt} />
            </View>
          </View>
        ))}
      </View>
    );
  };

  const EmptyList = useMemo(
    () => () =>
      (
        <View style={styles.emptyView}>
          {currentTab === notificationCategory[0].value ? (
            <Text style={styles.emptyListTitle}>沒有任何提醒</Text>
          ) : (
            <Text style={styles.emptyListTitle}>沒有任何通知</Text>
          )}
          <Text style={styles.emptyListString}>在 TeachPlanner 上與老師</Text>
          <Text style={styles.emptyListString}>保持聯繫</Text>
        </View>
      ),
    [currentTab]
  );

  const onFetch = useCallback(
    async (offset) => {
      try {
        const { data } = await getNotificationsList(currentTab, offset);
        if (!data) {
          return null;
        }

        const newItems = [];
        const notifications = data.notifications;

        if (notifications.length) {
          newItems.push({
            key: notifications.id,
            data: notifications,
          });
        }

        return {
          total: notifications.length,
          next: offset + notifications.length,
          items: newItems,
        };
      } catch (error) {
        console.log(error);
      }
    },
    [currentTab]
  );

  const renderItem = useCallback(({ item }) => {
    return <NotificationSection notifications={item.data} />;
  }, []);

  return (
    <View style={isMobileView ? null : styles.container}>
      <View style={isMobileView ? styles.mobileTabStyle : styles.webTabStyle}>
        <Tabs
          tabs={notificationCategory}
          defaultCurrentTab={currentTab}
          style={tabsStyle}
          onTabChange={onCatChange}
          activeButtonStyle={styles.activeTabStyle}
        />
      </View>
      <View style={isMobileView ? styles.mobileDataList : styles.list}>
        <DataList ListEmptyComponet={EmptyList} renderItem={renderItem} onFetch={onFetch} />
      </View>
    </View>
  );
};

export default NotificationList;
