import Modal from 'modal-react-native-web';
import React from 'react';
import { MenuProvider } from 'react-native-popup-menu';

import CommentList from './CommentList';

const PlanComments = ({ plan, closeModal, isModalOpen }) => {
  return (
    <Modal onRequestClose={closeModal} transparent visible={isModalOpen}>
      <MenuProvider>
        <CommentList slug={plan.slug} closeModal={closeModal} planAuthor={plan.authorUid} />
      </MenuProvider>
    </Modal>
  );
};

export default PlanComments;
