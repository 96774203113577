import { StatusBar } from 'expo-status-bar';
import Modal from 'modal-react-native-web';
import React, { useContext, useEffect, useCallback } from 'react';
import { View, Platform, SafeAreaView } from 'react-native';
import { MenuProvider } from 'react-native-popup-menu';
import { BackButton } from 'react-router-native';

import MobileNotification from '../../components/Notification/MobileNotification';
import { Router, Route, Switch } from '../../components/UniversalRouter';
import FloatingActions from '../../containers/FloatingActions';
import { LoadingContext } from '../../containers/LoadingProvider';
import { OverlayContext } from '../../containers/OverlayManager';
import PullDownToRefresh from '../../containers/PullDownToRefresh';
import Toast from '../../containers/Toast';
import { UserContext } from '../../containers/UserProvider';
import Home from '../Home';
import Landing from '../Landing';
import NavigationBar from '../NavigationBar';
import NotFound from '../NotFound';
import Plan from '../Plan';
import Search from '../Search';
import User from '../User';
import styles from './styles';
import { useMobileView } from './useMobileView';

const menuProviderCustomStyles = {};
if (Platform.OS === 'web') {
  window.document.title = 'Teach Planner';

  Modal.setAppElement(document.getElementById('root'));

  menuProviderCustomStyles.backdrop = { position: 'fixed' };
}

const Root = () => {
  const { loginStatus, userProfile } = useContext(UserContext);
  const { setIsLoading } = useContext(LoadingContext);
  const { pushOverlay } = useContext(OverlayContext);
  const [isMobileView] = useMobileView();

  useEffect(() => {
    setIsLoading(loginStatus === undefined);
  }, [loginStatus]);

  const getUserConfirmation = useCallback((message, callback) => {
    pushOverlay(({ Confirm }) => (
      <Confirm message={message} onOK={() => callback(true)} onCancel={() => callback(false)} />
    ));
  }, []);

  return (
    <Router getUserConfirmation={getUserConfirmation}>
      <MenuProvider customStyles={menuProviderCustomStyles}>
        <SafeAreaView style={styles.root}>
          <StatusBar style="auto" />
          <BackButton>
            {!loginStatus ? (
              <Landing />
            ) : (
              !!userProfile && (
                <FloatingActions>
                  <PullDownToRefresh>
                    <Toast>
                      <NavigationBar />
                      <View style={styles.body}>
                        <Switch>
                          <Route path="/user/:uid" component={User} />
                          <Route path="/notification" component={MobileNotification} />
                          {/* use 'require' to prevent from bundling into mobile app */}
                          {!isMobileView && <Route path="/planner/:slug?" component={require('../Planner').default} />}
                          <Route path="/plan/:slug?" component={Plan} />
                          <Route path="/search" component={Search} />
                          <Route exact path="/" component={Home} />
                          <Route path="*" component={NotFound} />
                        </Switch>
                      </View>
                    </Toast>
                  </PullDownToRefresh>
                </FloatingActions>
              )
            )}
          </BackButton>
        </SafeAreaView>
      </MenuProvider>
    </Router>
  );
};

export default Root;
