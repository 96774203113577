import React, { useMemo } from 'react';
import { View } from 'react-native';

import FallbackableImage from '../../components/Fallbackable/FallbackableImage';
import DefaultAvatar from '../../images/DefaultAvatar';
import { colorStyleProps } from '../../styles';

const Avatar = ({ uri, size, borderWidth, borderColor }) => {
  const defaultImage = useMemo(() => <DefaultAvatar width={size} height={size} />, [size]);
  return (
    <View
      style={{
        width: size,
        height: size,
        borderRadius: size / 2,
        overflow: 'hidden',
        borderWidth,
        borderColor,
        backgroundColor: colorStyleProps.tpWhite.color,
      }}>
      <FallbackableImage
        source={{ uri }}
        style={{ marginLeft: -borderWidth, marginTop: -borderWidth, width: size, height: size }}
        fallbackImage={defaultImage}
      />
    </View>
  );
};

Avatar.defaultProps = {
  size: 20,
  borderWidth: 0,
};

export default Avatar;
