import React, { createContext, useMemo, useState, useCallback, useRef } from 'react';
import { RefreshControl } from 'react-native';

export const PullDownToRefreshContext = createContext();

const PullDownToRefresh = ({ children }) => {
  const [refreshing, setRefreshing] = useState(false);

  const refreshTimer = useRef();
  const onRefresh = useCallback(() => {
    setRefreshing(true);

    refreshTimer.current = setTimeout(() => {
      setRefreshing(false);
    }, 60000);
  }, []);

  const refreshControl = useMemo(
    () => <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />,
    [refreshing, onRefresh]
  );

  const onRefreshEnd = useCallback(() => {
    clearTimeout(refreshTimer.current);
    setRefreshing(false);
  }, []);

  const value = useMemo(
    () => ({
      refreshing,
      refreshControl,
      onRefreshEnd,
    }),
    [refreshing, refreshControl, onRefreshEnd]
  );

  return <PullDownToRefreshContext.Provider value={value}>{children}</PullDownToRefreshContext.Provider>;
};

export default PullDownToRefresh;
