import Constants from 'expo-constants';
import React from 'react';
import { TouchableOpacity, Text, Platform } from 'react-native';

import GoogleLogo from '../../../images/GoogleLogo';
import WithAuthSessionSignIn from './WithAuthSessionSignIn';
import WithGoogleSignIn from './WithGoogleSignIn';
import styles from './styles';

const WithApproach =
  Platform.OS !== 'web' && Constants.appOwnership === 'standalone' ? WithGoogleSignIn : WithAuthSessionSignIn;

const GoogleSignInButton = () => {
  return (
    <WithApproach>
      {({ login }) => (
        <TouchableOpacity style={styles.button} onPress={login}>
          <GoogleLogo width={24} height={24} />
          <Text style={styles.text}>使用 Google 登入</Text>
        </TouchableOpacity>
      )}
    </WithApproach>
  );
};

export default GoogleSignInButton;
