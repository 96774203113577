import React from 'react';
import { Text } from 'react-native';

import * as Linking from './Linking';

const HyperLink = ({ style, href }) => (
  <Text style={[{ color: '#0645ad', marginRight: 10 }, style]} numberOfLines={1} onPress={() => Linking.openURL(href)}>
    {href}
  </Text>
);

export default HyperLink;
