import React, { useState, useCallback, useEffect } from 'react';

import loadPlan from '../Plan/loadPlan';
import Step0 from './Step0/Step0';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3';

const steps = [Step0, Step1, Step2, Step3];

const Planner = loadPlan(({ plan, setPlan, applying }) => {
  const [step, setStep] = useState(plan.slug || applying ? 3 : 0);
  const [phase, setPhase] = useState('E');

  const changeStep = useCallback((nextStep) => {
    setStep(nextStep);
  }, []);

  useEffect(() => {
    if (plan.slug || applying) {
      changeStep(3);
    } else {
      changeStep(0);
      setPlan({ ...plan, fromStep0: true });
    }
  }, [plan.slug, applying]);

  const Step = steps[step];
  return <Step changeStep={changeStep} plan={plan} setPlan={setPlan} phase={phase} setPhase={setPhase} />;
});

export default Planner;
