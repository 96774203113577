import * as ImageManipulator from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';
import React, { useCallback } from 'react';

import FileUploader from '../FileUploader';

const ImageUploader = ({ children, aspect, resize, withTimestamp, onUpload, ...props }) => {
  const onSelect = useCallback(async () => {
    try {
      /*
        https://docs.expo.io/versions/v41.0.0/sdk/imagepicker/#imagepickerlaunchimagelibraryasyncoptions
        Notes for Web: The system UI can only be shown after user activation (e.g. a Button press).
        Therefore, calling getDocumentAsync in componentDidMount, for example, will not work as intended.
        The cancel event will not be returned in the browser due to platform restrictions and inconsistencies across browsers.

        It means that launchImageLibraryAsync maybe not return a result in the browser when the user cancels selection.
      */
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect,
        quality: 1,
      });

      if (!result.cancelled) {
        let crop;
        if (aspect && Math.floor((100 * result.width) / result.height) !== Math.floor((100 * aspect[0]) / aspect[1])) {
          if (Math.abs(1 - result.width / aspect[0]) < Math.abs(result.height / aspect[1])) {
            const ratio = aspect[1] / aspect[0];
            crop = {
              originX: 0,
              originY: (result.height - result.width * ratio) / 2,
              width: result.width,
              height: result.width * ratio,
            };
          } else {
            const ratio = aspect[0] / aspect[1];
            crop = {
              originX: (result.width - result.height * ratio) / 2,
              originY: 0,
              width: result.height * ratio,
              height: result.height,
            };
          }
        }

        // passing an array of actions doesn't work
        // so manipluate one by one
        let uri = result.uri;
        let manipResult = { uri };
        if (crop) {
          manipResult = await ImageManipulator.manipulateAsync(uri, [{ crop }], {
            format: ImageManipulator.SaveFormat.PNG,
          });
          uri = manipResult.uri;
        }
        if (resize) {
          manipResult = await ImageManipulator.manipulateAsync(uri, [{ resize }], {
            format: ImageManipulator.SaveFormat.PNG,
          });
        }

        return manipResult;
      }
    } catch (error) {
      // TODO handle error
      console.log(error);
    }
    return null;
  }, []);

  const onImageUpload = useCallback(
    async (url) => {
      if (url.indexOf('http') === 0 && withTimestamp) {
        const ts = `t=${Date.now()}`;
        await onUpload(url.indexOf('?') > -1 ? `${url}&${ts}` : `${url}?${ts}`);
      } else {
        await onUpload(url);
      }
    },
    [onUpload]
  );

  return (
    <FileUploader onSelect={onSelect} onUpload={onImageUpload} {...props}>
      {children}
    </FileUploader>
  );
};

export default ImageUploader;
