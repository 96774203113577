import React, { useContext } from 'react';
import { View, StyleSheet, Text } from 'react-native';

import Avatar from '../../components/Avatar';
import PopupMenu from '../../components/PopupMenu';
import { Link } from '../../components/UniversalRouter';
import { UserContext } from '../../containers/UserProvider';
import { fontStyleProps, colorStyleProps } from '../../styles';

const styles = StyleSheet.create({
  menu: {
    width: '100%',
    height: '100%',
    flexGrow: 0,
    flexShrink: 1,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    flexGrow: 0,
    flexShrink: 0,
  },
  name: {
    width: '100%',
    marginLeft: 15,
    flexGrow: 1,
    flexShrink: 1,
  },
  nameText: {
    ...fontStyleProps.bold18,
    ...colorStyleProps.keyBlack,
    textAlign: 'left',
  },
});

const UserMenu = ({ style }) => {
  const { userProfile, logout } = useContext(UserContext);
  return (
    <View style={[styles.menu, style]}>
      <View style={styles.icon}>
        <Link to={`/user/${userProfile?.uid}`}>
          <Avatar size={40} uri={userProfile?.image} />
        </Link>
      </View>
      <View style={styles.name}>
        <PopupMenu
          options={[
            {
              onSelect: logout,
              text: '登出',
            },
          ]}>
          <Text style={styles.nameText} numberOfLines={2}>
            {userProfile?.username}
          </Text>
        </PopupMenu>
      </View>
    </View>
  );
};

export default UserMenu;
