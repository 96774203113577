import { StyleSheet } from 'react-native';

import { fontStyleProps, colorStyleProps } from '../../styles';

export default StyleSheet.create({
  button: {
    width: 342,
    padding: 15,
    display: 'flex',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: colorStyleProps.tpOG.color,
    borderRadius: 10,
  },
  text: {
    marginLeft: 16.6,
    ...fontStyleProps.bold18,
  },
});
