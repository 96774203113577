import React, { useMemo, useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';

import { coreOptions } from '../../../options';
import { colorStyleProps, fontStyleProps } from '../../../styles';
import Capsule from '../../Plan/Capsule';
import AddPicker from './AddPicker';
import CloseSvg from './CloseSvg';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  capsuleContainer: {
    marginRight: 12,
    marginTop: 6,
    marginBottom: 6,
  },
  capsuleTextContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  capsuleText: {
    ...fontStyleProps.bold12,
  },
  capsuleClose: {
    marginLeft: 15,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    paddingHorizontal: 10,
    ...fontStyleProps.regular12,
  },
  itemText: {
    ...fontStyleProps.regular12,
    whiteSpace: 'nowrap',
  },
});

const CoreListPicker = ({ selected, setSelected }) => {
  const [opened, setOpened] = useState(false);

  const [selectedOptions, remainOptions] = useMemo(() => {
    const set = new Set(selected);
    const options1 = [];
    const options2 = [];
    coreOptions.forEach((o) => {
      if (set.has(o.value)) {
        options1.push(o);
      } else {
        options2.push({
          ...o,
          label: undefined,
          children: (
            <View style={styles.item}>
              <Text style={[styles.itemText, { color: colorStyleProps[`category${o.category}`].color }]}>
                {o.value}
              </Text>
              <Text style={styles.itemText}>：{o.label}</Text>
            </View>
          ),
          onSelect: (clicked) => setSelected((old) => [...old, clicked]),
        });
      }
    });
    // no options to select
    if (!options2.length) {
      setOpened(false);
    }
    return [options1, options2];
  }, [selected]);

  return (
    <View style={styles.container}>
      <AddPicker
        opened={opened}
        setOpened={setOpened}
        selectedOptions={selectedOptions}
        remainOptions={remainOptions}
        selected={selected}
        setSelected={setSelected}>
        {({ onUnselect }) =>
          selectedOptions.map((o, x) => {
            const category = `category${o.category}`;
            return (
              <View style={styles.capsuleContainer}>
                <Capsule
                  key={o.value}
                  style={{
                    borderColor: colorStyleProps[category].color,
                    backgroundColor: colorStyleProps[category].backgroundColor,
                  }}>
                  <View style={styles.capsuleTextContainer}>
                    <Text style={[styles.capsuleText, { color: colorStyleProps[category].color }]}>{o.value}</Text>
                    <Text style={styles.capsuleText}>：{o.label}</Text>
                  </View>
                  <View style={styles.capsuleClose}>
                    <TouchableOpacity onPress={() => onUnselect(o.value)}>
                      <CloseSvg color={colorStyleProps[category].color} />
                    </TouchableOpacity>
                  </View>
                </Capsule>
              </View>
            );
          })
        }
      </AddPicker>
    </View>
  );
};

CoreListPicker.defaultProps = {
  selected: [],
  setSelected: () => {},
};

export default CoreListPicker;
