import { LinearGradient } from 'expo-linear-gradient';
import React, { useCallback } from 'react';
import { View, Text, Image, StyleSheet } from 'react-native';

import FallbackableImage from '../../components/Fallbackable/FallbackableImage';
import TouchableOpacityAwait from '../../components/PressAwait/TouchableOpacityAwait';
import HeartSvg from '../../images/Heart';
import { colorStyleProps } from '../../styles';
import CenterText from '../CenterText';
import { renderWithLinkOrNot } from './PlanCard';
import styles from './styles';
import withTag from './withTag';
import withToggleFavorite from './withToggleFavorite';

const shortenNumber = (number) => (number > 99 ? '99+' : number);

const LitePlanCard = ({ plan, toggleFavorite, style, disableLink }) => {
  let linkTo = '/';
  if (plan.type === 0 || plan.type === 100) {
    linkTo = `/planner/${plan.slug}`;
  } else if (plan.type === 1 || plan.type === 101) {
    linkTo = `/plan/${plan.slug}`;
  }

  const toggleFavoriteAndPreventDefault = useCallback(
    (e) => {
      e.preventDefault();
      return toggleFavorite();
    },
    [toggleFavorite]
  );

  return (
    <View style={StyleSheet.compose(styles.card, style)}>
      <View style={styles.liteBackground} />
      <View style={styles.foreground}>
        {renderWithLinkOrNot(
          <View style={{ width: '100%', height: '100%' }}>
            <>
              <View style={styles.liteCover}>
                <FallbackableImage
                  source={{ uri: plan.cover }}
                  fallbackImage={<Image source={require('../../images/default_background.png')} />}
                  style={styles.coverImage}
                />
                <View style={styles.liteTagList}>
                  {plan.tagList.map((tag, idx) => (
                    <CenterText
                      key={tag.key}
                      containerStyle={[styles.tag, tag.containerStyle]}
                      textStyle={[styles.litTagText, tag.textStyle]}>
                      {tag.text}
                    </CenterText>
                  ))}
                </View>
                <View style={styles.gradientContainer}>
                  <LinearGradient
                    style={styles.gradient}
                    start={{ x: 1, y: 0 }}
                    end={{ x: 0.4, y: 1 }}
                    colors={['rgba(0, 0, 0, 0.5)', 'transparent']}
                    locations={[0.1, 0.6]}
                  />
                  <View style={styles.liteFavorites}>
                    <TouchableOpacityAwait onPress={toggleFavoriteAndPreventDefault}>
                      <HeartSvg fill={plan.favorited ? colorStyleProps.tpGreen.color : colorStyleProps.grayBD.color} />
                    </TouchableOpacityAwait>
                    <Text style={styles.liteFavoritesCount}>{shortenNumber(plan.favoritesCount)}</Text>
                  </View>
                </View>
              </View>
              <View style={styles.liteTitle}>
                <Text style={styles.titleText} numberOfLines={1}>
                  {plan.title}
                </Text>
              </View>
            </>
          </View>,
          linkTo,
          disableLink
        )}
      </View>
    </View>
  );
};

export default withToggleFavorite(withTag(LitePlanCard));
