import React, { useCallback } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import StyledText from 'react-native-styled-text';

import Avatar from '../../components/Avatar';
import { Link } from '../../components/UniversalRouter';
import { useMobileView } from '../../pages/Root/useMobileView';
import { fontStyleProps, colorStyleProps } from '../../styles';

const styles = StyleSheet.create({
  item: {
    width: 229,
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 7,
    paddingHorizontal: 12,
  },
  mobileItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 7,
    paddingHorizontal: 12,
  },
  content: {
    width: 190,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 12,
    flexGrow: 1,
  },
  mobileContent: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 12,
    flexGrow: 1,
  },
  message: {
    ...fontStyleProps.regular14,
    ...colorStyleProps.keyBlack,
  },
  time: {
    ...fontStyleProps.regular12,
    ...colorStyleProps.grayBD,
  },
});

const NotificationItem = ({ notification, createdTime }) => {
  const [isMobileView] = useMobileView();
  const now = new Date().getTime();
  const date = new Date(createdTime).getTime();
  //convert to hours
  const timeDifference = (now - date) / (1000 * 3600);
  const toTimeFormatted = useCallback((timeDifference, date) => {
    if (timeDifference >= 24) {
      const newDate = new Date(date).toLocaleDateString();
      return newDate;
    }
    if (timeDifference <= 1) {
      const minutes = Math.round(timeDifference * 60);
      return minutes;
    }
    return Math.round(timeDifference);
  }, []);
  return (
    <View style={isMobileView ? styles.mobileItem : styles.item}>
      {notification.type === 0 ? null : (
        <Link to={`/user/${notification.senderUid}`}>
          <Avatar size={30} uri={notification.senderImage} />
        </Link>
      )}
      <View style={isMobileView ? styles.mobileContent : styles.content}>
        {notification.type === 1 ? (
          notification.planSlug ? (
            <Link to={`/plan/${notification.planSlug}`}>
              <StyledText style={styles.message}>
                {`<b>${notification.senderUsername}</b> ${notification.message}`}
              </StyledText>
            </Link>
          ) : (
            <StyledText style={styles.message}>
              {`<b>${notification.senderUsername}</b> ${notification.message}`}
            </StyledText>
          )
        ) : (
          <Text style={styles.message}>{notification.message}</Text>
        )}
        {timeDifference >= 24 ? (
          <Text style={styles.time}>{toTimeFormatted(timeDifference, date)}</Text>
        ) : timeDifference > 1 ? (
          <Text style={styles.time}>約 {toTimeFormatted(timeDifference, date)} 小時前</Text>
        ) : (
          <Text style={styles.time}>約 {toTimeFormatted(timeDifference, date)} 分鐘前</Text>
        )}
      </View>
    </View>
  );
};

export default NotificationItem;
