import React, { useCallback, useState, useMemo } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import Accordion from 'react-native-collapsible/Accordion';
import { MenuOption } from 'react-native-popup-menu';

import Popup from '../../../components/Popup';
import { colorStyleProps, fontStyleProps } from '../../../styles';
import Capsule from '../../Plan/Capsule';
import ArrowSvg from './arrow.svg';
import PlusSvg from './plus.svg';

const styles = StyleSheet.create({
  header: {
    paddingTop: 10,
    paddingHorizontal: 15,
    paddingBottom: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderTopWidth: 1,
    borderTopColor: colorStyleProps.grayBD.color,
  },
  headerText: {
    ...fontStyleProps.regular12,
    color: colorStyleProps.tpBlack.color,
    flexGrow: 1,
  },
  content: {
    maxHeight: 300,
    overflow: 'auto',
  },
  capsuleContainer: {
    marginTop: 6,
    marginBottom: 6,
    width: 'fit-content',
  },
});

const PickerPopup = ({ opened, canAdd, onAddPress, onBackdropPress, hasSection, children }) => (
  <Popup
    trigger={
      canAdd && (
        <TouchableOpacity disabled={opened} onPress={onAddPress}>
          <View style={styles.capsuleContainer}>
            <Capsule style={{ opacity: opened ? 0.2 : 1 }}>
              <Text style={{ ...fontStyleProps.regular14 }}>新增</Text>
              <View style={{ marginLeft: 15 }}>
                <PlusSvg />
              </View>
            </Capsule>
          </View>
        </TouchableOpacity>
      )
    }
    menuProps={{ rendererProps: { placement: 'auto' }, opened, onBackdropPress }}
    optionsProps={{
      customStyles: {
        optionsContainer: {
          minWidth: 150,
          paddingVertical: 10,
          borderRadius: 10,
          borderWidth: 1,
          borderColor: colorStyleProps.keyBlack.color,
        },
      },
    }}>
    <MenuOption
      text="新增"
      disableTouchable
      customStyles={{
        optionText: { paddingHorizontal: 10, ...fontStyleProps.bold12, color: colorStyleProps.gray82.color },
      }}
    />
    <View
      style={{
        backgroundColor: colorStyleProps.grayBD.color,
        width: '100%',
        height: hasSection ? 0 : 1,
        marginVertical: 10,
      }}
    />
    {children}
  </Popup>
);

const AccordionMenu = ({ options }) => {
  const [activeSections, setActiveSections] = useState([]);
  const sectionOptions = useMemo(() => {
    const sections = {};
    options.forEach((o) => {
      sections[o.section] = sections[o.section] || [];
      sections[o.section].push(o);
    });
    return Object.keys(sections).map((k) => ({ title: k, content: sections[k] }));
  }, [options]);
  return (
    <Accordion
      activeSections={activeSections}
      sections={sectionOptions}
      renderHeader={(section) => (
        <View style={styles.header}>
          <Text style={styles.headerText}>{section.title}</Text>
          <ArrowSvg />
        </View>
      )}
      renderContent={(section) => (
        <View style={styles.content}>
          {section.content.map((o) => (
            <MenuOption key={o.value} {...o} />
          ))}
        </View>
      )}
      onChange={(section) => setActiveSections(section)}
    />
  );
};

const AddPicker = ({ opened, setOpened, selectedOptions, remainOptions, children, selected, setSelected }) => {
  const onAddPress = useCallback(() => setOpened(true), []);
  const onBackdropPress = useCallback(() => setOpened(false), []);
  const onUnselect = useCallback(
    (clicked) => {
      setSelected(selected.filter((s) => s !== clicked));
    },
    [selected, setSelected]
  );

  const hasSection = remainOptions[0] && !!remainOptions[0].section;

  return (
    <>
      {children({ onUnselect })}
      <PickerPopup
        opened={opened}
        canAdd={!!remainOptions.length}
        onAddPress={onAddPress}
        onBackdropPress={onBackdropPress}
        hasSection={hasSection}>
        {hasSection ? (
          <AccordionMenu options={remainOptions} />
        ) : (
          remainOptions.map((o) => <MenuOption key={o.value} {...o} />)
        )}
      </PickerPopup>
    </>
  );
};

export default AddPicker;
