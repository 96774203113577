import React, { useMemo } from 'react';
import { View } from 'react-native';

import styles from './styles';

const CardsView = ({ cards, renderCard, numColumns }) => {
  /*
    1 column
    +---------------+
    | +-----------+ |
    | | +-------+ | |
    | | | card  | | | 1 card in 1 row(margin/ card/ margin)
    | | +-------+ | |
    | +-----------+ |
    | | +-------+ | |
    | | | card  | | |
    | | +-------+ | |
    | +-----------+ |
    | ...           |
    2 columns
    +-------------------------+
    | +---------------------+ |
    | | +-------+ +-------+ | | 2 cards in 1 row(margin/ card/ margin/ card/ margin)
    | | | card  | | card  | | |
    | | +-------+ +-------+ | |
    | +---------------------+ |
    | | +-------+           | |
    | | | card  |           | |
    | | +-------+           | |
    | +---------------------+ |
    | ...                     |
  */
  const cardRows = useMemo(() => {
    const padded = cards.concat(Array(numColumns - (cards.length % numColumns)).fill(null));
    const rows = [];
    padded.forEach((card) => {
      const rowKey = rows.length;

      if (numColumns === 1) {
        rows.push([
          <View key={`${rowKey}-0`} style={styles.flexableMargin} />,
          <View key={`${rowKey}-1`}>{card ? renderCard(card, true) : null}</View>,
          <View key={`${rowKey}-2`} style={styles.flexableMargin} />,
        ]);
        return;
      }

      // insert a new row if there is no row or current row contains <numColumns> cards
      if (rows.length === 0 || rows[rows.length - 1].length === 2 * numColumns - 1) {
        rows.push([]);
      }

      const currentRow = rows[rows.length - 1];

      // insert margin between cards
      if (currentRow.length > 0) {
        currentRow.push(<View key={`${rowKey}-${currentRow.length}`} style={styles.fixedMargin} />);
      }

      currentRow.push(
        <View key={`${rowKey}-${currentRow.length}`} style={{ flex: 1 }}>
          {card ? renderCard(card) : null}
        </View>
      );
    });
    return rows;
  }, [numColumns, cards]);

  return (
    <View style={{ width: '100%' }}>
      {cardRows.map((cardRow, idx) => (
        <View key={idx} style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 10 }}>
          {cardRow}
        </View>
      ))}
    </View>
  );
};

CardsView.defaultProps = {
  numColumns: 4,
};

export default CardsView;
