import { useState, useCallback } from 'react';

const Fallbackable = ({ children, ...props }) => {
  const [isFailed, setFailed] = useState();
  const onLoad = useCallback(() => {
    setFailed(false);
  }, []);

  const onError = useCallback(() => {
    setFailed(true);
  }, []);

  return children({ isFailed, onLoad, onError });
};

export default Fallbackable;
