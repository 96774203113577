import React, { useState, useMemo } from 'react';
import { StyleSheet } from 'react-native';

import UniversalLottie from '../components/UniversalLottie';
import UniversalOverlay from '../components/UniversalOverlay';
import loadingAnimation from '../images/Loading.json';

export const LoadingContext = React.createContext();

const styles = StyleSheet.create({
  overlay: {
    borderWidth: 0,
    width: '100%',
    height: '100%',
  },
  overlayOverlay: {
    padding: 0,
    shadowOpacity: 0,
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const value = useMemo(
    () => ({
      isLoading,
      setIsLoading,
    }),
    [isLoading]
  );

  return (
    <LoadingContext.Provider value={value}>
      {children}
      <UniversalOverlay
        isVisible={isLoading}
        fullScreen
        animation={false}
        style={styles.overlay}
        overlayStyle={styles.overlayOverlay}>
        <UniversalLottie source={loadingAnimation} width={150} height={150} />
      </UniversalOverlay>
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
