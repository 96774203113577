import axios from 'axios';
import * as DocumentPicker from 'expo-document-picker';
import * as FileSystem from 'expo-file-system';
import React, { useCallback, useState } from 'react';
import { Platform, TouchableOpacity } from 'react-native';

import apis from '../../apis';

const onSelectDefault = async () => {
  /*
    https://docs.expo.io/versions/v41.0.0/sdk/document-picker/#documentpickergetdocumentasyncoptions
    Notes for Web: The system UI can only be shown after user activation (e.g. a Button press).
    Therefore, calling getDocumentAsync in componentDidMount, for example, will not work as intended.
    The cancel event will not be returned in the browser due to platform restrictions and inconsistencies across browsers.
    It means that getDocumentAsync maybe not return a result in the browser when the user cancels selection.
  */
  const result = await DocumentPicker.getDocumentAsync();
  if (result.type === 'success') {
    return result;
  }
  return null;
};

const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }
  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

const guessContentType = (uri) => {
  if (uri.startsWith('data:image/png;') || uri.endsWith('.png')) {
    return 'image/png';
  }
  if (uri.startsWith('data:image/jpeg;') || uri.endsWith('.jpg') || uri.endsWith('.jpeg')) {
    return 'image/jpeg';
  }
  return 'application/octet-stream';
};

const FileUploader = ({ style, onSelect, onUpload, objectType, children, ...props }) => {
  const [isUploading, setIsUploading] = useState(false);

  const onPress = useCallback(async () => {
    if (isUploading) {
      return;
    }

    const result = await (onSelect || onSelectDefault)();

    // cancelled
    if (!result) {
      // TODO handle selection cancelled or failed
      return;
    }

    try {
      setIsUploading(true);

      const {
        data: { download_url, presigned_info },
      } = await apis.aws.getPresignedUrl(objectType, guessContentType(result.uri));

      if (Platform.OS === 'web') {
        const formData = new FormData();
        Object.keys(presigned_info.fields).forEach((key) => {
          formData.append(key, presigned_info.fields[key]);
        });
        formData.append('file', dataURItoBlob(result.uri));

        await axios.post(presigned_info.url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        await FileSystem.uploadAsync(presigned_info.url, result.uri, {
          uploadType: FileSystem.FileSystemUploadType.MULTIPART,
          fieldName: 'file',
          parameters: presigned_info.fields,
        });
      }
      if (onUpload) {
        await onUpload(download_url);
      }
    } catch (error) {
      // TODO handle error
      console.error(error);
    } finally {
      setIsUploading(false);
    }
  }, [onUpload]);

  return (
    <TouchableOpacity
      disabled={isUploading}
      style={[
        { position: 'relative', cursor: isUploading ? 'wait' : 'pointer', opacity: isUploading ? 0.5 : 1 },
        style,
      ]}
      onPress={onPress}
      {...props}>
      {children}
    </TouchableOpacity>
  );
};

FileUploader.defaultProps = {
  objectType: 'photo',
};

export default FileUploader;
