import React, { useState, useCallback, useEffect } from 'react';
import { StyleSheet, View, Text } from 'react-native';

import CustomInput from '../../../components/CustomInput';
import Textarea from '../../../components/Textarea';
import { fontStyleProps, colorStyleProps } from '../../../styles';
import Capsule from '../../Plan/Capsule';
import PlannerFormField from './PlannerFormField';
import rules from './rules';

const styles = StyleSheet.create({
  main: {
    paddingVertical: 60,
    paddingHorizontal: 80,
  },
  field: {
    marginTop: 45,
  },
  label: {
    ...fontStyleProps.bold20,
  },
});

const SubPlanForm = ({ methods, plan, subPlan, index }) => {
  const [goalContainsKeywords, setGoalContainsKeywords] = useState({});
  const onGoalChange = useCallback((goal) => {
    const newContains = {};
    let text = goal;
    plan.keywords.forEach((keyword) => {
      if (text.indexOf(keyword) > -1) {
        text = text.replaceAll(keyword, ' ');
        newContains[keyword] = true;
      }
    });
    setGoalContainsKeywords(newContains);
  }, []);

  useEffect(() => {
    methods.trigger();
  }, []);

  return (
    <View style={styles.main}>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <PlannerFormField
          label="子題"
          name={`subPlans.${index}.title`}
          defaultValue={subPlan.title}
          control={methods.control}
          rules={rules.subPlan.title}
          style={[styles.field, { flexGrow: 1, marginRight: 15 }]}>
          {({ field, error }) => <CustomInput field={field} error={error} />}
        </PlannerFormField>
        <PlannerFormField
          label="節數"
          name={`subPlans.${index}.totalLectures`}
          defaultValue={subPlan.totalLectures}
          control={methods.control}
          rules={rules.subPlan.totalLectures}
          style={[styles.field, { flexBasis: 150, flexGrow: 0, flexShrink: 0, marginLeft: 15 }]}>
          {({ field, error }) => <CustomInput field={field} error={error} />}
        </PlannerFormField>
      </View>
      <PlannerFormField
        label="學習表現 (相關領域或自訂)"
        name={`subPlans.${index}.performance`}
        defaultValue={subPlan.performance}
        control={methods.control}
        rules={rules.subPlan.performance}
        style={styles.field}>
        {({ field, error }) => <Textarea field={field} error={error} minRows={10} maxRows={25} />}
      </PlannerFormField>
      <PlannerFormField
        label="學習內容 (相關領域或自訂)"
        name={`subPlans.${index}.content`}
        defaultValue={subPlan.content}
        control={methods.control}
        rules={rules.subPlan.content}
        style={styles.field}>
        {({ field, error }) => <Textarea field={field} error={error} minRows={10} maxRows={25} />}
      </PlannerFormField>
      <PlannerFormField
        label={
          <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ flexShrink: 0, marginRight: 15 }}>課程目標</Text>
            <View style={{ width: '100%', flexDirection: 'row', flexWrap: 'wrap', flexShrink: 1 }}>
              {plan.keywords.map((k) => (
                <Capsule
                  key={k}
                  style={{
                    borderColor: colorStyleProps.keyBlack.color,
                    backgroundColor: colorStyleProps.keyBlack.color,
                    marginRight: 12,
                    marginTop: 6,
                    marginBottom: 6,
                    opacity: goalContainsKeywords[k] ? 1 : 0.5,
                  }}>
                  <Text
                    style={{
                      ...fontStyleProps.regular14,
                      color: colorStyleProps.tpWhite.color,
                    }}>
                    {k}
                  </Text>
                </Capsule>
              ))}
            </View>
          </View>
        }
        name={`subPlans.${index}.goal`}
        defaultValue={subPlan.goal}
        control={methods.control}
        rules={rules.subPlan.goal}
        style={styles.field}>
        {({ field, error }) => (
          <Textarea field={field} error={error} minRows={10} maxRows={25} onChangeText={onGoalChange} />
        )}
      </PlannerFormField>
      <PlannerFormField
        label="學習活動"
        name={`subPlans.${index}.activity`}
        defaultValue={subPlan.activity}
        control={methods.control}
        rules={rules.subPlan.activity}
        style={styles.field}>
        {({ field, error }) => <Textarea field={field} error={error} minRows={10} maxRows={25} />}
      </PlannerFormField>
      <PlannerFormField
        label="學習評量"
        name={`subPlans.${index}.assessment`}
        defaultValue={subPlan.assessment}
        control={methods.control}
        rules={rules.subPlan.assessment}
        style={styles.field}>
        {({ field, error }) => <Textarea field={field} error={error} minRows={10} maxRows={25} />}
      </PlannerFormField>
      <PlannerFormField
        label="備註"
        name={`subPlans.${index}.note`}
        defaultValue={subPlan.note}
        control={methods.control}
        rules={rules.subPlan.note}
        style={styles.field}>
        {({ field, error }) => <Textarea field={field} error={error} minRows={3} maxRows={10} />}
      </PlannerFormField>
    </View>
  );
};

export default SubPlanForm;
