export default {
  cover: {
    required: { value: true, message: '必選' },
  },
  title: {
    required: { value: true, message: '必填' },
  },
  planName: {
    required: { value: true, message: '必填' },
  },
  date: {
    required: { value: true, message: '必填' },
  },
  semester: {
    required: { value: true, message: '必填' },
  },
  designer: {
    required: { value: true, message: '必填' },
  },
  totalLectures: {
    required: { value: true, message: '必填' },
    pattern: {
      value: /^[0-9]+$/,
      message: '必須為數字',
    },
  },
  idea: {
    required: { value: true, message: '必填' },
  },
  ideas: {
    validate: (v) => v.length > 0 || '必選',
  },
  cores: {
    validate: (v) => v.length > 0 || '必選',
  },
  core: {
    coreName: {
      required: { value: true, message: '必填' },
    },
    body: {
      required: { value: true, message: '必填' },
    },
  },
  goal: {
    required: { value: true, message: '必填' },
  },
  keywords: {
    validate: (v) => v.length > 0 || '必選',
  },
  description: {
    required: { value: true, message: '必填' },
  },

  subPlan: {
    title: {
      required: { value: true, message: '必填' },
    },
    totalLectures: {
      required: { value: true, message: '必填' },
      pattern: {
        value: /^[0-9]+$/,
        message: '必須為數字',
      },
    },
    goal: {
      required: { value: true, message: '必填' },
    },
    performance: {
      required: { value: true, message: '必填' },
    },
    content: {
      required: { value: true, message: '必填' },
    },
    activity: {
      required: { value: true, message: '必填' },
    },
    assessment: {
      required: { value: true, message: '必填' },
    },
  },
};
