import React, { useState, useEffect, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { View, Text, TouchableOpacity, Platform } from 'react-native';

import Button from '../../../../components/Button';
import CustomInput from '../../../../components/CustomInput';
import Field from '../../../../components/Form/Field';
import ButtonAwait from '../../../../components/PressAwait/ButtonAwait';
import { colorStyleProps } from '../../../../styles';
import AvatarName from '../../AvatarName';
import socialNetworks, { withOpen } from '../socialNetworks';
import SubjectPicker from './SubjectPicker';
import rules from './rules';
import styles from './styles';

const subjectStyle = {};

if (Platform.OS !== 'android') {
  // make dropdown overlap on other UIs
  subjectStyle.zIndex = 1;
}

const okBtnStyle = {
  container: {
    marginBottom: 24,
  },
};

const cancelBtnStyle = {
  negativeButton: {
    borderWidth: 0,
  },
  negativeTitle: {
    color: colorStyleProps.gray82.color,
  },
};

const CharacterCounter = ({ count, limit }) => {
  return (
    <View style={styles.bioCounter}>
      <Text style={styles.bioCounterText}>
        {count}/{limit}
      </Text>
    </View>
  );
};

const ProfileForm = ({ isMobileView, userProfile, onAvatarUpload, onExitEditingMode, onSaveUserProfile }) => {
  const methods = useForm({ mode: 'all', shouldUnregister: true });
  const [bioLength, setBioLength] = useState(userProfile?.bio?.length || 0);

  useEffect(() => {
    methods.trigger();
  }, []);

  const { isValid } = methods.formState;

  const [socialLinks, setSocialLinks] = useState(
    socialNetworks.map((sn) => methods.getValues(sn.type) || userProfile?.[sn.type] || '')
  );
  const socialNetworksWithOpen = useMemo(() => withOpen(socialLinks), socialLinks);

  const onSocialLinkChange = useMemo(
    () =>
      socialNetworks.map((sn, idx) => (newLink) => {
        setSocialLinks((oldLinks) => {
          const newLinks = [...oldLinks];
          newLinks[idx] = newLink;
          return newLinks;
        });
      }),
    [socialNetworks]
  );

  return (
    <View style={isMobileView ? styles.mobileUserProfileContainer : styles.userProfileContainer}>
      <View style={isMobileView ? styles.mobileAvatarNameContainer : styles.avatarNameContainer}>
        <AvatarName isEditable userProfile={userProfile} onUpload={onAvatarUpload} avatarOnly />
      </View>
      <View style={styles.formContainer}>
        <FormProvider {...methods}>
          <Field
            label="顯示名稱"
            name="username"
            defaultValue={userProfile?.username || ''}
            control={methods.control}
            rules={rules.username}>
            {({ field, error }) => (
              <CustomInput field={field} error={error} style={[styles.textInput]} maxLength={120} />
            )}
          </Field>
          <Field
            label="任教科目"
            name="subject"
            defaultValue={userProfile?.subject}
            style={subjectStyle}
            control={methods.control}
            rules={rules.subject}>
            {({ field, error }) => <SubjectPicker field={field} />}
          </Field>
          <Field
            label="任教學校"
            name="school"
            defaultValue={userProfile?.school || ''}
            control={methods.control}
            rules={rules.school}>
            {({ field, error }) => (
              <CustomInput field={field} error={error} style={[styles.textInput]} maxLength={120} />
            )}
          </Field>
          <Field
            label="電子郵件地址"
            name="email"
            defaultValue={userProfile?.email || ''}
            control={methods.control}
            rules={rules.email}>
            {({ field, error }) => (
              <CustomInput field={field} error={error} style={[styles.textInput]} maxLength={320} />
            )}
          </Field>
          <Field
            label="自介"
            name="bio"
            defaultValue={userProfile?.bio || ''}
            control={methods.control}
            rules={rules.bio}>
            {({ field, error }) => (
              <CustomInput
                field={field}
                error={error}
                style={styles.textArea}
                multiline
                maxLength={300}
                numberOfLines={10}
                onChangeTextLength={setBioLength}
                textAlignVertical="top"
              />
            )}
          </Field>
          <View />
          <CharacterCounter count={bioLength} limit={300} />
          {socialNetworksWithOpen.map(({ type, label, icon: Icon, open }, idx) => (
            <View key={type} style={styles.socialGroup}>
              <View style={styles.socialIcon}>
                <TouchableOpacity onPress={open} disabled={!open}>
                  <Icon
                    fill={open ? colorStyleProps.keyBlack.color : colorStyleProps.grayBD.color}
                    width={24}
                    height={24}
                  />
                </TouchableOpacity>
              </View>
              <View style={styles.socialTextInput}>
                <Field name={type} defaultValue={socialLinks[idx]} control={methods.control} rules={rules[type]}>
                  {({ field, error }) => (
                    <CustomInput
                      field={field}
                      error={error}
                      maxLength={80}
                      placeholder={label}
                      onChangeText={onSocialLinkChange[idx]}
                    />
                  )}
                </Field>
              </View>
            </View>
          ))}
          <View style={styles.buttonContainer}>
            <ButtonAwait
              title="儲存個人資料"
              onPress={async () => {
                await methods.handleSubmit(onSaveUserProfile)();
              }}
              style={okBtnStyle}
              disabled={!isValid}
            />
            <Button title="取消" onPress={onExitEditingMode} negative style={cancelBtnStyle} />
          </View>
        </FormProvider>
      </View>
    </View>
  );
};

export default ProfileForm;
