import { StyleSheet } from 'react-native';

import { colorStyleProps } from '../../styles';

export default StyleSheet.create({
  bar: {
    width: '100%',
    height: 100,
    backgroundColor: colorStyleProps.tpWhite.color,

    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: 70,
    flexGrow: 0,
    flexShrink: 1,
  },
  navTypes: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',

    flexGrow: 1,
    flexShrink: 1,
  },
});
