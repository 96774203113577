import { StyleSheet, StatusBar, Platform } from 'react-native';

import { colorStyleProps } from '../../styles';

export default StyleSheet.create({
  root: {
    backgroundColor: colorStyleProps.tpWhite.color,
    position: 'relative',
    width: '100%',
    height: Platform.OS === 'web' ? '100vh' : '100%',
    paddingTop: StatusBar.currentHeight,

    display: 'flex',
    alignItems: 'center',
  },
  body: {
    width: '100%',
    height: '100%',
    flex: 1,
  },
});
