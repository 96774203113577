import React, { memo, useCallback, useState, useEffect, useRef } from 'react';
import { View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { useMobileView } from '../../Root/useMobileView';
import ProfileForm from './ProfileForm';
import ProfileView from './ProfileView';
import styles from './styles';

const UserProfile = memo(
  ({
    userProfile,
    onScroll,
    onFollowChange,
    isEditable,
    onSaveUserProfile,
    onAvatarUpload,
    onBlockUser,
    onUnblockUser,
  }) => {
    const [isMobileView] = useMobileView();
    const [isEditMode, setEditMode] = useState(false);
    const scrollableRef = useRef();

    const toggleEditMode = useCallback(() => {
      setEditMode((old) => !old);
    }, []);

    const onExitEditingMode = useCallback(() => {
      setEditMode(false);
      if (scrollableRef.current) {
        scrollableRef.current.scrollToPosition(0, 0);
      }
    }, []);

    useEffect(() => {
      onExitEditingMode();
    }, [userProfile]);

    // make content scrollable
    const [scrollableChildStyle, setScrollableChildStyle] = useState([isMobileView ? styles.mobileMain : styles.main]);
    const onScrollableLayout = useCallback(
      (e) => {
        if (isMobileView) {
          const height = e.nativeEvent.layout.height;
          setScrollableChildStyle((old) => [styles.mobileMain, { minHeight: 54 + height }]);
        } else {
          setScrollableChildStyle((old) => [styles.main]);
        }
      },
      [isMobileView]
    );

    const rendered = (
      <View style={scrollableChildStyle}>
        {isEditMode ? (
          <ProfileForm
            isMobileView={isMobileView}
            userProfile={userProfile}
            onAvatarUpload={onAvatarUpload}
            onExitEditingMode={onExitEditingMode}
            onSaveUserProfile={onSaveUserProfile}
          />
        ) : (
          <ProfileView
            isMobileView={isMobileView}
            userProfile={userProfile}
            isEditable={isEditable}
            onAvatarUpload={onAvatarUpload}
            onFollowChange={onFollowChange}
            toggleEditMode={toggleEditMode}
            onBlockUser={onBlockUser}
            onUnblockUser={onUnblockUser}
          />
        )}
      </View>
    );

    return isMobileView ? (
      <KeyboardAwareScrollView ref={scrollableRef} onScroll={onScroll} onLayout={onScrollableLayout}>
        {rendered}
      </KeyboardAwareScrollView>
    ) : (
      rendered
    );
  }
);

export default UserProfile;
