import { StyleSheet, Platform } from 'react-native';

import { fontStyleProps, colorStyleProps } from '../../styles';

export default StyleSheet.create({
  landing: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colorStyleProps.tpWhite.color,
    paddingTop: Platform.OS === 'web' ? 60 : undefined,
  },

  title: {
    ...fontStyleProps.bold56,
    ...colorStyleProps.keyBlack,
    fontSize: 20,
    lineHeight: 30,
    marginBottom: 10,
    flexGrow: 0,
    flexShrink: 0,
  },

  image: {
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
    paddingRight: 20,
    paddingLeft: 20,
    backgroundColor: colorStyleProps.tpOG.color,
    flexGrow: 1,
    flexShrink: 1,
  },

  buttons: {
    flexGrow: 0,
    flexShrink: 0,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subtitle: {
    ...fontStyleProps.bold18,
    ...colorStyleProps.tpBlack,
    paddingTop: 20,
  },
  first: {
    marginTop: 20,
  },
  second: {
    marginTop: 10,
  },
  last: {
    marginTop: 10,
    marginBottom: 20,
    marginHorizontal: 20,
  },
});
