import React, { useCallback } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';

import { useHistoryWithSpec } from '../components/UniversalRouter';
import ChevronLeft from '../images/ChevronLeft';
import { fontStyleProps, colorStyleProps } from '../styles';

const styles = StyleSheet.create({
  main: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    ...fontStyleProps.bold56,
    ...colorStyleProps.tpGreen,
  },
  content: {
    ...fontStyleProps.regular18,
    ...colorStyleProps.tpBlack,
  },
  back: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const NotFound = ({ message }) => {
  const history = useHistoryWithSpec();

  const onGoBack = useCallback(() => {
    if (history.length) {
      history.goBack();
    } else {
      history.replace('/');
    }
  }, [history]);

  return (
    <View style={styles.main}>
      <Text style={styles.title}>Oops!</Text>
      {message && <Text style={styles.content}>{message}</Text>}
      <TouchableOpacity onPress={onGoBack}>
        <View style={styles.back}>
          <ChevronLeft fill="#000" />
          <Text>退回上一頁</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default NotFound;
