import React from 'react';
import { TouchableOpacity, View, Text, StyleSheet } from 'react-native';

import { fontStyleProps, colorStyleProps } from '../../styles';
import BackSvg from './back.svg';

const styles = StyleSheet.create({
  main: {
    display: 'flex',
    flexDirection: 'row',
  },
  text: {
    ...fontStyleProps.regular18,
    color: colorStyleProps.keyBlack.color,
  },
});

const GoBack = ({ onPress }) => (
  <TouchableOpacity onPress={onPress}>
    <View style={styles.main}>
      <BackSvg />
      <Text style={styles.text}>上一步</Text>
    </View>
  </TouchableOpacity>
);

export default GoBack;
