import { StyleSheet } from 'react-native';

import { colorStyleProps, fontStyleProps } from '../../styles';

export default StyleSheet.create({
  main: {
    backgroundColor: colorStyleProps.tpOG.color,
    paddingLeft: '4.86vw',
    width: '100%',
    height: '100%',
  },
  title: {
    marginTop: 35,
    marginBottom: 20,
    ...fontStyleProps.bold36,
  },
  keyword: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    ...fontStyleProps.bold14,
    borderWidth: 1,
    borderColor: colorStyleProps.gray55,
    borderRadius: 50,
    backgroundColor: colorStyleProps.tpWhite.color,
    marginBottom: 32,
    width: 'fit-content',
  },
});
