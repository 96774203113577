import React, { useCallback, useState, useContext } from 'react';
import { View, StyleSheet } from 'react-native';

import apis from '../../../apis';
import Button from '../../../components/Button';
import { useHistoryWithSpec } from '../../../components/UniversalRouter';
import { LoadingContext } from '../../../containers/LoadingProvider';
import { OverlayContext } from '../../../containers/OverlayManager';
import { fontStyleProps, colorStyleProps } from '../../../styles';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    overflow: 'hidden',

    borderRadius: 10,
    borderColor: 'transparent',
    borderWidth: 1,

    margin: 2,
    position: 'relative',

    paddingTop: '81.2%',
  },
  content: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    zIndex: -1,
  },
  text: {
    marginTop: 20,
    ...fontStyleProps.bold24,
    ...colorStyleProps.grayBD,
  },
  buttons: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
});

const PlanCardViewOrEditOrDelete = ({ plan, onDelete, children }) => {
  const { setIsLoading } = useContext(LoadingContext);
  const { pushOverlay } = useContext(OverlayContext);
  const [showButtons, setShowButtons] = useState(false);
  const history = useHistoryWithSpec();

  const onMouseOver = useCallback(() => {
    setShowButtons(true);
  }, []);

  const onMouseOut = useCallback(() => {
    setShowButtons(false);
  }, []);

  const onViewPlan = useCallback(() => {
    history.push(`/plan/${plan.slug}`);
  }, [plan]);

  const onEditPlan = useCallback(() => {
    history.push(`/planner/${plan.slug}`);
  }, [plan]);

  const onDeletePlan = useCallback(() => {
    if (plan.slug) {
      pushOverlay(({ Confirm }) => (
        <Confirm
          message="確定要刪除?"
          onOK={() => {
            setIsLoading(true);
            apis.plan
              .deletePlan(plan.slug)
              .then(() => {
                onDelete(plan.slug);
                setIsLoading(false);
              })
              .catch((error) => {
                console.error(error);
                setIsLoading(false);
              });
          }}
        />
      ));
    }
  }, [plan, onDelete]);

  const isDraft = plan.type === 0 || plan.type === 100;
  const editTitle = isDraft ? '編輯草稿' : '檢視';
  const deleteTitle = isDraft ? '刪除草稿' : '刪除';
  const onEditOrView = isDraft ? onEditPlan : onViewPlan;

  return (
    <View style={styles.container} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      <View style={styles.content}>
        {children}
        <View style={[styles.buttons, { opacity: showButtons ? 1 : 0 }]}>
          <Button title={editTitle} type="green" onPress={onEditOrView} />
          <View style={{ marginTop: 12 }}>
            <Button title={deleteTitle} type="red" onPress={onDeletePlan} />
          </View>
        </View>
      </View>
    </View>
  );
};

export default PlanCardViewOrEditOrDelete;
