import React from 'react';
import { View } from 'react-native';

import Lottie from './Lottie';

const UniversalLottie = (props) => (
  <View>
    <Lottie {...props} />
  </View>
);

export default UniversalLottie;
