import React, { useState, useCallback } from 'react';

import apis from '../../apis/apis';
import useMountedFlag from '../useMountedFlag';

const getSummary = () => {
  try {
    return apis.notifications.getNotificationsSummary();
  } catch (error) {
    console.log(error);
  }
  return null;
};

const updateNotification = (type) => {
  try {
    return apis.notifications.putNotificatonToRead(type);
  } catch (error) {
    console.log(error);
  }
};

const withFetchNotification = (Comp) => (props) => {
  const [unread, setRead] = useState();
  const isMountedRef = useMountedFlag();

  const notificationBadge = useCallback(async () => {
    const { data } = await getSummary();

    if (!isMountedRef.current) {
      return;
    }

    setRead(data.unread);
  }, []);

  const onOpeNotification = useCallback(async () => {
    try {
      await updateNotification(1);
    } finally {
      const { data } = await getSummary();

      if (!isMountedRef.current) {
        return;
      }

      setRead(data.unread);
    }
  }, []);

  return (
    <Comp {...props} unread={unread} onOpeNotification={onOpeNotification} notificationBadge={notificationBadge} />
  );
};

export default withFetchNotification;
