import React, { useMemo } from 'react';
import { Button as RNEButton } from 'react-native-elements';

import * as predefinedStyles from './styles';

const Button = ({ negative, type, style, ...props }) => {
  const styles = useMemo(() => {
    const predefinedStyle = predefinedStyles[type];
    return {
      container: [predefinedStyle.container, style?.container],
      main: {
        default: [predefinedStyle.main, style?.main],
        negative: [predefinedStyle.main, predefinedStyle.negativeMain, style?.negativeMain],
      },
      button: {
        default: [predefinedStyle.button, style?.button],
        negative: [predefinedStyle.button, predefinedStyle.negativeButton, style?.negativeButton],
      },
      title: {
        default: [predefinedStyle.title, style?.title],
        negative: [predefinedStyle.title, predefinedStyle.negativeTitle, style?.negativeTitle],
      },
      disabled: {
        borderWidth: 0,
        cursor: 'not-allowed',
      },
    };
  }, [type, predefinedStyles, style]);

  const styleName = negative ? 'negative' : 'default';

  /*
  Button styles:
    +-----------------------+
    | containerStyle        |  <- container
    | +-------------------+ |
    | | style             | |  <- main
    | | +---------------+ | |
    | | | buttonStyle   | | |  <- button
    | | | +-----------+ | | |
    | | | |titleStyle | | | |  <- title
    | | | +-----------+ | | |
    +---------------------+
  */
  return (
    <RNEButton
      {...props}
      containerStyle={styles.container}
      style={styles.main[styleName]}
      buttonStyle={styles.button[styleName]}
      titleStyle={styles.title[styleName]}
      disabledStyle={styles.disabled}
    />
  );
};

Button.defaultProps = {
  negative: false,
  type: 'green',
};

export default Button;
