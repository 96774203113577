import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Select from 'react-select';

import { fontStyleProps, colorStyleProps } from '../../styles';

const DropDown = ({ items, defaultValue, onChangeValue, style, ...pickerProps }) => {
  const [value, setValue] = useState();

  useEffect(() => {
    const defaultOption = items.find(({ value }) => value === defaultValue);
    if (items.length && !defaultOption) {
      setValue(pickerProps.multiple ? [items[0]] : items[0]);
    } else {
      setValue(defaultOption);
    }
  }, []);

  const styles = useMemo(() => {
    return {
      container: (provided) => ({
        ...provided,
        ...style.container,
      }),
      control: (provided) => ({
        ...provided,
        padding: '0px 5px',
        height: '40px',

        ...fontStyleProps.bold16,
        color: colorStyleProps.tpBlack.color,
        lineHeight: '18px',

        border: `1px solid ${colorStyleProps.grayBD.color}`,
        borderRadius: '10px',
      }),
      menuList: (provided) => ({
        ...provided,

        ...fontStyleProps.bold16,
        color: colorStyleProps.tpBlack.color,
        lineHeight: '18px',
      }),

      indicatorSeparator: (provided) => ({ ...provided, display: 'none' }),
    };
  }, [style]);

  const onChange = useCallback(
    (selected) => {
      setValue(selected);
      onChangeValue && onChangeValue(selected.value);
    },
    [onChangeValue]
  );

  return (
    <Select
      styles={styles}
      placeholder="請選擇"
      options={items}
      value={value}
      setValue={setValue}
      onChange={onChange}
    />
  );
};

DropDown.defaultProps = {
  items: [],
  style: {},
};

export default DropDown;
