import axios from 'axios';

export const postPlan = (data) => axios.post('/api/plan', data);

export const putPlan = (slug, data) => axios.put(`/api/plan/${slug}`, data);

export const getPlan = (slug) => axios.get(`/api/plan/${slug}`);

export const deletePlan = (slug) => axios.delete(`/api/plan/${slug}`);

export const getCoreTags = (phase, cores) => {
  const params = new URLSearchParams();
  params.append('edu_type', phase);
  cores.forEach((core) => params.append('core', core));
  return axios.get('/api/plan/core/tags', {
    params,
  });
};
