import RCTooltip from 'rc-tooltip';
import React, { useMemo } from 'react';
import { Text } from 'react-native';
import 'rc-tooltip/assets/bootstrap.css';

import { fontStyleProps, colorStyleProps } from '../styles';

const styles = {
  text: {
    ...fontStyleProps.regular12,
    color: colorStyleProps.tpWhite.color,
  },
  overlay: {
    maxWidth: 200,
  },
};

const Tooltip = ({ text, children, ...props }) => {
  const overlay = useMemo(() => {
    if (text) {
      return <Text style={styles.text}>{text}</Text>;
    }
    return undefined;
  }, [text]);
  return (
    <RCTooltip placement="top" trigger={['hover']} overlayStyle={styles.overlay} overlay={overlay} {...props}>
      {children}
    </RCTooltip>
  );
};

export default Tooltip;
