import React, { useMemo, useCallback } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import { colorStyleProps, fontStyleProps } from '../../styles';

const defaultStyle = {
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: 10,
  borderColor: colorStyleProps.grayE0.color,
  padding: '8px 15px 8px 15px',
  ...fontStyleProps.bold16,
  // fix style issue on web
  lineHeight: '22px',
  color: colorStyleProps.tpBlack.color,
};

const Textarea = (props) => {
  const { field, error, style, onChangeTextLength, ...restProps } = props;

  const mergedStyle = useMemo(() => {
    const newStyle = { ...defaultStyle, ...style };
    if (error) {
      return { ...newStyle, borderColor: colorStyleProps.error.color };
    }
    return newStyle;
  }, [error, style]);

  const onChange = useCallback((e) => {
    const text = e.target.value;
    field.onChange(text);
    if (restProps.onChangeText) {
      restProps.onChangeText(text);
    }
    if (onChangeTextLength) {
      onChangeTextLength(text.length);
    }
  }, []);

  return <TextareaAutosize style={mergedStyle} {...restProps} value={field.value} onChange={onChange} />;
};

export default Textarea;
