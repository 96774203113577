import React from 'react';
import { Text, StyleSheet } from 'react-native';

import { coreOptionsMap } from '../../options';
import { colorStyleProps, fontStyleProps } from '../../styles';
import Capsule from './Capsule';

const styles = StyleSheet.create({
  text: {
    ...fontStyleProps.bold14,
  },
});

export const renderOption = ({ option, key, selectedSet, isMobileView }) => {
  const m = option.match(/^(.+)([ABC][123])$/);
  return (
    <Capsule
      key={option}
      style={{
        backgroundColor: colorStyleProps.tpWhite.color,
        opacity: !selectedSet || selectedSet.has(option) ? 1 : 0.2,
      }}
      isMobileView={isMobileView}>
      {m && m[1] && m[2] ? (
        <>
          <Text style={styles.text}>{m[1]}</Text>
          <Text style={[styles.text, { color: colorStyleProps[`category${coreOptionsMap[m[2]].category}`].color }]}>
            {coreOptionsMap[m[2]].value}
          </Text>
        </>
      ) : (
        <Text style={styles.text}>{option}</Text>
      )}
    </Capsule>
  );
};
