import * as Analytics from 'expo-firebase-analytics';
import React, { useCallback } from 'react';
import { Platform, Share, TouchableOpacity } from 'react-native';

import FbSvg from './fb.svg';

if (Platform.OS === 'web') {
  const body = document.getElementsByTagName('body')[0];

  const js = document.createElement('script');
  js.setAttribute('async', '');
  js.setAttribute('defer', '');
  js.setAttribute('crossorigin', 'anonymous');
  js.setAttribute('src', 'https://connect.facebook.net/zh_TW/sdk.js#xfbml=1&version=v11.0');
  js.setAttribute('nonce', 'D4UvWDlq');
  body.insertBefore(js, body.firstChilde);

  const root = document.createElement('div');
  root.setAttribute('id', 'fb-root');
  body.insertBefore(root, body.firstChilde);
}

const ShareToFb = ({ plan }) => {
  const url = `${process.env.HOST_URL}/plan/${plan.slug}`;
  const onShare = useCallback(async () => {
    try {
      await Share.share({
        message: url,
      });
      Analytics.logEvent('share_plan_to_fb', {
        slug: plan.slug,
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  if (Platform.OS !== 'web') {
    return (
      <TouchableOpacity onPress={onShare}>
        <FbSvg />
      </TouchableOpacity>
    );
  }
  return (
    <div className="fb-share-button" data-href={url} data-layout="button" data-size="small">
      <a
        target="_blank"
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}&amp;src=sdkpreparse&amp;display=pop`}
        className="fb-xfbml-parse-ignore">
        <FbSvg />
      </a>
    </div>
  );
};

export default ShareToFb;
