import React from 'react';
import { View } from 'react-native';

import Button from '../../components/Button';
import CustomInput from '../../components/CustomInput';
import HyperLink from '../../components/HyperLink';

const AttachmentLink = ({ editing, field, remove, index }) => (
  <View
    style={{
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 10,
      width: '100%',
      height: 50,
    }}>
    {editing ? (
      <CustomInput style={{ flexGrow: 1, marginRight: 10 }} field={field} />
    ) : (
      <HyperLink style={{ flexGrow: 1 }} href={field.value} />
    )}
    {remove && (
      <View style={{ flexGrow: 0 }}>
        <Button title="移除" type="red" negative onPress={() => remove(index)} />
      </View>
    )}
  </View>
);

export default AttachmentLink;
