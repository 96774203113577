import * as AppleAuthentication from 'expo-apple-authentication';
import * as Analytics from 'expo-firebase-analytics';
import * as WebBrowser from 'expo-web-browser';
import React, { useContext, useCallback } from 'react';

import { OverlayContext } from '../../../containers/OverlayManager';
import { UserContext } from '../../../containers/UserProvider';

WebBrowser.maybeCompleteAuthSession();

const WithAuthSessionSignIn = ({ children }) => {
  const { authInfo, setAuthInfo } = useContext(UserContext);
  const { pushOverlay } = useContext(OverlayContext);

  const login = useCallback(async () => {
    // if alread has authInfo, just clone a new one to trigger
    // UserProvider to login again
    if (authInfo) {
      await Analytics.logEvent('login', {
        provider: 'apple',
        desc: 'auth exists',
      });
      setAuthInfo({ ...authInfo });
    } else {
      try {
        const credential = await AppleAuthentication.signInAsync({
          requestedScopes: [
            AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
            AppleAuthentication.AppleAuthenticationScope.EMAIL,
          ],
        });

        if (credential && credential.realUserStatus === 1) {
          const { identityToken: token } = credential;
          const { authorizationCode: code } = credential;

          await Analytics.logEvent('login', {
            provider: 'apple',
            action: 'login',
          });
          setAuthInfo({
            provider: 'apple',
            token,
            code,
            revoke: () => {},
          });
        } else {
        }
      } catch ({ message }) {
        pushOverlay(({ Message }) => <Message message="無法登入，請確認輸入的帳號密碼是否正確" />);
        console.error(message);
      }
    }
  }, [authInfo]);

  return children({ login });
};

export default WithAuthSessionSignIn;
