import React, { forwardRef } from 'react';
import { TextInput, StyleSheet } from 'react-native';

import { colorStyleProps, fontStyleProps } from '../../styles';

const CustomInput = forwardRef((props, ref) => {
  const { field, error, style, onChangeTextLength, ...restProps } = props;

  return (
    <TextInput
      autoCapitalize="none"
      ref={ref}
      style={[styles.input, style, { borderColor: error ? colorStyleProps.error.color : colorStyleProps.grayE0.color }]}
      {...restProps}
      value={field.value}
      onChangeText={(text) => {
        field.onChange(text);
        if (restProps.onChangeText) {
          restProps.onChangeText(text);
        }
        if (onChangeTextLength) {
          onChangeTextLength(text.length);
        }
      }}
      blurOnSubmit={false}
    />
  );
});

const styles = StyleSheet.create({
  input: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 15,
    height: 40,
    ...fontStyleProps.bold16,
    color: colorStyleProps.tpBlack.color,
  },
});

export default CustomInput;
