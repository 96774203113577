import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { FlatList, ActivityIndicator, Text, StyleSheet, Platform } from 'react-native';

import { PullDownToRefreshContext } from '../../containers/PullDownToRefresh';
import { fontStyleProps, colorStyleProps } from '../../styles';
import useMountedFlag from '../useMountedFlag';

const Fetching = () => <ActivityIndicator size="large" color={colorStyleProps.tpGreen.color} />;

const styles = StyleSheet.create({
  emptyString: {
    textAlign: 'center',
    ...fontStyleProps.regular14,
    ...colorStyleProps.keyBlack,
  },
});

const DataList = ({
  onFetch,
  scrollableStyle,
  scrollableRef,
  emptyString,
  ListEmptyComponet,
  renderItem,
  onReceiveMethods,
  ...props
}) => {
  const { refreshing, refreshControl, onRefreshEnd } = useContext(PullDownToRefreshContext) || {};
  const isMountedRef = useMountedFlag();

  const [fetching, setFetching] = useState();
  const [list, setList] = useState({
    total: 0,
    next: 0,
    items: [],
  });

  const fetch = useCallback(
    async (offset, resetListBeforeFetching) => {
      try {
        setFetching(true);

        if (resetListBeforeFetching) {
          setList((old) => ({
            total: 0,
            next: 0,
            items: [],
          }));
        }

        const result = await onFetch(offset);

        if (!isMountedRef.current) {
          return;
        }

        if (result) {
          setList((old) => ({
            total: result.total,
            next: result.next,
            items: offset > 0 ? old.items.concat(result.items) : result.items,
          }));
        }
      } finally {
        setFetching(false);
        onRefreshEnd && onRefreshEnd();
      }
    },
    [onFetch, onRefreshEnd]
  );

  useEffect(() => {
    fetch(0, true);
  }, [onFetch]);

  useEffect(() => {
    if (refreshing) {
      fetch(0);
    }
  }, [refreshing]);

  useEffect(() => {
    onReceiveMethods && onReceiveMethods({ setList });
  }, []);

  const fetchMore = useCallback(() => {
    if (fetching || list.next <= 0 || list.next >= list.total) {
      return;
    }

    fetch(list.next);
  }, [list, fetching]);

  const renderItemWithRefetchFunction = useCallback(
    (content) => renderItem(content, () => fetch(0)),
    [renderItem, fetch]
  );

  const Empty = useMemo(() => <Text style={styles.emptyString}>{emptyString}</Text>, [emptyString]);

  return (
    <FlatList
      ref={(ref) => {
        if (ref) {
          const scrollRef = ref.getNativeScrollRef();
          if (scrollableRef) {
            scrollableRef.current = scrollRef;
          }
          if (Platform.OS === 'web' && scrollableStyle) {
            Object.keys(scrollableStyle).forEach((key) => {
              scrollRef.style[key] = scrollableStyle[key];
            });
          }
        } else {
          if (scrollableRef) {
            scrollableRef.current = null;
          }
        }
      }}
      refreshControl={refreshControl}
      ListFooterComponent={fetching ? Fetching : undefined}
      ListEmptyComponent={(!fetching && (emptyString ? Empty : ListEmptyComponet)) || undefined}
      data={list.items}
      renderItem={renderItemWithRefetchFunction}
      onEndReached={fetchMore}
      onEndReachedThreshold={0.9}
      keyExtractor={(item) => item.key}
      {...props}
    />
  );
};

export default DataList;
