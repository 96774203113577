import React from 'react';
import { ImageBackground } from 'react-native';

import Fallbackable from './Fallbackable';

const FallbackableImageBackground = ({ fallbackImage, ...props }) => (
  <Fallbackable>
    {({ isFailed, onLoad, onError }) =>
      isFailed || !props.source?.uri ? fallbackImage : <ImageBackground {...props} onLoad={onLoad} onError={onError} />
    }
  </Fallbackable>
);

FallbackableImageBackground.defaultProps = {
  fallbackImage: null,
};

export default FallbackableImageBackground;
