import React, { useCallback, useContext } from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';

import apis from '../../../apis';
import Button from '../../../components/Button';
import CardsView from '../../../components/CardsView';
import withColumnCalculator from '../../../components/CardsView/withColumnCalculator';
import DataList from '../../../components/DataList';
import GoBack from '../../../components/GoBack';
import PlanCard from '../../../components/PlanCard';
import { LoadingContext } from '../../../containers/LoadingProvider';
import { fontStyleProps, colorStyleProps } from '../../../styles';
import { renderOption } from '../../Plan/PhaseCoreOption';

const styles = StyleSheet.create({
  containner: {},
  header: {
    width: '100%',
    paddingHorizontal: `${(100 * 70) / 1440}vw`,

    backgroundColor: colorStyleProps.tpWhite.color,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: colorStyleProps.tpOG.color,

    display: 'flex',
    flexDirection: 'row',

    alignItems: 'flex-start',
    paddingVertical: 15,
  },
  headerContent: {
    width: '100%',
    minHeight: 40,
    paddingHorizontal: 80,

    display: 'flex',
    flexDirection: 'row',

    alignItems: 'flex-start',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  edit: {
    ...fontStyleProps.regular14,
    color: colorStyleProps.tpGreen.color,
  },
  main: {
    minWidth: 640,

    paddingHorizontal: `${(100 * 120) / 1440}vw`,
    paddingVertical: 40,
    backgroundColor: colorStyleProps.tpOG.color,
  },
  title: {
    ...fontStyleProps.bold36,
  },
  list: {
    marginTop: 50,
  },
});

const btnStyle = {
  main: {
    marginHorizontal: 5,
  },
  button: {
    paddingHorizontal: 40,
  },
};

const PAGE_SIZE = 24;

const getGrade = (phase) => {
  if (phase === 'U') {
    return '10';
  }
  if (phase === 'J') {
    return '7';
  }
  return '1';
};

const Step2 = withColumnCalculator(({ changeStep, numColumns, plan, setPlan, phase }) => {
  const { setIsLoading } = useContext(LoadingContext);

  const onFetchTemplate = useCallback(
    async (offset) => {
      try {
        const { data } = await apis.plans.getPlansMatch({
          offset,
          limit: PAGE_SIZE,
          subject: plan.subject,
          phase,
          cores: plan.coreList,
          ideas: plan.ideas,
          core_tags: plan.cores.map((c) => c.coreName),
          keywords: plan.keywords,
        });

        const newItems = [];
        if (data.plans.length) {
          newItems.push({
            key: `${offset}-${data.plans[0].slug}`,
            data: data.plans,
          });
        }

        return {
          total: data.total,
          next: offset + data.plans.length,
          items: newItems,
        };
      } catch (error) {
        // TODO handle error
        console.error(error);
      }
    },
    [plan, phase]
  );

  const onGoBack = useCallback(() => changeStep(1), []);
  const onEditDirectly = useCallback(() => {
    setPlan({
      ...plan,
      slug: null,
      appliedAuthorUid: null,
      appliedPlanId: null,
      cover: '',
      title: '',
      name: '',
      date: '',
      semester: '',
      designer: '',
      grade: getGrade(plan.phase),
      totalLectures: 0,
      // set default value from step1
      // idea: '',
      goal: '',
      summary: '',
      pictures: [],
      attachments: [],
      subPlans: [],
    });
    changeStep(3);
  }, []);

  const onSelectTemplate = useCallback(
    (card) => () => {
      setIsLoading(true);
      return apis.plan
        .getPlan(card.slug)
        .then(
          ({
            data: {
              plan: {
                authorUid: appliedAuthorUid,
                authorUsername: appliedAuthorName,
                id: appliedPlanId,
                cover,
                subject,
                title,
                name,
                date,
                semester,
                designer,
                grade,
                totalLectures,
                idea,
                coreList,
                cores,
                goal,
                summary,
                pictures,
                attachments,
                subPlans,
              },
            },
          }) => {
            setIsLoading(false);
            setPlan({
              ...plan,
              slug: null,
              appliedAuthorUid,
              appliedAuthorName,
              appliedPlanId,
              cover,
              subject,
              title,
              name,
              date,
              semester,
              designer,
              grade,
              totalLectures,
              idea,
              coreList,
              cores,
              goal,
              summary,
              pictures,
              attachments,
              subPlans,
            });
            changeStep(3);
          }
        )
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    },
    [plan]
  );

  const renderCard = useCallback(
    (card, fixedWith) => {
      const style = fixedWith ? { width: 356 } : undefined;
      return (
        <TouchableOpacity onPress={onSelectTemplate(card)}>
          <PlanCard key={card.id} plan={card} style={style} disableLink />
        </TouchableOpacity>
      );
    },
    [onSelectTemplate]
  );

  const renderItem = useCallback(
    ({ item }) => {
      return <CardsView cards={item.data} renderCard={renderCard} numColumns={numColumns} />;
    },
    [numColumns]
  );

  return (
    <View style={styles.containner}>
      <View style={styles.header}>
        <View style={styles.headerContent}>
          <View style={{ position: 'absolute', left: -12, top: 7 }}>
            <GoBack onPress={onGoBack} />
          </View>
          {plan.cores.map(({ coreName: option }) => (
            <View style={{ marginRight: 12, marginTop: 6, marginBottom: 6 }}>{renderOption({ option })}</View>
          ))}
        </View>
      </View>
      <View style={styles.main}>
        <Text style={styles.title}>選擇模板</Text>
        <View>
          <View style={{ position: 'absolute', right: 0, width: 250 }}>
            <Button title="創建空白教案" style={btnStyle} onPress={onEditDirectly} />
          </View>
        </View>
        <View style={styles.list}>
          <DataList renderItem={renderItem} onFetch={onFetchTemplate} />
        </View>
      </View>
    </View>
  );
});

export default Step2;
