export default {
  username: {
    required: { value: true, message: '必填' },
  },
  subject: {
    required: { value: true, message: '必填' },
  },
  bio: {
    required: { value: false },
  },
  school: {
    required: { value: true, message: '必填' },
  },
  email: {
    required: { value: true, message: '必填' },
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: '格式錯誤',
    },
  },
  ig: {
    required: { value: false },
    pattern: {
      value: /(?:https?:)?\/\/(?:www\.)?(?:instagram\.com|instagr\.am)\//,
      message: '至少包含 "instagram.com" 或 "instagr.am"',
    },
  },
  fb: {
    required: { value: false },
    pattern: {
      value: /(?:https?:)?\/\/(?:www\.)?(?:facebook|fb)\.com\//,
      message: '至少包含 "facebook.com" 或 "fb.com"',
    },
  },
  yt: {
    required: { value: false },
    pattern: {
      value: /(?:https?:)?\/\/(?:[A-z]+\.)?youtube.com\//,
      message: '至少包含 "youtube.com"',
    },
  },
};
