import { StyleSheet } from 'react-native';

import { colorStyleProps, fontStyleProps } from '../../../../styles';

export default StyleSheet.create({
  userProfileContainer: {
    backgroundColor: colorStyleProps.tpWhite.color,
    borderRadius: 10,
    paddingTop: 48,
  },
  mobileUserProfileContainer: {
    backgroundColor: colorStyleProps.tpWhite.color,
    margin: 10,
    borderRadius: 10,
  },
  avatarNameContainer: {
    width: '100%',
  },
  mobileAvatarNameContainer: {
    marginTop: -62,
    width: '100%',
  },

  formContainer: {
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: colorStyleProps.tpWhite.color,
    borderRadius: 10,
  },

  textInput: {},
  textArea: {
    height: 211,
  },
  socialGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
  },
  socialIcon: {
    flexGrow: 0,
    marginTop: 18,
    marginRight: 18,
  },
  socialTextInput: {
    width: '100%',
    flex: 1,
    flexGrow: 1,
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 30,
    paddingTop: 40,
  },

  bioCounter: {
    alignItems: 'flex-end',
  },
  bioCounterText: {
    ...fontStyleProps.bold14,
    ...colorStyleProps.tpGreen,
  },
});
