import React, { useMemo } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Platform } from 'react-native';

import SortArrow from '../images/SortArrow';
import { fontStyleProps } from '../styles';
import PopupMenu from './PopupMenu';

const sortOptions = [
  {
    value: 'subject',
    text: '科目',
  },
  {
    value: 'grade',
    text: '年級',
  },
  {
    value: 'created_at',
    text: '建立日期',
  },
  {
    value: 'updated_at',
    text: '更新日期',
  },
];

const styles = StyleSheet.create({
  main: {
    width: 110,
    display: 'flex',
  },
  upper: {
    alignSelf: 'flex-end',
  },
  title: {
    ...fontStyleProps.bold12,
  },
  lower: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  orderBy: {
    ...(Platform.OS === 'web' ? fontStyleProps.bold20 : fontStyleProps.bold16),
  },
  sortTouchArea: {
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const arrowStyles = {
  desc: {
    transform: Platform.OS === 'web' ? 'scaleY(1)' : [{ scaleY: 1 }],
  },
  asc: {
    transform: Platform.OS === 'web' ? 'scaleY(-1)' : [{ scaleY: -1 }],
  },
};

const Sorter = ({ orderBy, sort, onOrderBy, onToggleSort }) => {
  const orderByText = useMemo(() => {
    const found = sortOptions.find((so) => so.value === orderBy);
    return found ? found.text : '';
  }, [orderBy]);
  return (
    <View style={styles.main}>
      <View style={styles.upper}>
        <Text style={styles.title}>排序</Text>
      </View>
      <View style={styles.lower}>
        <TouchableOpacity onPress={onToggleSort} style={styles.sortTouchArea}>
          <SortArrow style={sort === 'desc' ? arrowStyles.desc : arrowStyles.asc} />
        </TouchableOpacity>
        <PopupMenu
          options={sortOptions.map((so) => ({
            onSelect: () => onOrderBy(so.value),
            text: so.text,
          }))}
          optionsProps={{ optionsContainerStyle: { minWidth: 90 } }}>
          <Text style={styles.orderBy}>{orderByText}</Text>
        </PopupMenu>
      </View>
    </View>
  );
};

export default Sorter;
