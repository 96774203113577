import { StyleSheet } from 'react-native';

import { fontStyleProps, colorStyleProps } from '../../styles';

const defaultProps = {
  tabs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -10,
  },
  container: {
    marginLeft: 10,
    borderRadius: 0,
  },
  tab: {},
  activeTab: {},
};

export const button = StyleSheet.create({
  tabs: defaultProps.tabs,
  container: defaultProps.container,
  tab: defaultProps.tab,
  activeTab: defaultProps.activeTab,
  button: {
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: 'transparent',
  },
  activeButton: {
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: colorStyleProps.keyBlack.color,
  },
  title: {
    ...fontStyleProps.bold16,
    color: colorStyleProps.keyBlack.color,
  },
  activeTitle: {
    ...fontStyleProps.bold16,
    color: colorStyleProps.tpWhite.color,
  },
});

export const underline = StyleSheet.create({
  tabs: defaultProps.tabs,
  container: {
    ...defaultProps.container,
    height: '100%',
  },
  tab: {
    ...defaultProps.tab,
    height: '100%',
  },
  activeTab: {
    ...defaultProps.activeTab,
    height: '100%',
  },
  button: {
    backgroundColor: 'transparent',
    padding: 0,
    height: '100%',
    borderRadius: 0,
    borderBottomWidth: 2,
    borderBottomColor: 'transparent',
  },
  activeButton: {
    backgroundColor: 'transparent',
    padding: 0,
    height: '100%',
    borderRadius: 0,
    borderBottomWidth: 2,
    borderBottomColor: colorStyleProps.keyBlack.color,
  },
  title: {
    ...fontStyleProps.bold16,
    color: colorStyleProps.keyBlack.color,
  },
  activeTitle: {
    ...fontStyleProps.bold16,
    color: colorStyleProps.keyBlack.color,
  },
});
