import React from 'react';
import { View, Text, ImageBackground } from 'react-native';

import CustomInput from '../../../components/CustomInput';
import ImageUploader from '../../../components/ImageUploader';
import Textarea from '../../../components/Textarea';
import { fontStyleProps, colorStyleProps } from '../../../styles';
import { styles, useCoverController } from './PlanForm';
import PlannerFormField from './PlannerFormField';
import rules from './rules';
import UploadSvg from './upload.svg';

const ArticleForm = ({ methods, plan, setPlan }) => {
  const { coverField, coverError, onCoverUpload } = useCoverController(methods.control, plan.cover);

  return (
    <View style={styles.main}>
      <View
        style={{
          width: `${(100 * 594) / 1440}vw`,
          height: `${(100 * 326) / 1440}vw`,
        }}>
        <ImageUploader
          objectType="plan_cover"
          onUpload={onCoverUpload}
          aspect={[1.822085889570552, 1]}
          resize={{ width: 594, height: 326 }}
          withTimestamp>
          <View
            style={{
              width: `${(100 * 594) / 1440}vw`,
              height: `${(100 * 326) / 1440}vw`,
              borderWidth: 1,
              borderStyle: 'dashed',
              borderColor: coverError ? colorStyleProps.error.color : colorStyleProps.grayBD.color,
              borderRadius: 10,
              backgroundColor: colorStyleProps.grayFD.color,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}>
            <View style={styles.coverImage}>
              <ImageBackground source={{ uri: coverField.value }} style={{ flex: 1, resizeMode: 'cover' }} />
            </View>
            <UploadSvg />
            <Text style={{ marginTop: 10, ...fontStyleProps.bold20, color: colorStyleProps.grayBD.color }}>
              新增文章圖片
            </Text>
            <Text style={{ ...fontStyleProps.regular20, color: colorStyleProps.grayBD.color }}>建議尺寸 594 x 326</Text>
            {coverError && <Text style={{ color: colorStyleProps.error.color }}>{coverError.message}</Text>}
          </View>
        </ImageUploader>
      </View>
      <PlannerFormField
        label="標題"
        name="title"
        defaultValue={plan.title}
        control={methods.control}
        rules={rules.title}
        style={styles.field}>
        {({ field, error }) => <CustomInput field={field} error={error} />}
      </PlannerFormField>
      <PlannerFormField
        label="內文"
        name="description"
        defaultValue={plan.description}
        control={methods.control}
        rules={rules.description}
        style={styles.field}>
        {({ field, error }) => <Textarea field={field} error={error} minRows={10} maxRows={50} />}
      </PlannerFormField>
    </View>
  );
};

export default ArticleForm;
