import axios from 'axios';

import * as aws from './aws';
import * as comments from './comments';
import * as notifications from './notifications';
import * as plan from './plan';
import * as plans from './plans';
import * as profiles from './profiles';
import * as user from './user';
import * as users from './users';

axios.defaults.baseURL = `${process.env.API_BASE_URL}`;

// mock apis
// axios.interceptors.request.use((config) => {
//   if (config.method === 'get' && config.url === '/api/plans/landing') {
//     config.adapter = async () => ({
//       data: [],
//       status: 200,
//     });
//     return config;
//   }
//   return config;
// }, Promise.reject);

export default {
  users,
  user,
  profiles,
  plan,
  plans,
  aws,
  notifications,
  comments,
};
