import React, { useCallback, useContext } from 'react';
import { StyleSheet, View, Text } from 'react-native';

import Button from '../../../components/Button';
import { UserContext } from '../../../containers/UserProvider';
import { fontStyleProps, colorStyleProps } from '../../../styles';
import Crown from './crown.svg';

const styles = StyleSheet.create({
  main: {
    minWidth: 640,
    height: '100%',
    backgroundColor: colorStyleProps.tpOG.color,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  half: {
    width: '50%',
    height: '100%',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    ...fontStyleProps.bold56,
  },
  subtitle: {
    marginTop: 10,
    ...fontStyleProps.regular18,
  },
  vip: {
    marginTop: 10,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  vipSubtitle: {
    marginLeft: 10,
    ...fontStyleProps.regular18,
  },
  button: {
    marginTop: 70,
  },

  separator: {
    height: '100%',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  line: {
    width: 1,
    height: `${(100 * 256) / 1440}vw`,
    backgroundColor: colorStyleProps.grayBD.color,
    marginVertical: 20,
  },
  or: {
    ...fontStyleProps.regular18,
    ...colorStyleProps.grayBD,
  },
});

const btnStyle = {
  button: {
    width: 290,
  },
};

const Step0 = ({ changeStep, setPlan }) => {
  const { userProfile } = useContext(UserContext);

  const isVIP = userProfile.roleId === 1;

  const onCreatePlan = useCallback(() => {
    setPlan((oldValue) => ({ ...oldValue, type: 0 }));
    changeStep(1);
  }, []);

  const onCreateArticle = useCallback(() => {
    setPlan((oldValue) => ({ ...oldValue, type: 100 }));
    changeStep(3);
  }, []);

  return (
    <View style={styles.main}>
      <View style={styles.half}>
        <Text style={styles.title}>建立教案</Text>
        <Text style={styles.subtitle}>使用教案編輯器建立專屬教案</Text>
        <View style={styles.button}>
          <Button title="選擇模板" style={btnStyle} onPress={onCreatePlan} />
        </View>
      </View>
      <View style={styles.separator}>
        <View style={styles.line} />
        <Text style={styles.or}>或</Text>
        <View style={styles.line} />
      </View>
      <View style={styles.half}>
        <Text style={styles.title}>建立文章</Text>
        <View style={styles.vip}>
          <Crown width={18} height={18} />
          <Text style={styles.vipSubtitle}>VIP 會員限定</Text>
        </View>
        <View style={styles.button}>
          {isVIP ? (
            <Button type="gold" title="開始建立" style={btnStyle} onPress={onCreateArticle} />
          ) : (
            <Button type="gray" disabled title="請聯繫系統管理員" style={btnStyle} />
          )}
        </View>
      </View>
    </View>
  );
};

export default Step0;
