import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';

import apis from '../../apis/apis';
import Cross from '../../images/Cross';
import { useMobileView } from '../../pages/Root/useMobileView';
import { colorStyleProps, fontStyleProps } from '../../styles';
import Button from '../Button/Button';
import CustomInput from '../CustomInput/CustomInput';
import DataList from '../DataList/DataList';
import Field from '../Form/Field';
import ButtonAwait from '../PressAwait/ButtonAwait';
import CommentItem from './CommentItem';

const COMMENT_SIZE = 20;

const styles = StyleSheet.create({
  commentPanel: {
    backgroundColor: colorStyleProps.tpWhite.color,
    width: 434,
    height: '100%',
    position: 'absolute',
    right: 0,
    paddingVertical: 28,
    paddingHorizontal: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    borderWidth: 1,
    borderColor: colorStyleProps.grayE0.color,
  },
  mobilePanel: {
    backgroundColor: colorStyleProps.tpWhite.color,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    paddingHorizontal: 25,
  },
  textBtnSection: {
    display: 'flex',
    width: '100%',
  },
  textInput: {
    width: '100%',
    height: 200,
    borderRadius: 4,
    ...fontStyleProps.regular14,
  },
  mobileTextInput: {
    width: '100%',
    height: 160,
    ...fontStyleProps.regular14,
    borderRadius: 4,
    marginTop: 20,
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  labelText: {
    ...colorStyleProps.keyBlack,
    ...fontStyleProps.bold18,
    marginBottom: 14,
  },
  crossIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  body: {
    flexGrow: 1,
    flexShrink: 1,
    width: '100%',
  },
});

const btnStyle = {
  main: {
    marginHorizontal: 5,
  },
  negativeMain: {
    marginHorizontal: 5,
  },
  button: {
    paddingHorizontal: 30,
  },
  title: {
    ...fontStyleProps.regular14,
  },
  negativeButton: {
    borderWidth: 0,
  },
  negativeTitle: {
    ...colorStyleProps.tpBlack,
    ...fontStyleProps.regular14,
  },
};

const getPlanComment = (offset, limit, slug) => {
  try {
    return apis.comments.getComments(slug, { offset, limit });
  } catch (error) {
    console.log(error);
  }
};

const postComment = (slug, data) => {
  try {
    return apis.comments.postComments(slug, data);
  } catch (error) {
    console.log(error);
  }
};

const deleteComment = (slug, comment_id) => {
  try {
    return apis.comments.deleteComments(slug, comment_id);
  } catch (error) {
    console.error(error);
  }
};

const CommentList = ({ slug, closeModal, planAuthor }) => {
  const methods = useForm({ mode: 'all', shouldUnregister: true });
  const [currentCommentCount, setCommentCount] = useState();
  const [isMobileView] = useMobileView();
  const dataListMethodsRef = useRef();

  const onFetch = useCallback(
    async (offset) => {
      try {
        const {
          data: { comments, total },
        } = await getPlanComment(offset, COMMENT_SIZE, slug);
        setCommentCount(total);

        if (!comments) {
          return null;
        }
        const newItems = [];

        if (comments.length) {
          newItems.push({
            key: `${offset}-${comments[0].id}`,
            data: comments,
          });
        }
        return {
          total,
          next: offset + comments.length,
          items: newItems,
        };
      } catch (error) {
        console.log(error);
      }
    },
    [getPlanComment]
  );

  const onSendComment = useCallback(async (comment, offset) => {
    try {
      const savingValues = {};
      Object.keys(comment).forEach((name) => {
        const value = comment[name];
        savingValues[name] = value.length ? value : undefined;
      });
      await postComment(slug, { comment: { ...savingValues } });
      setCommentCount((oldValue) => oldValue + 1);

      const {
        data: { comments, total },
      } = await getPlanComment(0, 1, slug);
      dataListMethodsRef.current.setList((oldValue) => ({
        ...oldValue,
        items: [{ key: comments.id, data: comments }, ...oldValue.items],
        total,
      }));
    } catch (error) {
      console.error(error);
    }
  }, []);

  const onDeleteComment = useCallback(async (commentId) => {
    try {
      await deleteComment(slug, commentId);
      setCommentCount((oldValue) => oldValue - 1);

      dataListMethodsRef.current.setList((oldValue) => ({
        ...oldValue,
        items: oldValue.items.map((item) => ({
          ...item,
          data: item.data.filter((comment) => comment.id !== commentId),
        })),
        total: oldValue.total - 1,
      }));
    } catch (error) {
      console.error(error);
    }
  }, []);

  const CommentSection = ({ comments }) => {
    return (
      <>
        {comments.data.map((ct, idx) => (
          <View key={idx}>
            <View>
              <CommentItem comment={ct} slug={slug} planAuthor={planAuthor} onDeleteComment={onDeleteComment} />
            </View>
          </View>
        ))}
      </>
    );
  };

  const renderItem = useCallback(({ item }) => {
    return <CommentSection comments={item} />;
  }, []);

  const onReceiveDataListMethods = useCallback((methods) => {
    dataListMethodsRef.current = methods;
  }, []);

  const dataList = useMemo(
    () => <DataList renderItem={renderItem} onFetch={onFetch} onReceiveMethods={onReceiveDataListMethods} />,
    [renderItem, onFetch, onReceiveDataListMethods]
  );

  useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      methods.reset({ body: '' });
    }
  }, [methods.formState]);

  return (
    <View style={isMobileView ? styles.mobilePanel : styles.commentPanel}>
      <FormProvider {...methods}>
        <View style={styles.textBtnSection}>
          {!isMobileView && (
            <>
              <Text style={styles.labelText}>{`留言 (${currentCommentCount})`}</Text>
              <View style={styles.crossIcon}>
                <TouchableOpacity onPress={closeModal}>
                  <Cross type="dark" size={12} />
                </TouchableOpacity>
              </View>
            </>
          )}
          <Field name="body" control={methods.control}>
            {({ field, error }) => (
              <CustomInput
                field={field}
                error={error}
                style={isMobileView ? styles.mobileTextInput : styles.textInput}
                maxLength={700}
                placeholder="想留些什麼?"
                placeholderTextColor={colorStyleProps.grayBD.color}
                numberOfLines={10}
                multiline
                textAlignVertical="top"
              />
            )}
          </Field>
          <View style={styles.buttonGroup}>
            <Button negative title="取消" style={btnStyle} onPress={closeModal} />
            <ButtonAwait
              title="送出"
              style={btnStyle}
              onPress={async () => {
                await methods.handleSubmit(onSendComment)();
              }}
            />
          </View>
        </View>
        <View style={styles.body}>{dataList}</View>
      </FormProvider>
    </View>
  );
};

export default CommentList;
