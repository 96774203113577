import React, { useCallback, useState, useContext } from 'react';
import { View, StyleSheet, Text } from 'react-native';

import Button from '../../../components/Button';
import { useHistoryWithSpec } from '../../../components/UniversalRouter';
import { UserContext } from '../../../containers/UserProvider';
import Plus from '../../../images/Plus';
import { fontStyleProps, colorStyleProps } from '../../../styles';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    overflow: 'hidden',

    borderRadius: 10,
    borderColor: colorStyleProps.grayBD.color,
    borderWidth: 2,
    borderStyle: 'dashed',

    margin: 2,
    position: 'relative',

    paddingTop: '81.2%',
  },
  content: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    zIndex: -1,
  },
  text: {
    marginTop: 20,
    ...fontStyleProps.bold24,
    ...colorStyleProps.grayBD,
  },
  buttons: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
});

const PlanCardCreation = () => {
  const { userProfile } = useContext(UserContext);
  const [showButtons, setShowButtons] = useState(false);
  const history = useHistoryWithSpec();

  const onMouseOver = useCallback(() => {
    setShowButtons(true);
  }, []);

  const onMouseOut = useCallback(() => {
    setShowButtons(false);
  }, []);

  const onCreatePlan = useCallback(() => {
    history.push('/planner');
  }, []);

  const isVIP = userProfile.roleId === 1;

  return (
    <View style={styles.container} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      <View style={styles.content}>
        <Plus width={40} height={40} fill={colorStyleProps.grayBD.color} />
        <Text style={styles.text}>新增</Text>
        <View style={[styles.buttons, { opacity: showButtons ? 1 : 0 }]}>
          <Button title="建立教案" type="green" onPress={onCreatePlan} />
          <View style={{ marginTop: 12 }}>
            {isVIP ? (
              <Button title="建立文章" type="gold" onPress={onCreatePlan} />
            ) : (
              <Button title="建立文章" type="gray" disabled onPress={onCreatePlan} />
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default PlanCardCreation;
