import React, { useCallback, useMemo, useState, useRef } from 'react';
import { View, StyleSheet } from 'react-native';

import apis from '../../../apis/apis';
import CardsView from '../../../components/CardsView/CardsView';
import withColumnCalculator from '../../../components/CardsView/withColumnCalculator';
import DataList from '../../../components/DataList/DataList';
import PlanCard from '../../../components/PlanCard/PlanCard';
import Sorter from '../../../components/Sorter';
import Tabs from '../../../components/Tabs';
import { colorStyleProps } from '../../../styles';
import PlanCardCreation from './PlanCardCreation';
import PlanCardViewOrEditOrDelete from './PlanCardViewOrEditOrDelete';

const PAGE_SIZE = 24;

const styles = StyleSheet.create({
  main: {
    flexGrow: 0,
    flexShrink: 1,
    backgroundColor: colorStyleProps.tpOG.color,
    width: '100%',
    height: '100%',

    marginTop: 23,
  },
  header: {
    width: '100%',
    marginBottom: 30,
    paddingRight: '4.86vw',

    display: 'flex',
    flexDirection: 'row',
  },
  sorter: {
    flexGrow: 1,

    display: 'flex',
    flexDirection: 'row-reverse',
  },
});

const tabsStyle = {
  button: {
    default: {
      paddingVertical: 10,
      paddingHorizontal: 30,
    },
    active: {
      paddingVertical: 10,
      paddingHorizontal: 30,
    },
  },
};

const getPlanList = (offset, category, orderBy, sort, authorUid) => {
  if (category === 'created') {
    return apis.plans.getPlans({
      authorUid,
      type: '201',
      orderBy,
      sort,
      offset,
      limit: offset === 0 ? PAGE_SIZE - 1 : PAGE_SIZE,
    });
  }
  if (category === 'favorite') {
    return apis.plans.getPlans({
      favorited: authorUid,
      orderBy,
      sort,
      offset,
      limit: PAGE_SIZE,
    });
  }
  if (category === 'draft') {
    return apis.plans.getPlans({
      authorUid,
      type: '200',
      orderBy,
      sort,
      offset,
      limit: PAGE_SIZE,
    });
  }
  if (category === 'plan') {
    return apis.plans.getPlans({
      authorUid,
      type: '1',
      orderBy,
      sort,
      offset,
      limit: PAGE_SIZE,
    });
  }
  if (category === 'article') {
    return apis.plans.getPlans({
      authorUid,
      type: '101',
      orderBy,
      sort,
      offset,
      limit: PAGE_SIZE,
    });
  }
  return {};
};

const renderCard = (card, fixedWith) => {
  const style = fixedWith ? { width: 356 } : undefined;
  return <PlanCard key={card.id} plan={card} style={style} />;
};

const renderCardWithDeletion = (onDelete) => (card, fixedWith) => {
  // creation card
  if (Array.isArray(card) && !card.length) {
    return <PlanCardCreation />;
  }
  const style = fixedWith ? { width: 356 } : undefined;
  return (
    <PlanCardViewOrEditOrDelete plan={card} onDelete={onDelete}>
      <PlanCard key={card.id} plan={card} style={style} />
    </PlanCardViewOrEditOrDelete>
  );
};

const PlanCardsSection = withColumnCalculator(({ numColumns, userProfile, tabs }) => {
  const [category, setCategory] = useState(tabs[0].value);
  const [orderBy, setOrderBy] = useState('updated_at');
  const [sort, setSort] = useState('desc');
  const dataListMethodsRef = useRef();

  const onCategoryChange = useCallback((tab) => {
    setCategory(tab);
  }, []);

  const onFetch = useCallback(
    async (offset) => {
      try {
        if (userProfile.blocked) {
          return null;
        }

        if (!category || !orderBy || !sort) {
          return null;
        }

        const { data } = await getPlanList(offset, category, orderBy, sort, userProfile.uid);
        if (!data) {
          return null;
        }

        const newItems = [];
        let plans;
        if (category === 'created' && offset === 0) {
          // empty array for creation card
          plans = [[], ...data.plans];
        } else {
          plans = data.plans;
        }
        if (plans.length) {
          newItems.push({
            key: `${offset}-${data.plans[0] ? data.plans[0].slug : 'create-buttons'}`,
            data: plans,
          });
        }
        return {
          total: data.total,
          next: offset + data.plans.length,
          items: newItems,
        };
      } catch (error) {
        console.log(error);
      }
    },
    [userProfile.uid, userProfile.blocked, category, orderBy, sort]
  );

  const renderItem = useCallback(
    ({ item }, refetch) => {
      const onDelete = (slug) => {
        if (dataListMethodsRef.current && dataListMethodsRef.current.setList) {
          dataListMethodsRef.current.setList((oldValue) => ({
            ...oldValue,
            items: oldValue.items.map((item) => ({
              ...item,
              data: item.data.filter((plan) => plan.slug !== slug),
            })),
            total: oldValue.total - 1,
          }));
        } else {
          refetch();
        }
      };
      return (
        <CardsView
          cards={item.data}
          renderCard={category === 'created' || category === 'draft' ? renderCardWithDeletion(onDelete) : renderCard}
          numColumns={Math.max(2, numColumns - 1)}
        />
      );
    },
    [numColumns, category]
  );

  const emptyString = useMemo(() => {
    const found = tabs.find((tab) => tab.value === category);
    return found ? found.emptyString : undefined;
  }, [tabs, category]);

  const onOrderBy = useCallback((ob) => setOrderBy(ob), []);
  const onToggleSort = useCallback(() => setSort((old) => (old === 'desc' ? 'asc' : 'desc')), []);

  const onReceiveDataListMethods = useCallback((methods) => {
    dataListMethodsRef.current = methods;
  }, []);

  return userProfile.blocked ? null : (
    <View style={styles.main}>
      <View style={styles.header}>
        <Tabs tabs={tabs} defaultCurrentTab={category} style={tabsStyle} onTabChange={onCategoryChange} />
        <View style={styles.sorter}>
          <Sorter orderBy={orderBy} sort={sort} onOrderBy={onOrderBy} onToggleSort={onToggleSort} />
        </View>
      </View>
      <DataList
        scrollableStyle={{ paddingRight: '4.86vw' }}
        renderItem={renderItem}
        onFetch={onFetch}
        emptyString={emptyString}
        onReceiveMethods={onReceiveDataListMethods}
      />
    </View>
  );
});

export default PlanCardsSection;
