import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Platform } from 'react-native';

import apis from '../../apis';
import { useParams, useHistoryWithSpec } from '../../components/UniversalRouter';
import { LoadingContext } from '../../containers/LoadingProvider';
import NotFound from '../../pages/NotFound';

const defaultPlan = {
  slug: null,
  cover: '',
  subject: 0,
  title: '',
  name: '',
  date: '',
  semester: '',
  designer: '',
  grade: 1,
  totalLectures: 1,
  idea: '',
  coreList: [],
  cores: [],
  goal: '',
  ideas: [],
  keywords: [],
  summary: '',
  pictures: [],
  attachments: [],
  subPlans: [],
};

const loadPlan = (Comp) => (props) => {
  const { slug } = useParams();
  const history = useHistoryWithSpec();
  const { setIsLoading } = useContext(LoadingContext);

  const applying = history.location.query.applying;
  const [plan, setPlan] = useState();
  useEffect(() => {
    const planSlug = applying || slug;
    if (planSlug) {
      setIsLoading(true);
      apis.plan
        .getPlan(planSlug)
        .then(({ data: { plan } }) => {
          if (applying) {
            const {
              authorUid: appliedAuthorUid,
              authorUsername: appliedAuthorName,
              id: appliedPlanId,
              cover,
              subject,
              title,
              name,
              date,
              semester,
              designer,
              grade,
              totalLectures,
              idea,
              coreList,
              cores,
              goal,
              summary,
              pictures,
              attachments,
              subPlans,
            } = plan;
            setPlan({
              ...plan,
              slug: null,
              appliedAuthorUid,
              appliedAuthorName,
              appliedPlanId,
              cover,
              subject,
              title,
              name,
              date,
              semester,
              designer,
              grade,
              totalLectures,
              idea,
              coreList,
              cores,
              goal,
              summary,
              pictures,
              attachments,
              subPlans,
              type: 0,
            });
          } else {
            // not draft
            if (
              (plan.type !== 0 && plan.type !== 100 && !history.location.pathname.startsWith('/plan/')) ||
              (plan.type !== 1 && plan.type !== 101 && !history.location.pathname.startsWith('/planner/'))
            ) {
              setPlan(null);
            } else {
              setPlan(plan);
            }
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setPlan(null);
          setIsLoading(false);
        });
    } else {
      setPlan(defaultPlan);
    }
  }, [slug, applying, history]);

  useEffect(() => {
    if (Platform.OS === 'web') {
      if (plan) {
        window.document.title = `Teach Planner - ${plan.title}`;
      } else {
        window.document.title = 'Teach Planner';
      }
    }
    return () => {
      if (Platform.OS === 'web') {
        window.document.title = 'Teach Planner';
      }
    };
  }, [plan]);

  const reloadPlan = useCallback(async () => {
    const { data } = await apis.plan.getPlan(slug);
    setPlan(data.plan);
  }, [slug]);

  if (plan === null) {
    const action = history.location.pathname.startsWith('/plan/')
      ? '檢視'
      : history.location.pathname.startsWith('/planner/')
      ? '編輯'
      : null;
    return <NotFound message={action ? `您所${action}的教案或文章不存在` : undefined} />;
  }

  return !!plan && <Comp plan={plan} setPlan={setPlan} reloadPlan={reloadPlan} applying={applying} {...props} />;
};

export default loadPlan;
