import React from 'react';
import { StyleSheet } from 'react-native';

import Field from '../../../components/Form/Field';
import { fontStyleProps, colorStyleProps } from '../../../styles';

const styles = StyleSheet.create({
  labelText: {
    ...fontStyleProps.bold20,
    color: colorStyleProps.pureBlack.color,
    marginBottom: 5,
  },
});

const PlannerFormField = (props) => <Field {...props} labelTextStyle={styles.labelText} />;

export default PlannerFormField;
