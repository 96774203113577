import React, { useContext, useCallback, useState } from 'react';
import { View, ImageBackground, Text } from 'react-native';

import { UserContext } from '../../../containers/UserProvider/UserProvider';
import UserProfile from '../UserProfile';
import PlanCardsSection from './PlanCardsSection';
import styles from './styles';

const hostTabs = [
  {
    value: 'created',
    label: '建立',
    emptyString: '尚未發佈任何作品',
  },
  {
    value: 'favorite',
    label: '收藏',
    emptyString: '尚未收藏任何作品',
  },
  {
    value: 'draft',
    label: '草稿',
    emptyString: '尚未編輯任何作品',
  },
];

const guestTabs = [
  {
    value: 'plan',
    label: '教案',
    emptyString: '尚未發佈任何教案',
  },
  {
    value: 'article',
    label: '文章',
    emptyString: '尚未發佈任何文章',
  },
];

const UserWeb = ({
  userProfile,
  onScroll,
  onFollowChange,
  isEditable,
  onSaveUserProfile,
  onAvatarUpload,
  coverImageUploader,
  onBlockUser,
  onUnblockUser,
}) => {
  const { userProfile: loginUserProfile } = useContext(UserContext);
  const isHost = loginUserProfile.uid === userProfile.uid;

  const tabs = isHost ? hostTabs : guestTabs;

  const [bodyStyle, setBodyStyle] = useState([styles.body]);
  const onProfileContainerLayout = useCallback((e) => {
    const height = e.nativeEvent.layout.height;
    setBodyStyle((old) => [styles.body, { minHeight: height }]);
  }, []);

  return (
    <View style={styles.mainContainer}>
      <View style={styles.coverContainer}>
        <ImageBackground
          source={{
            uri: userProfile.cover,
          }}
          style={{ flex: 1, resizeMode: 'cover' }}
        />
        {isEditable && (
          <View style={userProfile.cover ? styles.cornerCoverUploader : styles.coverUploader}>
            <View style={styles.coverUploaderIcon}>{coverImageUploader}</View>
            {!userProfile.cover && (
              <View>
                <Text style={styles.coverUploaderTextTitle}>新增橫幅影像</Text>
                <Text style={styles.coverUploaderTextSubtitle}>最佳尺寸 1440 x 258</Text>
              </View>
            )}
          </View>
        )}
      </View>
      <View style={bodyStyle}>
        <View style={styles.profileContainer} onLayout={onProfileContainerLayout}>
          <UserProfile
            userProfile={userProfile}
            onScroll={onScroll}
            onFollowChange={onFollowChange}
            isEditable={isEditable}
            onSaveUserProfile={onSaveUserProfile}
            onAvatarUpload={onAvatarUpload}
            onBlockUser={onBlockUser}
            onUnblockUser={onUnblockUser}
          />
        </View>
        <View style={styles.rightSection}>
          <PlanCardsSection key={userProfile.uid} userProfile={userProfile} tabs={tabs} />
        </View>
      </View>
    </View>
  );
};

export default UserWeb;
