import * as Analytics from 'expo-firebase-analytics';
import React, { useEffect, useState, Fragment } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';

import apis from '../../apis';
import AutoSizeImage from '../../components/AutoSizeImage';
import Info from '../../components/Info';
import * as Linking from '../../components/Linking';
import { grade } from '../../options';
import { fontStyleProps, colorStyleProps } from '../../styles';
import AttachmentLink from './AttachmentLink';
import Author from './Author';
import { renderOption } from './PhaseCoreOption';
import PictureLink from './PictureLink';

const getUserProfile = async (uid) => {
  try {
    const { data } = await apis.profiles.getProfile(uid);
    return data.profile;
  } catch (error) {
    console.log(error);
  }
  return null;
};

const defaultStyles = StyleSheet.create({
  container: {},
  header: {},

  body: {
    marginTop: 15,
    paddingVertical: 60,
    paddingHorizontal: 75,
    backgroundColor: colorStyleProps.tpWhite.color,
    borderRadius: 20,
  },
  fieldRow: {
    marginTop: 50,
    display: 'flex',
    flexDirection: 'row',
  },
  fieldColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    ...fontStyleProps.bold36,
    color: colorStyleProps.keyBlack.color,
  },
  fieldLabel: {
    marginBottom: 15,
    ...fontStyleProps.bold20,
    color: colorStyleProps.pureBlack.color,
  },
  fieldContent: {
    ...fontStyleProps.regular16,
    color: colorStyleProps.keyBlack.color,
  },
  fieldContentWithBorder: {
    ...fontStyleProps.regular16,
    color: colorStyleProps.keyBlack.color,
    borderWidth: 1,
    borderColor: colorStyleProps.grayBD.color,
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  subPlan: {
    ...fontStyleProps.bold36,
    color: colorStyleProps.keyBlack.color,
    marginTop: 70,
    marginBottom: 50,
  },
});

const mobileStyles = StyleSheet.create({
  container: {},
  header: {},

  body: {
    marginTop: 12,
    paddingVertical: 20,
    paddingHorizontal: 15,
    backgroundColor: colorStyleProps.tpWhite.color,
    borderRadius: 20,
  },
  fieldRow: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'row',
  },
  fieldColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    ...fontStyleProps.bold18,
    color: colorStyleProps.keyBlack.color,
  },
  fieldLabel: {
    marginBottom: 14,
    ...fontStyleProps.bold14,
    color: colorStyleProps.tpBlack.color,
  },
  fieldContent: {
    ...fontStyleProps.bold16,
    color: colorStyleProps.keyBlack.color,
  },
  fieldContentWithBorder: {
    ...fontStyleProps.regular16,
    color: colorStyleProps.keyBlack.color,
    borderWidth: 1,
    borderColor: colorStyleProps.grayBD.color,
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  subPlan: {
    ...fontStyleProps.bold18,
    color: colorStyleProps.keyBlack.color,
    marginTop: 30,
    marginBottom: 10,
  },
});

const PlanDetail = ({ plan, isMobileView }) => {
  const [author, setAuthor] = useState();

  const isPlan = plan.type === 0 || plan.type === 1;

  useEffect(() => {
    (async () => {
      await getUserProfile(plan.authorUid).then(setAuthor);
      Analytics.logEvent('view_plan', {
        slug: plan.slug,
      });
    })();
  }, [plan]);

  const styles = isMobileView ? mobileStyles : defaultStyles;

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Author plan={plan} author={author} isMobileView={isMobileView} />
      </View>

      <View style={styles.body}>
        <AutoSizeImage fit style={{ maxWidth: '100%', marginBottom: 25, borderRadius: 10 }} uri={plan.cover} />

        <Text style={styles.title}>{plan.title}</Text>

        {!isPlan ? (
          <View style={{ marginTop: 25, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text style={[styles.fieldContent, { width: '100%' }]}>{plan.description}</Text>
          </View>
        ) : (
          <>
            <View style={styles.fieldRow}>
              <View style={styles.fieldColumn}>
                <Text style={styles.fieldLabel}>課程名稱</Text>
                <Text style={[styles.fieldContent, { width: '100%' }]}>{plan.name}</Text>
              </View>
            </View>

            <View style={styles.fieldRow}>
              <View style={[{ width: '50%' }, styles.fieldColumn]}>
                <Text style={styles.fieldLabel}>日期</Text>
                <Text style={[styles.fieldContent, { width: '100%' }]}>{plan.date}</Text>
              </View>
              <View style={[{ width: '50%' }, styles.fieldColumn]}>
                <Text style={styles.fieldLabel}>學期</Text>
                <Text style={[styles.fieldContent, { width: '100%' }]}>{plan.semester}</Text>
              </View>
            </View>

            {plan.appliedAuthorUid ? (
              <View style={styles.fieldRow}>
                <View style={[{ width: '50%' }, styles.fieldColumn]}>
                  <Text style={styles.fieldLabel}>設計者</Text>
                  <Text style={[styles.fieldContent, { width: '100%' }]}>{plan.designer}</Text>
                </View>
                <View style={[{ width: '50%' }, styles.fieldColumn]}>
                  <Text style={styles.fieldLabel}>原作者</Text>
                  <Text style={[styles.fieldContent, { width: '100%' }]}>{plan.appliedAuthorName}</Text>
                </View>
              </View>
            ) : (
              <View style={styles.fieldRow}>
                <View style={styles.fieldColumn}>
                  <Text style={styles.fieldLabel}>設計者</Text>
                  <Text style={[styles.fieldContent, { width: '100%' }]}>{plan.designer}</Text>
                </View>
              </View>
            )}

            <View style={styles.fieldRow}>
              <View style={[{ width: '50%' }, styles.fieldColumn]}>
                <Text style={styles.fieldLabel}>年級</Text>
                <Text style={[styles.fieldContent, { width: '100%' }]}>{grade[plan.grade]}</Text>
              </View>
              <View style={[{ width: '50%' }, styles.fieldColumn]}>
                <Text style={styles.fieldLabel}>節數</Text>
                <Text style={[styles.fieldContent, { width: '100%' }]}>{plan.totalLectures}</Text>
              </View>
            </View>

            <View style={styles.fieldRow}>
              <View style={[{ width: '100%' }, styles.fieldColumn]}>
                <View style={styles.flexRowCenter}>
                  <Text style={styles.fieldLabel}>設計理念</Text>
                  <View style={{ marginLeft: 10, marginBottom: 15 }}>
                    <TouchableOpacity
                      onPress={() =>
                        Linking.openURL(
                          'https://docs.google.com/document/d/1sHU8wyRz7iyfEwSKrW68BhRTERTgwQMFUzK7wvnpXmE/edit?usp=sharing',
                          '_blank'
                        )
                      }>
                      <Info text="點選圖示開啟說明" />
                    </TouchableOpacity>
                  </View>
                </View>
                <Text style={[styles.fieldContentWithBorder, { width: '100%' }]}>{plan.idea}</Text>
              </View>
            </View>

            <View style={styles.fieldRow}>
              <View style={[{ width: '100%' }, styles.fieldColumn]}>
                <View style={styles.flexRowCenter}>
                  <Text style={styles.fieldLabel}>本教育階段核心素養</Text>
                  <View style={{ marginLeft: 10, marginBottom: 15 }}>
                    <TouchableOpacity
                      onPress={() =>
                        Linking.openURL(
                          'https://docs.google.com/document/d/1iC5GRRi1c5gmK1bSqdUZ13YOo_cJZftjp-B02U9bMzc/edit?usp=sharing',
                          '_blank'
                        )
                      }>
                      <Info text="點選圖示開啟說明" />
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={[{ width: '100%' }, styles.fieldColumn]}>
                  {plan.cores.map((core) => (
                    <View
                      key={core.coreName}
                      style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <View style={{ marginRight: 12 }}>{renderOption({ option: core.coreName, isMobileView })}</View>
                      <Text style={[{ flexGrow: 1, flexShrink: 1 }, styles.fieldContentWithBorder]}>{core.body}</Text>
                    </View>
                  ))}
                </View>
              </View>
            </View>

            <View style={styles.fieldRow}>
              <View style={[{ width: '100%' }, styles.fieldColumn]}>
                <View style={styles.flexRowCenter}>
                  <Text style={styles.fieldLabel}>課程目標</Text>
                  <View style={{ marginLeft: 10, marginBottom: 15 }}>
                    <TouchableOpacity
                      onPress={() =>
                        Linking.openURL(
                          'https://docs.google.com/document/d/1V33ho65_sc1CXO9dEGyF5fWL4p4iwOxJE940-7yGLGA/edit?usp=sharing',
                          '_blank'
                        )
                      }>
                      <Info text="點選圖示開啟說明" />
                    </TouchableOpacity>
                  </View>
                </View>
                <Text style={styles.fieldContentWithBorder}>{plan.goal}</Text>
              </View>
            </View>

            <View style={styles.fieldRow}>
              <View style={[{ width: '100%' }, styles.fieldColumn]}>
                <Text style={styles.fieldLabel}>總結性表現任務</Text>
                <Text style={styles.fieldContentWithBorder}>{plan.summary}</Text>
              </View>
            </View>

            <View style={styles.fieldRow}>
              <View style={[{ width: '100%' }, styles.fieldColumn]}>
                <Text style={styles.fieldLabel}>架構脈絡</Text>
                <View style={[{ marginTop: 10 }, styles.fieldColumn]}>
                  {plan.pictures.map((picture) => (
                    <PictureLink key={picture.link} field={{ value: picture.link }} isMobileView={isMobileView} />
                  ))}
                </View>
              </View>
            </View>

            <View style={styles.fieldRow}>
              <View style={[{ width: '100%' }, styles.fieldColumn]}>
                <Text style={styles.fieldLabel}>附件</Text>
                <View style={[{ marginTop: 10 }, styles.fieldColumn]}>
                  {plan.attachments.map((attachment) => (
                    <AttachmentLink key={attachment.link} field={{ value: attachment.link }} />
                  ))}
                </View>
              </View>
            </View>
          </>
        )}
      </View>

      {isPlan && (
        <>
          {!!plan.subPlans.length && <Text style={styles.subPlan}>各節次（子題）學習設計</Text>}
          {plan.subPlans.map((subPlan, x) => (
            <Fragment key={x}>
              <View style={styles.body}>
                <View style={styles.fieldRow}>
                  <View style={[{ flexGrow: 1, flexShrink: 1, marginRight: 15 }, styles.fieldColumn]}>
                    <Text style={styles.fieldLabel}>子題</Text>
                    <Text style={[styles.fieldContentWithBorder, { width: '100%' }]}>{subPlan.title}</Text>
                  </View>
                  <View
                    style={{
                      flexBasis: 150,
                      flexGrow: 0,
                      flexShrink: 0,
                      marginLeft: 15,
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <Text style={styles.fieldLabel}>節數</Text>
                    <Text style={[styles.fieldContentWithBorder, { width: '100%' }]}>{subPlan.totalLectures}</Text>
                  </View>
                </View>

                <View style={styles.fieldRow}>
                  <View style={[{ width: '100%' }, styles.fieldColumn]}>
                    <Text style={styles.fieldLabel}>學習表現 (相關領域或自訂)</Text>
                    <Text style={styles.fieldContentWithBorder}>{subPlan.performance}</Text>
                  </View>
                </View>
                <View style={styles.fieldRow}>
                  <View style={[{ width: '100%' }, styles.fieldColumn]}>
                    <Text style={styles.fieldLabel}>學習內容 (相關領域或自訂)</Text>
                    <Text style={styles.fieldContentWithBorder}>{subPlan.content}</Text>
                  </View>
                </View>
                <View style={styles.fieldRow}>
                  <View style={[{ width: '100%' }, styles.fieldColumn]}>
                    <Text style={styles.fieldLabel}>課程目標</Text>
                    <Text style={styles.fieldContentWithBorder}>{subPlan.goal}</Text>
                  </View>
                </View>
                <View style={styles.fieldRow}>
                  <View style={[{ width: '100%' }, styles.fieldColumn]}>
                    <Text style={styles.fieldLabel}>學習活動</Text>
                    <Text style={styles.fieldContentWithBorder}>{subPlan.activity}</Text>
                  </View>
                </View>
                <View style={styles.fieldRow}>
                  <View style={[{ width: '100%' }, styles.fieldColumn]}>
                    <Text style={styles.fieldLabel}>學習評量</Text>
                    <Text style={styles.fieldContentWithBorder}>{subPlan.assessment}</Text>
                  </View>
                </View>
                <View style={styles.fieldRow}>
                  <View style={[{ width: '100%' }, styles.fieldColumn]}>
                    <Text style={styles.fieldLabel}>備註</Text>
                    <Text style={styles.fieldContentWithBorder}>{subPlan.note}</Text>
                  </View>
                </View>
              </View>
            </Fragment>
          ))}
        </>
      )}
    </View>
  );
};

export default PlanDetail;
