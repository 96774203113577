import React from 'react';

import Svg from './notification_icon.svg';
import SvgActive from './notification_icon_active.svg';

const NotificationIcon = ({ active, ...props }) => {
  return active ? <SvgActive {...props} viewBox="0 0 30 30" /> : <Svg {...props} viewBox="0 0 30 30" />;
};

export default NotificationIcon;
