import React, { useState, useEffect, useCallback, useRef } from 'react';
import { View, TouchableOpacity } from 'react-native';

import apis from '../../../apis';
import Tooltip from '../../../components/Tooltip';
import { renderOption } from '../../Plan/PhaseCoreOption';

const PhaseCoreSelector = ({ phase, coreList, selected, setSelected }) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (phase && coreList.length) {
      apis.plan.getCoreTags(phase, coreList).then(({ data }) => {
        const newOptions = Object.keys(data).map((coreName) => ({ coreName, body: data[coreName] }));
        setOptions(newOptions);

        const newOptionsSet = new Set(newOptions);
        selectedSet.current.clear();
        setSelected((oldValue) => {
          const newValue = oldValue.filter((s) => {
            if (newOptionsSet.has(s)) {
              selectedSet.current.add(s);
              return true;
            }
            return false;
          });
          return newValue;
        });
      });
    }
  }, [phase, coreList]);

  const selectedSet = useRef(new Set(selected.map((c) => c.coreName)));
  const onSelect = useCallback(
    (currentSelected) => {
      if (selectedSet.current.has(currentSelected)) {
        selectedSet.current.delete(currentSelected);
      } else {
        selectedSet.current.add(currentSelected);
      }

      setSelected(options.filter((o) => selectedSet.current.has(o.coreName)));
    },
    [options, selected]
  );

  return (
    <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      {options.map((option, x) => (
        <Tooltip text={option.body}>
          <TouchableOpacity key={option.coreName} onPress={() => onSelect(option.coreName)}>
            <View style={{ marginRight: 12, marginBottom: 12 }}>
              {renderOption({ option: option.coreName, selectedSet: selectedSet.current })}
            </View>
          </TouchableOpacity>
        </Tooltip>
      ))}
    </View>
  );
};

export default PhaseCoreSelector;
