import React, { useState, useCallback } from 'react';
import { View, Image } from 'react-native';

import AutoSizeImage from '../../components/AutoSizeImage';
import Button from '../../components/Button';
import CustomInput from '../../components/CustomInput';
import HyperLink from '../../components/HyperLink';

const PictureLink = ({ style, field, editing, remove, index, isMobileView }) => {
  const [isLink, setIsLink] = useState();
  const [isNotMiroLink, setIsNotMiroLink] = useState();

  const RemoveButton = useCallback(
    () => <Button title="移除" type="red" negative onPress={() => remove(index)} />,
    [remove, index]
  );

  const onError = useCallback(() => {
    setIsLink(true);
    // if (!field.value.startsWith('https://miro.com/')) {
    //   setIsNotMiroLink(true);
    // }

    // disable embed mirror board functionality
    setIsNotMiroLink(true);
  }, []);

  const onMiroError = useCallback(() => {
    setIsNotMiroLink(true);
  }, [field.value]);

  return isLink ? (
    isNotMiroLink || isMobileView ? (
      <View
        style={[{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10, height: 50 }, style]}>
        {editing ? (
          <CustomInput style={{ flexGrow: 1, marginRight: 10 }} field={field} />
        ) : (
          <HyperLink style={{ flexGrow: 1 }} href={field.value} />
        )}
        {remove && (
          <View style={{ flexGrow: 0 }}>
            <RemoveButton />
          </View>
        )}
      </View>
    ) : (
      <View style={[{ position: 'relative', paddingTop: '70%' }, style]}>
        <iframe
          style={{ position: 'absolute', left: '0px', top: '0px' }}
          width="100%"
          height="100%"
          src={`${field.value.replace('board', 'live-embed')}`}
          frameBorder="0"
          scrolling="no"
          onError={onMiroError}
        />
        {remove && (
          <View style={{ position: 'absolute', right: 10, top: 10 }}>
            <RemoveButton />
          </View>
        )}
      </View>
    )
  ) : (
    <View style={[{ position: 'relative', marginBottom: 10 }, style]}>
      <AutoSizeImage fit uri={field.value} onError={onError} />
      {remove && (
        <View style={{ position: 'absolute', right: 10, top: 10 }}>
          <RemoveButton />
        </View>
      )}
    </View>
  );
};

export default PictureLink;
