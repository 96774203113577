import React, { useState, useCallback, useEffect } from 'react';

import apis from '../../apis';

const withToggleFavorite = (Comp) => (props) => {
  const [plan, setPlan] = useState(props.plan);

  useEffect(() => setPlan(props.plan), [props.plan]);

  const toggleFavorite = useCallback(async () => {
    try {
      if (!plan.favorited) {
        await apis.plans.postFavorite(plan.slug);
      } else {
        await apis.plans.deleteFavorite(plan.slug);
      }
    } catch (error) {
      // may fail if the user toogle concurrently in different platforms
      console.log(error);
    }
    const { data } = await apis.plan.getPlan(plan.slug);
    setPlan(data.plan);
  }, [plan]);

  return <Comp {...props} plan={plan} toggleFavorite={toggleFavorite} />;
};

export default withToggleFavorite;
