import { StyleSheet } from 'react-native';

import { colorStyleProps } from '../../../styles';
import styles from '../styles';

const s = StyleSheet.create({
  button: {
    backgroundColor: colorStyleProps.pureBlack.color,
  },
  text: {
    ...colorStyleProps.tpWhite,
  },
});

export default {
  button: StyleSheet.compose(styles.button, s.button),
  text: StyleSheet.compose(styles.text, s.text),
};
