import * as Analytics from 'expo-firebase-analytics';
import React, { useCallback, useContext, useState, useEffect, useMemo } from 'react';
import { View, StyleSheet, TouchableOpacity, Text, ScrollView } from 'react-native';
import { Icon } from 'react-native-elements';

import apis from '../../apis';
import Button from '../../components/Button';
import PlanComments from '../../components/Comment';
import MobilePlanComments from '../../components/Comment/MobilePlanComments';
import GoBack from '../../components/GoBack';
import * as Linking from '../../components/Linking';
import { Like, Comment } from '../../components/PlanCard';
import withToggleFavorite from '../../components/PlanCard/withToggleFavorite';
import PopupMenu from '../../components/PopupMenu';
import ButtonAwait from '../../components/PressAwait/ButtonAwait';
import TouchableOpacityAwait from '../../components/PressAwait/TouchableOpacityAwait';
import { useHistoryWithSpec } from '../../components/UniversalRouter';
import { useFloatingActions } from '../../containers/FloatingActions';
import { LoadingContext } from '../../containers/LoadingProvider';
import { OverlayContext } from '../../containers/OverlayManager';
import { UserContext } from '../../containers/UserProvider';
import { colorStyleProps, fontStyleProps } from '../../styles';
import { useMobileView } from '../Root/useMobileView';
import { renderOption } from './PhaseCoreOption';
import PlanDetail from './PlanDetail';
import ArrowDownSvg from './arrow_down.svg';
import BackSvg from './back_mobile.svg';
import loadPlan from './loadPlan';

const mailTo = (subject, content) => {
  const url = `mailto:teachplannermanager@gmail.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
    content
  )}`;
  return Linking.openURL(url);
};

const defaultStyles = StyleSheet.create({
  containner: {},
  header: {
    width: '100%',
    paddingHorizontal: `${(100 * 70) / 1440}vw`,

    backgroundColor: colorStyleProps.tpWhite.color,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: colorStyleProps.tpOG.color,

    display: 'flex',
    flexDirection: 'row',

    alignItems: 'flex-start',
    paddingVertical: 15,
  },
  headerContent: {
    width: '100%',
    minHeight: 40,

    flexShrink: 1,
    flexGrow: 0,

    display: 'flex',
    flexDirection: 'row',

    alignItems: 'flex-start',
  },
  actions: {
    flexGrow: 0,
    flexShrink: 0,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  like: {
    marginRight: 35,
  },
  comment: {
    marginRight: 35,
  },

  main: {
    minWidth: 640,

    paddingHorizontal: `${(100 * 120) / 1440}vw`,
    paddingVertical: 40,
    backgroundColor: colorStyleProps.tpOG.color,
  },
});

const mobileStyles = StyleSheet.create({
  containner: {
    height: '100%',
  },
  header: {
    width: '100%',

    backgroundColor: colorStyleProps.tpWhite.color,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: colorStyleProps.tpOG.color,

    display: 'flex',
    flexDirection: 'column',

    // alignItems: 'flex-end',
  },
  headerText: {
    flex: 1,
    ...fontStyleProps.bold16,
    overflow: 'hidden',
    paddingHorizontal: 20,
  },
  headerContent: {
    width: '100%',

    paddingVertical: 12,
    paddingHorizontal: 24,

    flexShrink: 0,
    flexGrow: 0,

    display: 'flex',
    flexDirection: 'row',

    alignItems: 'center',

    borderBottomWidth: 1,
    borderColor: colorStyleProps.tpOG.color,
  },
  actions: {
    paddingVertical: 12,
    paddingHorizontal: 24,

    flexGrow: 0,
    flexShrink: 0,

    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  like: {
    marginRight: 30,
  },
  comment: {},

  main: {
    paddingHorizontal: 8,
    paddingVertical: 0,
    backgroundColor: colorStyleProps.tpOG.color,
  },
});

const btnStyle = {
  main: {
    marginHorizontal: 5,
  },
  negativeMain: {
    marginHorizontal: 5,
  },
  button: {
    paddingHorizontal: 30,
  },
};

const Plan = ({ toggleFavorite, plan, reloadPlan, isPreviewing }) => {
  const [isMobileView, styles] = useMobileView(mobileStyles, defaultStyles);
  const { setIsLoading } = useContext(LoadingContext);
  const { pushOverlay } = useContext(OverlayContext);
  const { userProfile } = useContext(UserContext);
  const history = useHistoryWithSpec();
  const [isCommentsShown, setCommentsShown] = useState(false);

  const hideComments = useCallback(() => {
    if (isPreviewing) {
      // do nothing
      return;
    }
    setCommentsShown(false);
    reloadPlan();
  }, [isPreviewing]);

  const showComments = useCallback(() => {
    if (isPreviewing) {
      // do nothing
      return;
    }
    setCommentsShown(true);
  }, [isPreviewing]);

  useEffect(() => {
    if (history.location.query.showComments) {
      showComments();
      history.with({ query: { showComments: undefined } }).replace();
    }
  }, []);

  const onGoBack = useCallback(() => {
    if (history.length) {
      history.goBack();
    } else {
      history.replace('/');
    }
  }, [history]);

  const onEdit = useCallback(() => {
    pushOverlay(({ Confirm }) => (
      <Confirm
        message="確定要下架成草稿狀態?"
        onOK={() => {
          setIsLoading(true);
          apis.plan
            .putPlan(plan.slug, {
              plan: { ...plan, type: plan.type === 1 ? 0 : 100 },
            })
            .then(() => {
              setIsLoading(false);
              history.push(`/planner/${plan.slug}`);
            })
            .catch((error) => {
              console.error(error);
              setIsLoading(false);
            });
          Analytics.logEvent('edit_plan', {
            slug: plan.slug,
          });
        }}
      />
    ));
  }, [plan]);

  const onApply = useCallback(() => {
    Analytics.logEvent('apply_plan', {
      slug: plan.slug,
    });
    history.with({ query: { applying: plan.slug }, pathname: '/planner' }).push();
  }, [plan]);

  const showEditWarning = useCallback(() => {
    Analytics.logEvent('edit_plan_on_mobile', {
      slug: plan.slug,
    });
    pushOverlay(({ Message }) => <Message message="請至電腦版編輯或下載教案!" />);
  }, []);

  const showExportWarning = useCallback(() => {
    Analytics.logEvent('download_plan_on_mobile', {
      slug: plan.slug,
    });
    pushOverlay(({ Message }) => <Message message="請至電腦版編輯或下載教案!" />);
  }, []);

  const onExportDocx = useCallback(() => {
    Analytics.logEvent('download_docx', {
      slug: plan.slug,
    });
    window.open(`${process.env.API_BASE_URL}/api/export/${plan.slug}?file_type=docx`, '_blank');
  }, [plan]);

  const onExportPdf = useCallback(() => {
    Analytics.logEvent('download_pdf', {
      slug: plan.slug,
    });
    window.open(`${process.env.API_BASE_URL}/api/export/${plan.slug}?file_type=pdf`, '_blank');
  }, [plan]);

  const onReport = useCallback(() => {
    Analytics.logEvent('report_plan', {
      slug: plan.slug,
    });
    mailTo(`檢舉不當內容 - ${plan.title} [${plan.slug}]`, '');
  }, [userProfile, plan]);

  const isHost = userProfile.uid === plan.authorUid;
  const isPlan = plan.type === 1;

  const { scrollableRef, callbacks } = useFloatingActions();

  const planMenuOptions = useMemo(() => {
    const options = [
      {
        onSelect: showEditWarning,
        text: '套用',
      },
      {
        onSelect: showExportWarning,
        text: '下載',
      },
    ];
    if (!isHost) {
      options.push({
        onSelect: onReport,
        text: '檢舉',
      });
    }
    return options;
  }, [isHost]);

  return isMobileView && isCommentsShown ? (
    <MobilePlanComments plan={plan} hideComments={hideComments} />
  ) : (
    <View style={styles.containner}>
      {!isPreviewing && (
        <View style={styles.header}>
          <View style={styles.headerContent}>
            {isMobileView && (
              <>
                <View style={{ flexShrink: 0 }}>
                  <View style={{ width: 24, height: 24 }}>
                    <TouchableOpacity onPress={onGoBack}>
                      <BackSvg />
                    </TouchableOpacity>
                  </View>
                </View>
                <Text style={styles.headerText} numberOfLines={1}>
                  {plan.title}
                </Text>
                <View style={{ flexShrink: 0, width: 24, height: 24 }}>
                  <PopupMenu
                    optionsProps={{
                      customStyles: {
                        optionsContainer: {
                          minWidth: 128,
                        },
                      },
                    }}
                    options={planMenuOptions}>
                    <Icon color={colorStyleProps.keyBlack.color} size={25} name="more-vert" />
                  </PopupMenu>
                </View>
              </>
            )}
            {!isMobileView && (
              <>
                <View style={{ flexShrink: 0, marginLeft: -12, marginRight: 20, paddingTop: 15 }}>
                  <GoBack onPress={onGoBack} />
                </View>
                <View style={{ flexShrink: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                  {plan.cores.map(({ coreName: option }) => (
                    <View style={{ marginRight: 12, marginTop: 6, marginBottom: 6 }}>{renderOption({ option })}</View>
                  ))}
                </View>
              </>
            )}
          </View>
          <View style={styles.actions}>
            <View style={styles.comment}>
              <TouchableOpacity onPress={showComments}>
                <Comment plan={plan} />
                {!isMobileView && <PlanComments plan={plan} closeModal={hideComments} isModalOpen={isCommentsShown} />}
              </TouchableOpacity>
            </View>
            <View style={styles.like}>
              <TouchableOpacityAwait onPress={toggleFavorite}>
                <Like plan={plan} />
              </TouchableOpacityAwait>
            </View>
            {!isMobileView && (
              <>
                {isHost && <ButtonAwait title="編輯" negative style={btnStyle} onPress={onEdit} />}
                {isPlan && (
                  <>
                    <ButtonAwait title="套用" style={btnStyle} onPress={onApply} />
                    <PopupMenu
                      optionsProps={{
                        customStyles: {
                          optionsContainer: {
                            minWidth: 128,
                          },
                        },
                      }}
                      options={[
                        {
                          onSelect: onExportDocx,
                          text: '教案.docx',
                        },
                        {
                          onSelect: onExportPdf,
                          text: '教案.pdf',
                        },
                      ]}>
                      <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <Button title="下載" style={btnStyle} icon={<ArrowDownSvg />} iconRight />
                        <View style={{ position: 'absolute', width: '100%', height: '100%', opacity: 0 }} />
                      </View>
                    </PopupMenu>
                    {!isHost && <Button title="檢舉" style={btnStyle} type="red" negative onPress={onReport} />}
                  </>
                )}
              </>
            )}
          </View>
        </View>
      )}
      <ScrollView
        ref={(ref) => {
          if (scrollableRef) {
            if (ref) {
              scrollableRef.current = ref.getNativeScrollRef();
            } else {
              scrollableRef.current = null;
            }
          }
        }}
        {...callbacks}
        style={styles.main}>
        {isMobileView && (
          <View
            style={{
              flexShrink: 1,
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              marginVertical: 6,
            }}>
            {plan.cores.map(({ coreName: option }) => (
              <View key={option} style={{ marginRight: 12, marginTop: 6, marginBottom: 6 }}>
                {renderOption({ option, isMobileView })}
              </View>
            ))}
          </View>
        )}
        <PlanDetail plan={plan} isMobileView={isMobileView} />
      </ScrollView>
    </View>
  );
};

export default loadPlan(withToggleFavorite(Plan));
