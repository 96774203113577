import React, { useState, useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { Button } from 'react-native-elements';

import * as predefinedStyles from './styles';

const Tabs = ({
  tabs,
  defaultCurrentTab,
  style,
  onTabChange,
  type,
  currentTab: outsideCurrentTab,
  setCurrentTab: setOutsideCurrentTab,
}) => {
  const [localCurrentTab, setLocalCurrentTab] = useState(defaultCurrentTab || tabs[0].value);
  const currentTab = setOutsideCurrentTab ? outsideCurrentTab : localCurrentTab;
  const setCurrentTab = setOutsideCurrentTab ? setOutsideCurrentTab : setLocalCurrentTab;

  const onSelect = useCallback(
    (value) => {
      setCurrentTab(value);
      if (onTabChange) {
        onTabChange(value);
      }
    },
    [onTabChange]
  );

  const styles = useMemo(() => {
    const predefinedStyle = predefinedStyles[type];
    return {
      tabs: [predefinedStyle.tabs, style?.tabs],
      container: [predefinedStyle.container, style?.container],
      tab: {
        default: [predefinedStyle.tab, style?.tab?.default],
        active: [predefinedStyle.activeTab, style?.tab?.active],
      },
      button: {
        default: [predefinedStyle.button, style?.button?.default],
        active: [predefinedStyle.activeButton, style?.button?.active],
      },
      title: {
        default: [predefinedStyle.title, style?.title?.default],
        active: [predefinedStyle.activeTitle, style?.title?.active],
      },
    };
  }, [type, predefinedStyles, style]);

  /*
  Button styles:
    +-----------------------+
    | containerStyle        |  <- container
    | +-------------------+ |
    | | style             | |  <- tab
    | | +---------------+ | |
    | | | buttonStyle   | | |  <- button
    | | | +-----------+ | | |
    | | | |titleStyle | | | |  <- title
    | | | +-----------+ | | |
    +---------------------+
  */
  return (
    <View style={styles.tabs}>
      {tabs.map((tab) => {
        const active = currentTab === tab.value;
        const styleName = active ? 'active' : 'default';
        return (
          <Button
            key={tab.value}
            title={tab.label}
            containerStyle={styles.container}
            style={styles.tab[styleName]}
            buttonStyle={styles.button[styleName]}
            titleStyle={styles.title[styleName]}
            onPress={() => onSelect(tab.value)}
            active={active}
          />
        );
      })}
    </View>
  );
};

Tabs.defaultProps = {
  type: 'button',
};

export default Tabs;
