import React from 'react';
import { TouchableOpacity } from 'react-native';

import PressAwait from './PressAwait';

const TouchableOpacityAwait = ({ onPress, waitFor, disabled, ...props }) => (
  <PressAwait onPress={onPress} waitFor={waitFor}>
    {({ isAwaiting, onPressAwait }) => (
      <TouchableOpacity disabled={isAwaiting || disabled} onPress={onPressAwait} {...props} />
    )}
  </PressAwait>
);

export default TouchableOpacityAwait;
