import React, { useEffect } from 'react';
import { View, StyleSheet } from 'react-native';

import { useHistoryWithSpec } from '../../components/UniversalRouter';
import NotificationIcon from '../../images/NotificationIcon';
import { useMobileView } from '../../pages/Root/useMobileView';
import { fontStyleProps, colorStyleProps } from '../../styles';
import CenterText from '../CenterText';
import withFetchNotification from './withFetchNotification';
const styles = StyleSheet.create({
  notificationContainer: {
    display: 'flex',
  },
  badge: {
    position: 'absolute',
    top: -11,
    left: 10,
    display: 'flex',
    height: 20,
    width: 26,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 24,
    backgroundColor: colorStyleProps.tpGreen.color,
    zIndex: 1,
  },
  badgeText: {
    ...fontStyleProps.bold12,
    ...colorStyleProps.tpWhite,
  },
});

const NotificationIconWithBadge = ({ unread, notificationBadge, active }) => {
  const [isMobileView] = useMobileView();
  const history = useHistoryWithSpec();
  const shortenNumber = (unread) => (unread > 99 ? '99+' : unread);
  useEffect(() => {
    notificationBadge();
  }, [history.location]);

  return (
    <View style={styles.notificationContainer}>
      {unread === undefined || unread === 0 ? null : (
        <CenterText containerStyle={styles.badge} textStyle={styles.badgeText}>
          {shortenNumber(unread)}
        </CenterText>
      )}
      {isMobileView ? (
        <NotificationIcon
          fill={unread === undefined || unread === 0 ? colorStyleProps.grayBD.color : colorStyleProps.keyBlack.color}
          width={30}
          height={30}
          active={active}
        />
      ) : (
        <NotificationIcon
          fill={unread === undefined || unread === 0 ? colorStyleProps.grayBD.color : colorStyleProps.keyBlack.color}
          width={21}
          height={21}
        />
      )}
    </View>
  );
};

export default withFetchNotification(NotificationIconWithBadge);
