import React, { useCallback, useEffect } from 'react';
import { View } from 'react-native';

import Button from '../../components/Button';
import Notification from '../../components/Notification';
import Tabs from '../../components/Tabs';
import { useRouteMatch, useHistoryWithSpec } from '../../components/UniversalRouter';
import { colorStyleProps } from '../../styles';
import { useMobileView } from '../Root/useMobileView';
import Logo from './Logo';
import SearchBar from './SearchBar';
import UserMenu from './UserMenu';
import defaultStyles from './styles';
import mobileStyles from './styles.native';

const tabs = [
  {
    value: '1',
    label: '找教案',
  },
  {
    value: '101',
    label: '長知識',
  },
];

const tabsStyle = {
  tabs: { height: '100%' },
  title: {
    default: {
      fontSize: 16,
      marginLeft: 5,
      marginRight: 5,
    },
    active: {
      fontSize: 16,
      marginLeft: 5,
      marginRight: 5,
    },
  },
};

const createBtnStyle = {
  negativeButton: {
    borderColor: colorStyleProps.grayBD.color,
  },
};

const NavigationBar = () => {
  const isHomePage = !!useRouteMatch({
    path: '/',
    exact: true,
  });
  const isUserProfilePage = !!useRouteMatch('/user/');
  const isNotificationPage = !!useRouteMatch('/notification');
  const isPlanPage = !!useRouteMatch('/plan');
  const isSearchPage = !!useRouteMatch('/search');
  const isCommentPage = !!useRouteMatch('/comment');
  const [isMobileView, styles] = useMobileView(mobileStyles, defaultStyles);
  const history = useHistoryWithSpec();

  // set default plan type to url
  useEffect(() => {
    if (isHomePage) {
      if (!history.location.query.pt) {
        history.with({ query: { pt: tabs[0].value } }).replace();
      }
    } else {
      if (history.location.query.pt) {
        history.with({ query: { pt: undefined } }).replace();
      }
    }
  }, [isHomePage, history.location.query.pt]);

  // always get plan type from url
  const planType = history.location.query.pt;
  const setPlanType = useCallback((planType) => {
    const spec = { query: { pt: planType || undefined } };
    history.with(spec).push();
  }, []);

  const onPlanTypeChange = useCallback(
    (planType) => {
      if (!isHomePage) {
        console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@!!!!!!!!!!!!');
        history.with({ pathname: '/' }).push();
      }
    },
    [isHomePage]
  );

  const onCreatePlan = useCallback(() => {
    history.push('/planner');
  }, []);

  if (isMobileView) {
    if (isUserProfilePage || isNotificationPage || isPlanPage || isSearchPage || isCommentPage) {
      return null;
    }
  }

  return (
    <View style={styles.bar}>
      <Logo style={styles.logo} />
      <View style={styles.navTypes}>
        <Tabs
          tabs={tabs}
          style={tabsStyle}
          defaultCurrentTab={planType}
          currentTab={planType}
          setCurrentTab={setPlanType}
          onTabChange={onPlanTypeChange}
          type="underline"
          allowNoCurrent
        />
      </View>
      {!isMobileView ? (
        <>
          <View style={styles.actions}>
            <View style={styles.createBtn}>
              <Button
                title="建立教案"
                style={createBtnStyle}
                type="black"
                negative
                onPress={onCreatePlan}
                titleProps={{ numberOfLines: 2 }}
              />
            </View>
            <View style={styles.notification}>
              <Notification />
            </View>
            <View style={styles.searchBar}>
              <SearchBar />
            </View>
          </View>
          <View style={styles.user}>
            <UserMenu />
          </View>
        </>
      ) : null}
    </View>
  );
};

export default NavigationBar;
