import React from 'react';

import { useMobileView } from '../Root/useMobileView';
import LandingNativeSvg from './landing.native.svg';
import LandingSvg from './landing.svg';

export default () => {
  const [, svg] = useMobileView(
    <LandingNativeSvg width="100%" height="100%" viewBox="0 0 356 472" />,
    <LandingSvg height="100%" viewBox="0 0 769 721" />
  );
  return svg;
};
