import * as Analytics from 'expo-firebase-analytics';
import React, { useCallback } from 'react';
import { Platform, Share, TouchableOpacity } from 'react-native';

import LineSvg from './line.svg';

const ShareToLine = ({ plan }) => {
  const url = `${process.env.HOST_URL}/plan/${plan.slug}?openExternalBrowser=1`;
  const onShare = useCallback(async () => {
    try {
      await Share.share({
        message: url,
      });
      Analytics.logEvent('share_plan_to_line', {
        slug: plan.slug,
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  if (Platform.OS !== 'web') {
    return (
      <TouchableOpacity onPress={onShare}>
        <LineSvg />
      </TouchableOpacity>
    );
  }
  return (
    <a href={`http://line.naver.jp/R/msg/text/?${plan.title}%0D%0A${encodeURIComponent(url)}`} target="_blank">
      <LineSvg />
    </a>
  );
};

export default ShareToLine;
