import React, { useCallback, useState, useRef } from 'react';
import { View, TextInput, StyleSheet, Platform, TouchableOpacity } from 'react-native';

import { useHistoryWithSpec } from '../../components/UniversalRouter';
import Cross from '../../images/Cross';
import SearchIcon from '../../images/SearchIcon';
import { fontStyleProps, colorStyleProps } from '../../styles';

const extraTextInputStyle = {};

if (Platform.OS === 'web') {
  extraTextInputStyle.outlineStyle = 'none';
}

const styles = StyleSheet.create({
  container: {
    maxWidth: 260,
    height: 45,
    backgroundColor: colorStyleProps.tpWhite.color,

    borderWidth: 1,
    borderColor: colorStyleProps.grayBD.color,
    borderRadius: 60,

    paddingHorizontal: 15,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    flexGrow: 0,
    flexShrink: 0,
  },
  textInput: {
    flexGrow: 1,
    flexShrink: 1,
    width: '100%',
    height: '100%',
    ...extraTextInputStyle,
    ...fontStyleProps.regular14,
    marginLeft: 12,
  },
});

const SearchBar = ({ style, keepKeywordAfterSubmit }) => {
  const history = useHistoryWithSpec();
  const textInputRef = useRef();

  const [keyword, setKeyword] = useState(history.location.query.keyword);

  const onKeyPress = useCallback(
    (e) => {
      // enter
      if (e.keyCode === 13) {
        history.with({ pathname: '/search', query: { keyword } }).push();
        if (!keepKeywordAfterSubmit) {
          setKeyword('');
        }
      }
    },
    [history, keyword, keepKeywordAfterSubmit]
  );

  const onEndEditing = useCallback(() => {
    history.with({ pathname: '/search', query: { keyword } }).push();
    if (!keepKeywordAfterSubmit) {
      setKeyword('');
    }
  }, [history, keyword, keepKeywordAfterSubmit]);

  const onClear = useCallback(() => {
    setKeyword('');
    if (textInputRef.current) {
      textInputRef.current.focus();
    }
  }, []);

  return (
    <View style={[styles.container, style]}>
      <View style={styles.icon}>
        <SearchIcon width={15} height={15} />
      </View>
      <TextInput
        placeholder="搜尋..."
        style={styles.textInput}
        value={keyword}
        onChangeText={setKeyword}
        onKeyPress={onKeyPress}
        onEndEditing={onEndEditing}
        ref={textInputRef}
      />
      <View style={styles.icon}>
        <TouchableOpacity onPress={onClear}>
          <Cross width={15} height={15} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default SearchBar;
