import React from 'react';
import { View, StyleSheet, Image } from 'react-native';

import CenterText from '../../components/CenterText';
import { Link } from '../../components/UniversalRouter';
import { fontStyleProps, colorStyleProps } from '../../styles';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: {
    ...fontStyleProps.bold56,
    fontSize: 24,
    lineHeight: 33,
    ...colorStyleProps.tpGreen,
  },
  logoTitle: {
    ...fontStyleProps.boldNunito56,
    fontSize: 16,
    lineHeight: 22, 
    ...colorStyleProps.tpBlue,
  },
  logoSubTitle: {
    ...fontStyleProps.regular12,
    fontSize: 12,
    lineHeight: 16,
    ...colorStyleProps.tpBlue,
  },
  logoImg: {
    width: 69,
    height: 56
  },
});

const Logo = ({ style }) => (
  <View style={StyleSheet.compose(styles.container, style)}>
    <Link to="/">
      <CenterText textStyle={styles.logo}>TeachPlanner</CenterText>
    </Link>
  </View>
);

export default Logo;
