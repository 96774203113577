import { StyleSheet } from 'react-native';

import { colorStyleProps, fontStyleProps } from '../../../../styles';

export default StyleSheet.create({
  userProfileContainer: {
    backgroundColor: colorStyleProps.tpWhite.color,
    borderRadius: 10,
    paddingTop: 48,
  },
  mobileUserProfileContainer: {
    backgroundColor: colorStyleProps.tpWhite.color,
    margin: 10,
    borderRadius: 10,
  },
  avatarNameContainer: {
    width: '100%',
  },
  mobileAvatarNameContainer: {
    marginTop: -62,
    width: '100%',
  },

  body: {
    flexGrow: 1,
    marginBottom: 17,
    paddingLeft: 30,
    paddingRight: 30,
  },
  introContainer: {
    alignItems: 'center',
  },
  introHint: {
    ...fontStyleProps.regular14,
    color: colorStyleProps.tpGreen.color,
    marginBottom: 5,
  },
  introText: {
    ...fontStyleProps.regular14,
    color: colorStyleProps.keyBlack.color,
    marginBottom: 5,
  },
  actionButton: {
    width: '100%',
    marginTop: 20,
  },
  fanContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',

    textAlign: 'center',
    marginTop: 40,
  },
  fanColumn: {
    width: '33.3%',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fanTitle: {
    ...fontStyleProps.bold14,
    color: colorStyleProps.grayBD.color,
  },
  fanCount: {
    ...fontStyleProps.bold24,
  },
  mailIntroSection: {},
  mailIntroSectionTitle: {
    ...fontStyleProps.bold14,
    color: colorStyleProps.grayBD.color,
    marginTop: 40,
    marginLeft: -3,
  },
  itemHint: {
    marginTop: 5,
    ...fontStyleProps.bold14,
    color: colorStyleProps.tpGreen.color,
  },
  item: {
    marginTop: 5,
    ...fontStyleProps.bold14,
    color: colorStyleProps.keyBlack.color,
  },
  socialIconContainer: {
    flex: 1,
    width: '100%',
    display: 'flex',
    paddingTop: 50,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingBottom: 50,
  },
  socialIcon: {
    marginRight: 20,
  },
});
