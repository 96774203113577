import React, { useMemo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Icon } from 'react-native-elements';

import Button from '../../../../components/Button';
import PopupMenu from '../../../../components/PopupMenu';
import { subject } from '../../../../options';
import { colorStyleProps } from '../../../../styles';
import AvatarName from '../../AvatarName';
import FollowButton from '../../FollowButton';
import socialNetworks, { withOpen } from '../socialNetworks';
import styles from './styles';

const editBtnStyle = {
  button: {
    width: '100%',
  },
};

const ProfileView = ({
  isMobileView,
  userProfile,
  isEditable,
  onFollowChange,
  onAvatarUpload,
  toggleEditMode,
  onBlockUser,
  onUnblockUser,
}) => {
  const isFollowing = userProfile.following;

  const userMenuOptions = useMemo(() => {
    if (!userProfile) {
      return [];
    }

    const options = [];

    if (!isEditable) {
      if (!userProfile.blocked) {
        options.push({
          onSelect: onBlockUser,
          text: '設定黑名單',
        });
      } else {
        options.push({
          onSelect: onUnblockUser,
          text: '取消黑名單',
        });
      }
    }

    return options;
  }, [isEditable, userProfile]);

  const socialLinks = socialNetworks.map((sn) => userProfile[sn.type]);
  const socialNetworksWithOpen = useMemo(() => withOpen(socialLinks), socialLinks);

  return (
    <View style={isMobileView ? styles.mobileUserProfileContainer : styles.userProfileContainer}>
      <View style={isMobileView ? styles.mobileAvatarNameContainer : styles.avatarNameContainer}>
        <AvatarName isEditable={isEditable} userProfile={userProfile} onUpload={onAvatarUpload} />
        {!isEditable && !isMobileView ? (
          <View style={{ position: 'absolute', right: 15, top: -35 }}>
            <PopupMenu options={userMenuOptions}>
              <Icon color={colorStyleProps.keyBlack.color} size={25} name="more-horiz" />
            </PopupMenu>
          </View>
        ) : null}
      </View>
      <View style={styles.body}>
        {userProfile.blocked ? (
          <Text style={styles.introContainer}>您已將對方加入黑名單，無法查看他發佈的內容</Text>
        ) : (
          <>
            <View style={styles.introContainer}>
              {(isEditable || !!userProfile.subject) && (
                <Text style={userProfile.subject ? styles.introText : styles.introHint}>
                  {userProfile.subject ? subject[userProfile.subject] : '您所任教的科目?'}
                </Text>
              )}
              {(isEditable || !!userProfile.school) && (
                <Text style={userProfile.school ? styles.introText : styles.introHint}>
                  {userProfile.school || '您所任教的學校?'}
                </Text>
              )}
              <View style={styles.actionButton}>
                {isEditable ? (
                  <Button title="編輯個人資料" onPress={toggleEditMode} style={editBtnStyle} />
                ) : (
                  <FollowButton isFollowing={isFollowing} uid={userProfile.uid} onFollowChange={onFollowChange} />
                )}
              </View>
            </View>
            <View style={styles.fanContainer}>
              <View style={styles.fanColumn}>
                <Text style={styles.fanTitle}>粉絲</Text>
                <Text style={styles.fanCount}>{userProfile.followerCount}</Text>
              </View>
              <View style={styles.fanColumn}>
                <Text style={styles.fanTitle}>追蹤中</Text>
                <Text style={styles.fanCount}>{userProfile.followingCount}</Text>
              </View>
              <View style={styles.fanColumn}>
                <Text style={styles.fanTitle}>TP積分</Text>
                <Text style={styles.fanCount}>{userProfile.appliedCount}</Text>
              </View>
            </View>
            <View style={styles.mailIntroSection}>
              {(isEditable || !!userProfile.email) && (
                <View>
                  <Text style={styles.mailIntroSectionTitle}>電子郵件地址</Text>
                  <Text style={userProfile.email ? styles.item : styles.itemHint}>
                    {userProfile.email || '加入您的電子郵件地址'}
                  </Text>
                </View>
              )}
              {(isEditable || !!userProfile.bio) && (
                <View>
                  <Text style={styles.mailIntroSectionTitle}>自介</Text>
                  <Text style={userProfile.bio ? styles.item : styles.itemHint}>
                    {userProfile.bio || '還沒向大家自我介紹一下呢!'}
                  </Text>
                </View>
              )}
            </View>
            <View style={styles.socialIconContainer}>
              {socialNetworksWithOpen.map(({ type, icon: Icon, open }) => (
                <TouchableOpacity key={type} onPress={open} disabled={!open}>
                  <View style={styles.socialIcon}>
                    <Icon
                      fill={open ? colorStyleProps.keyBlack.color : colorStyleProps.grayBD.color}
                      width={24}
                      height={24}
                    />
                  </View>
                </TouchableOpacity>
              ))}
            </View>
          </>
        )}
      </View>
    </View>
  );
};

export default ProfileView;
