import * as Analytics from 'expo-firebase-analytics';
import React, { useCallback, useEffect, useState, useContext, useMemo, useRef } from 'react';
import { View, ImageBackground, TouchableOpacity, Text } from 'react-native';
import { Icon } from 'react-native-elements';

import apis from '../../apis';
import FallbackableImageBackground from '../../components/Fallbackable/FallbackableImageBackground';
import ImageUploader from '../../components/ImageUploader';
import PopupMenu from '../../components/PopupMenu';
import Tabs from '../../components/Tabs';
import { useHistoryWithSpec, useParams } from '../../components/UniversalRouter';
import { LoadingContext } from '../../containers/LoadingProvider';
import { UserContext } from '../../containers/UserProvider/UserProvider';
import ChevronLeft from '../../images/ChevronLeft';
import Picture from '../../images/Picture';
import { colorStyleProps } from '../../styles';
import { useMobileView } from '../Root/useMobileView';
import UserContent from './UserContent';
import UserProfile from './UserProfile';
import UserWeb from './UserWeb/UserWeb';
import styles from './styles';
const mobileTabs = [
  {
    value: 'planCard',
    label: '作品',
  },
  {
    value: 'about',
    label: '關於',
  },
];

const mobileTabsStyle = {
  title: {
    default: {
      color: colorStyleProps.tpWhite.color,
      fontSize: 16,
    },
    active: {
      color: colorStyleProps.tpWhite.color,
      fontSize: 16,
    },
  },
  button: {
    active: {
      backgroundColor: colorStyleProps.pureBlack.color,
    },
  },
};

const getUserProfile = async (uid) => {
  try {
    const { data } = await apis.profiles.getProfile(uid);
    return data.profile;
  } catch (error) {
    console.log(error);
  }
  return null;
};

const CoverImageUploader = ({ onCoverUpload, disabled }) => {
  return (
    <ImageUploader
      onUpload={onCoverUpload}
      aspect={[5.581, 1]}
      resize={{ width: 1440, height: 258 }}
      objectType="cover"
      withTimestamp>
      <Picture />
    </ImageUploader>
  );
};

const User = () => {
  const [isMobileView] = useMobileView();
  const history = useHistoryWithSpec();
  const { uid } = useParams();
  const { userProfile: loginUserProfile, logout } = useContext(UserContext);
  const { setIsLoading } = useContext(LoadingContext);

  const [userProfile, setUserProfile] = useState();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        setUserProfile(await getUserProfile(uid));
        Analytics.logEvent('view_user_profile', {
          uid,
        });
      } catch (error) {
        // TODO handle error
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [uid]);

  const currentUserProfile = useRef();
  currentUserProfile.current = userProfile;
  const onSaveUserProfile = useCallback(async (values) => {
    try {
      const savingValues = {};
      Object.keys(values).forEach((name) => {
        const value = values[name].trim();
        savingValues[name] = value.length ? value : undefined;
      });
      await apis.user.putCurrentUser({ user: { ...currentUserProfile.current, ...savingValues } });
    } catch (error) {
      // TODO handle error
      console.error(error);
    } finally {
      try {
        setUserProfile(await getUserProfile(uid));
      } catch (error) {
        // TODO handle error
        console.error(error);
      }
    }
  }, []);

  const onFollowChange = useCallback(async () => {
    setUserProfile(await getUserProfile(uid));
  }, [uid]);

  const [headerBarBackgroundStyle, setHeaderBarBackgroundStyle] = useState({
    backgroundColor: colorStyleProps.keyBlack.color,
    opacity: 0.2,
    position: 'absolute',
    width: '100%',
    height: '100%',
  });

  const [coverUploaderStyle, setCoverUploaderStyle] = useState([{ opacity: 1 }, styles.coverUploader]);

  const onScroll = useCallback((e) => {
    const scrollTop = e.nativeEvent.contentOffset.y;
    setHeaderBarBackgroundStyle((old) => ({
      ...old,
      opacity: scrollTop > 0 ? Math.min(1, 0.2 + (0.8 * scrollTop) / 126) : 0.2,
    }));
    setCoverUploaderStyle((old) => [{ opacity: scrollTop > 0 ? Math.max(0, 1 - scrollTop / 10) : 1 }, old[1]]);
  }, []);

  const resetStyles = useCallback(() => {
    setHeaderBarBackgroundStyle((old) => ({
      ...old,
      opacity: 0.2,
    }));
    setCoverUploaderStyle((old) => [{ opacity: 1 }, old[1]]);
  }, []);

  // set default category to url
  useEffect(() => {
    if (isMobileView) {
      if (!history.location.query.v) {
        history.with({ query: { v: mobileTabs[0].value } }).replace();
      }
    } else {
      if (history.location.query.v) {
        history.with({ query: { v: undefined } }).replace();
      }
    }
  }, [isMobileView, history.location.query.v]);

  // always get view from url
  const view = history.location.query.v;
  const onViewChange = useCallback((tab) => {
    history.with({ query: { v: tab } }).replace();
    resetStyles();
  }, []);

  const onBack = useCallback(() => {
    if (history.length) {
      history.goBack();
    } else {
      history.replace('/');
    }
  }, [history]);

  const onCoverUpload = useCallback((url) => onSaveUserProfile({ cover: url }), [onSaveUserProfile]);

  const onAvatarUpload = useCallback((url) => onSaveUserProfile({ image: url }), [onSaveUserProfile]);

  const onBlockUser = useCallback(async () => {
    try {
      Analytics.logEvent('block-user', {
        uid,
      });
      setIsLoading(true);
      await apis.profiles.blockUser(uid);
    } catch (error) {
      // TODO handle error
      console.error(error);
    } finally {
      try {
        setUserProfile(await getUserProfile(uid));
      } catch (error) {
        // TODO handle error
        console.error(error);
      }
      setIsLoading(false);
    }
  }, [uid]);

  const onUnblockUser = useCallback(async () => {
    try {
      Analytics.logEvent('unblock-user', {
        uid,
      });
      setIsLoading(true);
      await apis.profiles.unblockUser(uid);
    } catch (error) {
      // TODO handle error
      console.error(error);
    } finally {
      try {
        setUserProfile(await getUserProfile(uid));
      } catch (error) {
        // TODO handle error
        console.error(error);
      }
      setIsLoading(false);
    }
  }, [uid]);

  const isEditable = loginUserProfile.uid === userProfile?.uid;

  const userMenuOptions = useMemo(() => {
    if (!userProfile) {
      return [];
    }

    const options = [
      {
        onSelect: logout,
        text: '登出',
      },
    ];

    if (!isEditable) {
      if (!userProfile.blocked) {
        options.push({
          onSelect: onBlockUser,
          text: '設定黑名單',
        });
      } else {
        options.push({
          onSelect: onUnblockUser,
          text: '取消黑名單',
        });
      }
    }

    return options;
  }, [isEditable, userProfile]);

  if (!userProfile) {
    return null;
  }

  if (isMobileView) {
    return (
      <View style={styles.main}>
        <View style={styles.headerImage}>
          <FallbackableImageBackground
            source={{ uri: userProfile.cover }}
            fallbackImage={
              <ImageBackground
                source={require('../../images/default_background.png')}
                style={{ flex: 1, resizeMode: 'cover' }}
              />
            }
            style={{ flex: 1, resizeMode: 'cover' }}
          />
        </View>
        <View style={styles.headerBar}>
          <View style={headerBarBackgroundStyle} />
          <View style={styles.headerBarContainer}>
            <TouchableOpacity onPress={onBack} style={styles.chevronLeftButton}>
              <ChevronLeft size={25} />
            </TouchableOpacity>
            <View style={styles.tabContainer}>
              <Tabs
                tabs={mobileTabs}
                defaultCurrentTab={view}
                style={mobileTabsStyle}
                onTabChange={onViewChange}
                activeButtonStyle={styles.activeTabStyle}
              />
            </View>
            <PopupMenu options={userMenuOptions}>
              <Icon color={colorStyleProps.tpWhite.color} size={25} name="more-horiz" />
            </PopupMenu>
          </View>
        </View>

        {view === mobileTabs[0].value ? (
          <UserContent
            userProfile={userProfile}
            onScroll={onScroll}
            onFollowChange={onFollowChange}
            isEditable={isEditable}
            onAvatarUpload={onAvatarUpload}
          />
        ) : (
          <UserProfile
            userProfile={userProfile}
            onScroll={onScroll}
            onFollowChange={onFollowChange}
            isEditable={isEditable}
            onSaveUserProfile={onSaveUserProfile}
            onAvatarUpload={onAvatarUpload}
          />
        )}

        {isEditable ? (
          <View style={coverUploaderStyle}>
            <View style={{ opacity: userProfile.cover ? 0 : 1 }}>
              <Text style={styles.coverUploaderTextTitle}>新增橫幅影像</Text>
              <Text style={styles.coverUploaderTextSubtitle}>最佳尺寸 1440 x 258</Text>
            </View>
            <View style={styles.coverUploaderIcon}>
              <CoverImageUploader onCoverUpload={onCoverUpload} disabled={coverUploaderStyle[0].opacity < 1} />
            </View>
          </View>
        ) : null}
      </View>
    );
  }

  return userProfile ? (
    <UserWeb
      userProfile={userProfile}
      onScroll={onScroll}
      onFollowChange={onFollowChange}
      isEditable={isEditable}
      onSaveUserProfile={onSaveUserProfile}
      onAvatarUpload={onAvatarUpload}
      coverImageUploader={<CoverImageUploader onCoverUpload={onCoverUpload} />}
      onBlockUser={onBlockUser}
      onUnblockUser={onUnblockUser}
    />
  ) : null;
};

export default User;
