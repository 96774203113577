import React from 'react';

import Button from '../Button';
import PressAwait from './PressAwait';

const ButtonAwait = ({ onPress, waitFor, disabled, ...props }) => (
  <PressAwait onPress={onPress} waitFor={waitFor}>
    {({ isAwaiting, onPressAwait }) => <Button {...props} disabled={isAwaiting || disabled} onPress={onPressAwait} />}
  </PressAwait>
);

export default ButtonAwait;
