import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

import Avatar from '../../components/Avatar';
import { Link } from '../../components/UniversalRouter';
import { fontStyleProps, colorStyleProps } from '../../styles';
import ShareToFb from './ShareToFb';
import ShareToLine from './ShareToLine';

const defaultStyles = StyleSheet.create({
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    backgroundColor: colorStyleProps.tpWhite.color,
    borderRadius: 20,

    paddingVertical: 30,
    paddingHorizontal: 38,
  },
  author: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  authorAvatar: {
    marginRight: 10,
  },
  authorName: {
    ...fontStyleProps.bold20,
    color: colorStyleProps.keyBlack.color,
    marginRight: 4,
  },
  followingAuthor: {
    height: 20,
    borderWidth: 1,
    borderColor: colorStyleProps.tpGreen.color,
    borderRadius: 10,
    ...fontStyleProps.bold12,
    color: colorStyleProps.tpGreen.color,
    paddingHorizontal: 15,
  },
  authorSchool: {
    ...fontStyleProps.regular14,
    color: colorStyleProps.gray82.color,
  },
  sharing: {
    marginRight: 25,
  },
});

const mobileStyles = StyleSheet.create({
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    backgroundColor: colorStyleProps.tpWhite.color,
    borderRadius: 20,

    paddingVertical: 16,
    paddingHorizontal: 16,
  },
  author: {
    width: '100%',
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  authorAvatar: {
    marginRight: 10,
  },
  authorName: {
    flexShrink: 1,
    ...fontStyleProps.bold20,
    color: colorStyleProps.keyBlack.color,
    marginRight: 4,
  },
  followingAuthor: {
    height: 20,
    borderWidth: 1,
    borderColor: colorStyleProps.tpGreen.color,
    borderRadius: 10,
    ...fontStyleProps.bold12,
    color: colorStyleProps.tpGreen.color,
    paddingHorizontal: 15,
  },
  authorSchool: {
    ...fontStyleProps.regular14,
    color: colorStyleProps.gray82.color,
  },
  sharing: {
    flexGrow: 0,
    flexShrink: 0,
    marginLeft: 10,
    marginRight: 25,
  },
});

const Author = ({ plan, author, isMobileView }) => {
  const styles = isMobileView ? mobileStyles : defaultStyles;
  return (
    <View style={styles.main}>
      <View style={styles.author}>
        <View style={styles.authorAvatar}>
          <Link to={`/user/${author?.uid || plan.authorUid}`}>
            <Avatar size={50} uri={author?.image || plan.authorImage} />
          </Link>
        </View>
        <View style={{ display: 'flex', flexDirection: 'column', flexShrink: 1 }}>
          <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text style={styles.authorName} numberOfLines={2}>
              {author?.username || plan.authorUsername}
            </Text>
            {author?.following && <Text style={styles.followingAuthor}>Following</Text>}
          </View>
          {author && <Text style={styles.authorSchool}>{author.school}</Text>}
        </View>
      </View>
      <View style={styles.sharing}>
        <ShareToLine plan={plan} />
      </View>
      <View>
        <ShareToFb plan={plan} />
      </View>
    </View>
  );
};

export default Author;
