import React from 'react';
import { Platform } from 'react-native';
import { useMediaQuery } from 'react-responsive';

const withColumnCalculator = (Comp) => (props) => {
  const oneColumn = useMediaQuery({ maxWidth: 639 });
  const twoColumns = useMediaQuery({ maxWidth: 1023 });
  const threeColumns = useMediaQuery({ maxWidth: 1439 });

  let numColumns = 4;
  if (props.numColumns) {
    numColumns = props.numColumns;
  } else {
    if (Platform.OS === 'web') {
      if (oneColumn) {
        numColumns = 1;
      } else if (twoColumns) {
        numColumns = 2;
      } else if (threeColumns) {
        numColumns = 3;
      }
    } else {
      numColumns = 1;
    }
  }

  return <Comp {...props} numColumns={numColumns} />;
};

export default withColumnCalculator;
