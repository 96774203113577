import React, { useMemo } from 'react';

import { subjectWithEmoji, grade } from '../../options';
import { colorStyleProps } from '../../styles';

const withTag =
  (Comp) =>
  ({ plan, ...props }) => {
    const planWithTagList = useMemo(
      () => ({
        ...plan,
        tagList:
          plan.type === 0 || plan.type === 1
            ? [
                {
                  key: `grade-${plan.grade}`,
                  containerStyle: { backgroundColor: colorStyleProps.keyBlack.color },
                  textStyle: { color: colorStyleProps.tpWhite.color },
                  text: grade[plan.grade],
                },
                {
                  key: `subject-${plan.subject}`,
                  containerStyle: { backgroundColor: colorStyleProps.tpOG.color },
                  textStyle: { color: colorStyleProps.keyBlack.color },
                  text: subjectWithEmoji[plan.subject],
                },
              ]
            : [
                {
                  key: 'article',
                  containerStyle: { backgroundColor: colorStyleProps.ppro.color },
                  textStyle: { color: colorStyleProps.tpWhite.color },
                  text: '文章',
                },
              ],
      }),
      [plan]
    );

    return <Comp {...props} plan={planWithTagList} />;
  };

export default withTag;
