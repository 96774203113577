import { useRef, useEffect } from 'react';

const useMountedFlag = () => {
  const isMountedRef = useRef();

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return isMountedRef;
};

export default useMountedFlag;
