import axios from 'axios';

export const getProfile = (uid) => axios.get(`/api/profiles/${uid}`);

export const followUser = (uid) => axios.post(`/api/profiles/${uid}/follow`);

export const unFollowUser = (uid) => axios.delete(`/api/profiles/${uid}/follow`);

export const blockUser = (uid) => axios.post(`/api/profiles/${uid}/block`);

export const unblockUser = (uid) => axios.delete(`/api/profiles/${uid}/unblock`);
