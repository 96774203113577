import axios from 'axios';
import * as Analytics from 'expo-firebase-analytics';
import React, { useEffect, useState, useMemo, useCallback, useRef, useContext } from 'react';

import apis from '../../apis';
import { LoadingContext } from '../../containers/LoadingProvider';
import { OverlayContext } from '../../containers/OverlayManager';
import { getItem, setItem, deleteItem } from './store';

export const UserContext = React.createContext();

/*
  +----------------------+        login         +----------+
  |     User Provider    | -------------------> | Backend  |
  |                      |                      |          |
  |    setLoginStatus() <---------------------  |          |
  +----------------------+    access token      +----------+
       ^
       |
       | setAuthInfo({ provider, token, revoke })
       |
  +---------+
  | Google  |
  +---------+
*/
const UserProvider = ({ children }) => {
  // authentication info from 3rd party provider
  const [authInfo, setAuthInfo] = useState();
  // login status with universal access token
  const [loginStatus, setLoginStatus] = useState();
  const [completeLoginProcess, setCompleteLoginProcess] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const { pushOverlay } = useContext(OverlayContext);
  const { setIsLoading } = useContext(LoadingContext);

  const loginStatusRef = useRef();
  loginStatusRef.current = loginStatus;
  // check access token when did mount
  useEffect(() => {
    (async () => {
      const accessToken = await getItem('accessToken');
      if (accessToken) {
        setLoginStatus({ accessToken });
      } else {
        setLoginStatus(null);
      }
    })();

    const reqInterceptorId = axios.interceptors.request.use((config) => {
      if (loginStatusRef.current) {
        // only insert access token for relative path (self backend api)
        if (config.url.indexOf('/') === 0) {
          config.headers['Authorization'] = `Bearer ${loginStatusRef.current.accessToken}`;
        }
      }
      return config;
    }, Promise.reject);

    const resInterceptorId = axios.interceptors.response.use(
      (res) => {
        return res;
      },
      (error) => {
        if (error.response?.status === 401 || error.response?.status === 403) {
          pushOverlay(({ Message }) => <Message message="閒置過久，請重新登入" onOK={logout} />);
          console.error(error);
          return {};
        }
        throw error;
      }
    );
    return () => {
      axios.interceptors.request.eject(reqInterceptorId);
      axios.interceptors.response.eject(resInterceptorId);
    };
  }, []);

  const tryToRevoke = useCallback(() => {
    if (completeLoginProcess) {
      setAuthInfo((ai) => {
        if (ai) {
          ai.revoke();
          return null;
        }
        return ai;
      });
    }
  }, [completeLoginProcess]);

  // once logged in
  useEffect(() => {
    if (loginStatus) {
      (async () => {
        try {
          setIsLoading(true);
          const {
            data: { user: profile },
          } = await apis.user.getCurrentUser();
          await Analytics.setUserId(profile.uid);
          setUserProfile(profile);
          setCompleteLoginProcess(true);
        } catch (error) {
          pushOverlay(({ Message }) => <Message message="無法取得帳戶資訊，請聯絡系統管理員" onOK={logout} />);
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      })();
    } else {
      tryToRevoke();
    }
  }, [loginStatus, tryToRevoke]);

  // do login to get access token after got authentication from 3rd provider
  useEffect(() => {
    if (authInfo) {
      (async () => {
        try {
          setIsLoading(true);
          const {
            data: {
              user: { token },
            },
          } = await apis.users.login({
            user: {
              provider: authInfo.provider,
              token: authInfo.token,
            },
          });

          // save access token
          setItem('accessToken', token);
          setLoginStatus({ accessToken: token });
        } catch (error) {
          pushOverlay(({ Message }) => <Message message="無法登入成功，請聯絡系統管理員" onOK={logout} />);
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [authInfo]);

  const logout = useCallback(async () => {
    try {
      await deleteItem('accessToken');
      setLoginStatus(null);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const value = useMemo(
    () => ({
      authInfo,
      setAuthInfo,
      loginStatus,
      userProfile,
      logout,
    }),
    [authInfo, setAuthInfo, loginStatus, userProfile, logout]
  );

  return loginStatus !== undefined && <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
