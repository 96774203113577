import React, { useCallback, useContext } from 'react';
import { View, StyleSheet, Text } from 'react-native';

import { Link } from '../../components/UniversalRouter';
import { UserContext } from '../../containers/UserProvider/UserProvider';
import { fontStyleProps, colorStyleProps } from '../../styles';
import Avatar from '../Avatar/Avatar';
import MoreMenu from './MoreMenu';

const styles = StyleSheet.create({
  item: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderBottomWidth: 1,
    borderBottomColor: colorStyleProps.tpOG.color,
    marginTop: 30,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  nameTime: {
    marginLeft: 10,
    flexShrink: 1,
    flexGrow: 1,
  },
  authorName: {
    ...fontStyleProps.bold18,
    ...colorStyleProps.keyBlack,
  },
  time: {
    ...fontStyleProps.bold12,
    ...colorStyleProps.gray82,
  },
  comment: {
    ...fontStyleProps.regular14,
    ...colorStyleProps.keyBlack,
    paddingVertical: 28,
  },
  moreOptions: {
    flexShrink: 0,
    flexGrow: 0,
    width: 24,
    height: 24,
  },
});

const CommentItem = ({ comment, planAuthor, onDeleteComment }) => {
  const now = new Date().getTime();
  const date = new Date(comment.createdAt).getTime();
  //convert to hours
  const timeDifference = (now - date) / (1000 * 3600);
  const toTimeFormatted = useCallback((timeDifference, date) => {
    if (timeDifference >= 24) {
      const newDate = new Date(date).toLocaleDateString();
      return newDate;
    }
    if (timeDifference <= 1) {
      const minutes = Math.round(timeDifference * 60);
      return minutes;
    }
    return Math.round(timeDifference);
  }, []);

  const { userProfile: loginUserProfile } = useContext(UserContext);

  const isDeletable = loginUserProfile.uid === planAuthor || loginUserProfile.uid === comment.authorUid;

  return (
    <View style={styles.item}>
      <View style={styles.header}>
        <View>
          <Link to={`/user/${comment.authorUid}`}>
            <Avatar size={50} uri={comment.authorImage} />
          </Link>
        </View>
        <View style={styles.nameTime}>
          <Text style={styles.authorName}>{comment.authorName}</Text>
          {timeDifference >= 24 ? (
            <Text style={styles.time}>{toTimeFormatted(timeDifference, date)}</Text>
          ) : timeDifference > 1 ? (
            <Text style={styles.time}>約 {toTimeFormatted(timeDifference, date)} 小時前</Text>
          ) : (
            <Text style={styles.time}>約 {toTimeFormatted(timeDifference, date)} 分鐘前</Text>
          )}
        </View>
        <View style={styles.moreOptions}>
          {isDeletable ? <MoreMenu onDeleteComment={onDeleteComment} commentId={comment.id} /> : null}
        </View>
      </View>
      <Text style={styles.comment}>{comment.body}</Text>
    </View>
  );
};

export default CommentItem;
