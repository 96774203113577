import React from 'react';
import { View, StyleSheet } from 'react-native';

const defaultStyles = StyleSheet.create({
  main: {
    borderRadius: 50,
    borderWidth: 1,
    paddingVertical: 10,
    paddingHorizontal: 15,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'fit-content',
  },
});

const mobileStyles = StyleSheet.create({
  main: {
    borderRadius: 50,
    borderWidth: 1,
    paddingVertical: 6,
    paddingHorizontal: 13,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const Capsule = ({ style, isMobileView, children }) => {
  const styles = isMobileView ? mobileStyles : defaultStyles;
  return <View style={[styles.main, style]}>{children}</View>;
};

export default Capsule;
