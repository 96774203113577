import React from 'react';

import Svg from './home_icon.svg';
import SvgActive from './home_icon_active.svg';

const HomeIcon = ({ active, ...props }) => {
  return active ? <SvgActive {...props} viewBox="0 0 30 30" /> : <Svg {...props} viewBox="0 0 30 30" />;
};

export default HomeIcon;
